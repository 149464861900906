import { Heading, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import Collection from "../../../shared/components/collection";
import CollectionItems from "../../../shared/components/collection/CollectionItems";
import MainFooterItem from "../../../shared/components/main/MainFooterItem";
import { CollectionOpts, getCollectionId } from "../../../shared/lib/ids";
import { ResourceType } from "../../../types/serializers";

import BlogPreview from "./BlogPreview";

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const GRID_COLUMNS = [1, 2];

const BlogRecommendations = ({
  collectionOptions,
}: {
  collectionOptions: CollectionOpts<ResourceType.Blog>;
}) => {
  const { t } = useTranslation();
  const collectionId = getCollectionId(ResourceType.Blog, collectionOptions);

  return (
    <MainFooterItem>
      <Heading as="h2" variant="lg">
        {t("main:blogs.recommendedBlogs")}
      </Heading>
      <Collection id={collectionId}>
        <SimpleGrid columns={GRID_COLUMNS} spacing="40px">
          <CollectionItems<ResourceType.Blog>>
            {(blogItem) => <BlogPreview blog={blogItem} />}
          </CollectionItems>
        </SimpleGrid>
      </Collection>
    </MainFooterItem>
  );
};

export default BlogRecommendations;

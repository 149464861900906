import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { FormEvent, Fragment, useState } from "react";
import { FaChevronRight } from "react-icons/fa";

import useCreateCallback from "../../../shared/hooks/useCreateCallback";
import { trackEvent } from "../../../shared/lib/analytics";
import { ResourceType } from "../../../types/serializers";

interface NewsletterFormProps {
  source: string;
}

const NewsletterForm: React.FC<NewsletterFormProps> = ({ source }) => {
  const toast = useToast();
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [createSubscription, loading] = useCreateCallback(
    ResourceType.NewsletterSubscription
  );
  const handleSuccess = () => {
    trackEvent("newsletter_subscribe", { source });
    toast({
      description: t("main:newsletter.confirmation")!,
      status: "success",
    });
  };
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    createSubscription({ email, firstname }, handleSuccess);
  };

  return (
    <Fragment>
      <form aria-label="Newsletter Form" onSubmit={handleSubmit}>
        <Stack alignItems="end" display="inline-flex" spacing={4}>
          <Text fontWeight="bold">{t("main:newsletter.header")}</Text>
          <FormControl>
            <FormLabel>{t("main:newsletter.name.label")}</FormLabel>
            <Input
              required
              placeholder={t("main:newsletter.name.placeholder")!}
              type="text"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("main:newsletter.email.label")}</FormLabel>
            <Input
              required
              placeholder={t("main:newsletter.email.placeholder")!}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Box float="right">
            <Button
              isLoading={loading}
              rightIcon={<FaChevronRight />}
              type="submit"
            >
              {t("main:newsletter.send")}
            </Button>
          </Box>
        </Stack>
      </form>
    </Fragment>
  );
};

export default NewsletterForm;

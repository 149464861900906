import { Box } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

const TypingDot = ({ delay }: { delay: number }) => (
  <Box
    animation={`1.5s typingAnimation ${delay}ms infinite ease-in-out`}
    backgroundColor="primary.darkActive"
    borderRadius="2px"
    display="inline-block"
    height="4px"
    marginRight="2px"
    width="4px"
  />
);

const TypingIndicator = () => {
  return (
    <Box
      __css={{
        "@keyframes typingAnimation": {
          "0%": {
            transform: "translateY(0px)",
          },
          "28%": {
            transform: "translateY(-5px)",
          },
          "44%": {
            transform: "translateY(0px)",
          },
        },
      }}
      title={t("main:chat.typingIndicator")!}
    >
      <Box alignItems="center" display="flex" height="17px">
        <TypingDot delay={200} />
        <TypingDot delay={300} />
        <TypingDot delay={400} />
      </Box>
    </Box>
  );
};

export default TypingIndicator;

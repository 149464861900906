import {
  FormControl,
  FormLabel,
  Heading,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import useCreateCallback from "../../../shared/hooks/useCreateCallback";
import useCurrentUser from "../../../shared/hooks/useCurrentUser";
import useDestroyCallback from "../../../shared/hooks/useDestroyCallback";
import useRefreshResource from "../../../shared/hooks/useRefreshResource";
import useResource from "../../../shared/hooks/useResource";
import useStatus from "../../../shared/hooks/useStatus";
import { trackEvent } from "../../../shared/lib/analytics";
import { isRefreshingStatus } from "../../../shared/lib/status";
import { ResourceType } from "../../../types/serializers";

const UserSettingsTab = () => {
  const toast = useToast();
  const refreshResource = useRefreshResource();
  const [currentUser] = useCurrentUser();
  const newsletterSubscription = useResource(
    currentUser?.relationships.newsletterSubscription.data
  );
  const newsletterSubscriptionStatus = useStatus(newsletterSubscription);
  const [createCallback, createLoading] = useCreateCallback(
    ResourceType.NewsletterSubscription
  );
  const [destroyCallback, destroyLoading] = useDestroyCallback(
    newsletterSubscription
  );
  const value = !!newsletterSubscription?.attributes?.emailSubscription;
  const loading =
    createLoading ||
    destroyLoading ||
    isRefreshingStatus(newsletterSubscriptionStatus);

  const handleChange = () => {
    const message = value
      ? t("main:account.user.settings.newsletter.unsubscribed")
      : t("main:account.user.settings.newsletter.subscribed");
    const callback = value ? destroyCallback : createCallback;
    const { email, firstname } = currentUser?.attributes ?? {};

    if (callback) {
      const handleSuccess = () => {
        if (newsletterSubscription) {
          refreshResource(newsletterSubscription);
        }
        trackEvent("newsletter_subscribe", { source: "profile" });
        toast({
          description: message,
        });
      };
      callback(
        {
          email,
          firstname,
        },
        handleSuccess
      );
    }
  };

  return (
    <>
      <Heading as="h2" variant="lg">
        {t("main:account.user.settings.header")}
      </Heading>
      <FormControl>
        <FormLabel htmlFor="email-alerts" mb="1em">
          {t("main:account.user.settings.newsletter.label")}
        </FormLabel>
        <Switch
          colorScheme="green"
          id="email-alerts"
          isChecked={newsletterSubscription?.attributes?.emailSubscription}
          isDisabled={loading}
          size="lg"
          onChange={handleChange}
        />
      </FormControl>
    </>
  );
};

export default UserSettingsTab;

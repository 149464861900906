import { EmptyResource, ResourceType } from "../../types/serializers";
import useResourceRoute from "../components/store/hooks/useResourceRoute";

import useResourceCallback, { ResourceCallback } from "./useResourceCallback";

function useUpdateCallback<T extends ResourceType>(
  resource: EmptyResource<T>,
  root?: string
): ResourceCallback<T>;
function useUpdateCallback(resource: undefined, root?: string): [];
function useUpdateCallback<T extends ResourceType>(
  resource: EmptyResource<T> | undefined,
  root?: string
): [] | ResourceCallback<T>;
function useUpdateCallback<T extends ResourceType>(
  resource?: EmptyResource<T>,
  root: string = GLOBALS.root
): [] | ResourceCallback<T> {
  const path = useResourceRoute(resource);
  const method = "PUT";

  return useResourceCallback<T>(path, method, resource, root);
}

export default useUpdateCallback;

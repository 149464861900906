import { Box } from "@chakra-ui/react";
import React from "react";

interface ChatWrapperProps {
  children: React.ReactNode;
}

export const ChatWrapper: React.FC<ChatWrapperProps> = ({ children }) => {
  return (
    <Box
      alignItems="start"
      background="yellow.light"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="50vh"
      w="full"
    >
      <Box borderRadius="md" width="100%">
        {children}
      </Box>
    </Box>
  );
};

import { HStack, Heading, SimpleGrid, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import InlineInput from "../../../shared/components/inputs/InlineInput";
import { InputTypes } from "../../../shared/components/inputs/types";
import useCurrentUser from "../../../shared/hooks/useCurrentUser";
import DeleteAccountButton from "../Chat/components/buttons/DeleteAccountButton";

const UserDataTab = () => {
  const [currentUser] = useCurrentUser();

  return (
    <>
      <HStack justifyContent="space-between" width="full">
        <Heading as="h2" variant="lg">
          {t("main:account.user.data.header")}
        </Heading>
        <DeleteAccountButton />
      </HStack>
      <SimpleGrid columns={{ base: 1, md: 2 }}>
        <VStack
          border="1px solid"
          borderColor="background.stroke"
          borderRadius="0.5em"
          gap="1em"
          padding="1em"
        >
          <InlineInput
            attribute="firstname"
            label={t("main:account.user.inputs.name.label")}
            resource={currentUser}
          />
          <InlineInput
            attribute="email"
            label={t("main:account.user.inputs.email.label")}
            resource={currentUser}
            type={InputTypes.Email}
          />
        </VStack>
      </SimpleGrid>
    </>
  );
};

export default UserDataTab;

import { Box, Divider, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { FiChevronRight } from "react-icons/fi";

import Link from "../../../shared/components/link";

interface AnecdotePreview {
  href: string;
  imageSrc: string;
  name: string;
  previewText: string;
}

interface AnecdoteCollectionProps {
  previews: AnecdotePreview[];
}

const AnecdoteCollection: React.FC<AnecdoteCollectionProps> = ({
  previews,
}) => {
  return (
    <Flex direction="column" width="100%">
      {previews.map((preview, index) => (
        <Link href={preview.href} key={index}>
          <Box>
            <Flex alignItems="center" my="1em">
              <Box
                borderRadius="full"
                height="80px"
                padding="0.5em"
                width="80px"
              >
                <Image
                  alt="Person"
                  borderRadius="full"
                  height="100%"
                  objectFit="cover"
                  src={preview.imageSrc}
                  width="100%"
                />
              </Box>
              <Box flex="1" marginLeft="1em">
                <Text lineHeight={1.5} marginBottom={1}>
                  {preview.previewText}
                </Text>
                <Text color="blackish" lineHeight={1.5}>
                  {preview.name}
                </Text>
              </Box>
              <FiChevronRight />
            </Flex>
            {index < previews.length - 1 && <Divider />}
          </Box>
        </Link>
      ))}
    </Flex>
  );
};

export default AnecdoteCollection;

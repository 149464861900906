import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";

import InterviewCoverImage from "./images/femke_cover.webp";

const PrimaryAnecdote: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box height={{ base: "550px" }}>
      <Box
        backgroundImage={`url(${InterviewCoverImage})`}
        backgroundPosition="right"
        backgroundSize="cover"
        borderRadius={{ base: "8px 8px 0px 0px", md: "0px 8px 0px 0px" }}
        bottom="0"
        height={{ base: "350px", md: "500px", lg: "600px" }}
        left="0"
        position="absolute"
        right="0"
        top="0"
        zIndex="-1"
      />
      <Box
        background={{
          base: "linear-gradient(to bottom, transparent 75%, white)",
        }}
        borderRadius={{ base: "8px 8px 0px 0px", md: "0px" }}
        display={{ base: "", md: "none" }}
        height={{ base: "350px" }}
        position="absolute"
        top={{
          base: "1",
          md: "0",
        }}
        width="100%"
      />

      <Box
        background="background.avatar"
        borderRadius="lg"
        bottom={{ base: "0", md: "20", xl: "60" }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        marginLeft={{ base: "1em", md: "5%" }}
        marginRight={{ base: "1em", md: undefined }}
        maxW={{ base: "100%", md: "60%", lg: "45%" }}
        padding="5"
        position={{ base: "absolute", md: undefined }}
        zIndex="1"
      >
        <Box marginBottom="1em">
          <Text marginBottom="2em">
            {t("main:home.anecdotes.primary.text")}
          </Text>
          <Text marginBottom="1em">
            {t("main:home.anecdotes.primary.source")}
          </Text>
        </Box>
        <Box display="flex" justifyContent="start">
          <Button
            as={Link}
            rightIcon={<FiChevronRight />}
            size="lg"
            to="/blogs/interview-moederschap-nicole"
            variant="solid"
          >
            {t("main:home.anecdotes.primary.button")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PrimaryAnecdote;

import { Container } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";

import Main from "../../../shared/components/main/Main";
import MainHeader from "../../../shared/components/main/MainHeader";
import { UserResource } from "../../../types/serializers";
import SignInForm, { FormStep } from "../../components/auth/SignInForm";

const SignInPage = ({ initialStep }: { initialStep?: FormStep }) => {
  const navigate = useNavigate();
  const handleSignIn = (_user?: UserResource, location?: string | null) => {
    navigate(location || "/");
  };

  return (
    <Main>
      <Container>
        <MainHeader title={t("main:signIn.header")} />
        <SignInForm initialStep={initialStep} onSuccess={handleSignIn} />
      </Container>
    </Main>
  );
};

export default SignInPage;

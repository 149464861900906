import {
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiLogOut } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

import Container from "../../../shared/components/layout/Container";
import Main from "../../../shared/components/main/Main";
import MainBody from "../../../shared/components/main/MainBody";
import useSignOut from "../../../shared/hooks/useSignOut";
import VolunteerHelpTab from "../../components/profile/VolunteerHelpTab";
import VolunteerProfileTab from "../../components/profile/VolunteerProfileTab";
import VolunteerStatsTab from "../../components/profile/VolunteerStatsTab";

const TAB_KEYS = ["profiel", "helpcentrum", "statistieken"];

const VolunteerAccountPage = () => {
  const signOutCallback = useSignOut();
  const navigate = useNavigate();
  const location = useLocation();
  const index = TAB_KEYS.findIndex((key) => key === location.hash.slice(1));
  const handleTabChange = (newIndex: number) => {
    navigate(`#${TAB_KEYS[newIndex]}`);
  };

  return (
    <Main>
      <Container>
        <MainBody>
          <Tabs
            isLazy
            colorScheme="primary"
            index={index >= 0 ? index : 0}
            marginTop="3em"
            onChange={handleTabChange}
          >
            <HStack
              alignItems="flex-start"
              flexDirection={{ base: "column-reverse", md: "row" }}
              justifyContent="space-between"
            >
              <TabList>
                <Tab fontWeight="bold">
                  {t("main:account.volunteer.tabs.profile")}
                </Tab>
                <Tab fontWeight="bold">
                  {t("main:account.volunteer.tabs.helpdesk")}
                </Tab>
                <Tab fontWeight="bold">
                  {t("main:account.volunteer.tabs.stats")}
                </Tab>
              </TabList>
              <Button
                alignSelf={{ base: "end", md: "unset" }}
                color="primary.darkActive"
                leftIcon={<FiLogOut />}
                marginBottom={{ base: "1em", md: 0 }}
                variant="link"
                onClick={signOutCallback}
              >
                {t("main:navbar.signOut")}
              </Button>
            </HStack>
            <TabPanels>
              <TabPanel>
                <VolunteerProfileTab />
              </TabPanel>
              <TabPanel>
                <VolunteerHelpTab />
              </TabPanel>
              <TabPanel>
                <VolunteerStatsTab />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </MainBody>
      </Container>
    </Main>
  );
};

export default VolunteerAccountPage;

import { Heading } from "@chakra-ui/react";
import React from "react";

import Highlight from "../../../shared/components/highlight";
import MainFooterItem from "../../../shared/components/main/MainFooterItem";
import RichText from "../../../shared/components/richText";

const PillarPageBlockHighlight = ({
  highlightBody,
  highlightTitle,
}: {
  highlightBody?: string;
  highlightTitle?: string;
}) => {
  if (!highlightBody?.trim()) {
    return null;
  }

  return (
    <MainFooterItem>
      <Highlight icon>
        {highlightTitle && (
          <Heading as="h3" marginBottom="0.5em" variant="sm">
            {highlightTitle}
          </Heading>
        )}
        <RichText textStyle="bodyBlog">{highlightBody}</RichText>
      </Highlight>
    </MainFooterItem>
  );
};

export default PillarPageBlockHighlight;

import { ResourceType } from "../../types/serializers";
import useResourceRoute from "../components/store/hooks/useResourceRoute";
import { getCollectionId } from "../lib/ids";

import useResourceCallback from "./useResourceCallback";

const useCreateCallback = <T extends ResourceType>(
  resourceType: T,
  root: string = GLOBALS.root
) => {
  const path = useResourceRoute({
    id: getCollectionId(resourceType),
    type: ResourceType.Collection,
  })!;
  const method = "POST";

  return useResourceCallback<T>(path, method, { type: resourceType }, root);
};

export default useCreateCallback;

import { Box, HStack } from "@chakra-ui/react";
import React, { FC } from "react";

import Button from "../../../shared/components/button";
import useResource from "../../../shared/hooks/useResource";
import {
  CollectionFilters,
  CollectionOrderDirection,
  ResourceType,
} from "../../../types/serializers";
import CountBubble from "../Chat/components/CountBubble";

import { ChatViewerTabKey, getTabCollectionId } from "./lib/tabs";

export interface ChatViewerTab {
  filter: CollectionFilters<ResourceType.ChatConversation>;
  key: ChatViewerTabKey;
  label: string;
  onEmpty: FC;
  orderDirection: CollectionOrderDirection;
  orderKey: string;
  showCount?: boolean;
}

interface ChatViewerTabsProps {
  reverseList: boolean;
  selectConversationId: (id?: string) => void;
  selectedTab: ChatViewerTab;
  setSelectedTab: (tab: ChatViewerTab) => void;
  tabs: ChatViewerTab[];
}

interface ChatViewerTabProps {
  reverseList: boolean;
  selectConversationId: (id?: string) => void;
  selectedTab: ChatViewerTab;
  setSelectedTab: (tab: ChatViewerTab) => void;
  tab: ChatViewerTab;
}

const ChatViewerTab = ({
  reverseList,
  selectConversationId,
  selectedTab,
  setSelectedTab,
  tab,
}: ChatViewerTabProps) => {
  const selected = selectedTab.label == tab.label;
  const collection = useResource({
    id: getTabCollectionId(tab, reverseList),
    type: ResourceType.Collection,
  });
  const count = collection?.attributes.totalCount;
  const handleClick = () => {
    selectConversationId(undefined);
    setSelectedTab(tab);
  };

  return (
    <Button
      aria-pressed={selected}
      fontWeight={selected ? "bold" : "normal"}
      lineHeight="1.5em"
      paddingX="0.6em"
      variant="nude"
      onClick={handleClick}
    >
      <div>
        {tab.label}
        {tab.showCount && <CountBubble background="white" count={count} />}
      </div>
    </Button>
  );
};

const ChatViewerTabs = ({
  reverseList,
  selectConversationId,
  selectedTab,
  setSelectedTab,
  tabs,
}: ChatViewerTabsProps) => {
  if (tabs.length <= 1) {
    return <Box height="4em" />;
  }

  return (
    <HStack
      __css={{ "& *": { flexShrink: 0 } }}
      gap="0.6em"
      maxWidth="100%"
      overflow="auto"
      paddingY="1em"
    >
      {tabs.map((tab) => (
        <ChatViewerTab
          key={tab.label}
          reverseList={reverseList}
          selectConversationId={selectConversationId}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tab={tab}
        />
      ))}
    </HStack>
  );
};

export default ChatViewerTabs;

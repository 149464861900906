import { Icon, Link } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiChevronDown } from "react-icons/fi";

import { CollectionOrderDirection } from "../../../types/serializers";

import { ChatViewerTab } from "./ChatViewerTabs";
import { reverseDirection } from "./lib/tabs";

const LABELS: Record<string, Record<CollectionOrderDirection, string>> = {
  createdAt: {
    [CollectionOrderDirection.Asc]: t("main:chat.sort.created_at.asc"),
    [CollectionOrderDirection.Desc]: t("main:chat.sort.created_at.desc"),
  },
  lastMessageAt: {
    [CollectionOrderDirection.Asc]: t("main:chat.sort.last_message_at.asc"),
    [CollectionOrderDirection.Desc]: t("main:chat.sort.last_message_at.desc"),
  },
};

const ChatSorter = ({
  reverseList,
  setReverseList,
  tab,
}: {
  reverseList: boolean;
  setReverseList: (reverse: boolean) => void;
  tab: ChatViewerTab;
}) => {
  const direction = reverseList
    ? reverseDirection(tab.orderDirection)
    : tab.orderDirection;

  const handleClick = () => {
    setReverseList(!reverseList);
  };

  return (
    <Link as="button" onClick={handleClick}>
      {LABELS[tab.orderKey][direction!]}
      <Icon as={FiChevronDown} marginBottom="-0.2em" />
    </Link>
  );
};

export default ChatSorter;

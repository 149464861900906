import { BarElement, CategoryScale, Chart, LinearScale } from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";

import { Stats } from "../VolunteerStatsTab";

Chart.register(LinearScale);
Chart.register(CategoryScale);
Chart.register(BarElement);

interface BarChartProps {
  stats: Stats;
}

const BarChart = ({ stats }: BarChartProps) => {
  return (
    <Bar
      data={{
        datasets: [
          {
            backgroundColor: "#091047",
            data: stats.helpedPerWeek.map(({ count }) => count),
          },
        ],
        labels: stats.helpedPerWeek.map(({ week }) => week),
      }}
      options={{
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      }}
    />
  );
};

export default BarChart;

import { Textarea, forwardRef, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import ResizeTextarea from "react-textarea-autosize";

const AutoResizeTextarea = forwardRef((props, ref) => {
  const maxRows = useBreakpointValue({ base: 6, sm: 8, md: 10 });

  return (
    <Textarea
      as={ResizeTextarea}
      maxRows={maxRows}
      minH="unset"
      minRows={3}
      ref={ref}
      resize="none"
      w="100%"
      {...props}
    />
  );
});

export default AutoResizeTextarea;

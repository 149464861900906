import { Image } from "@chakra-ui/react";
import React from "react";

import { BlogAuthorResource } from "../../../types/serializers";
import useResource from "../../hooks/useResource";

const BlogAuthorDetail = ({
  blogAuthor: { attributes, relationships },
}: {
  blogAuthor: BlogAuthorResource;
}) => {
  const profilePhoto = useResource(relationships.profilePhoto.data);

  return (
    <>
      {profilePhoto && (
        <Image
          alt={profilePhoto.attributes.alt}
          background="gray.light"
          borderRadius="1em"
          display="inline-block"
          height="2em"
          marginRight="0.1em"
          src={profilePhoto.attributes.avatarUrl}
          verticalAlign="middle"
          width="2em"
        />
      )}
      {` door ${attributes.name}`}
    </>
  );
};

export default BlogAuthorDetail;

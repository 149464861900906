import { Box, Button, HStack, Skeleton, Text, chakra } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiEdit } from "react-icons/fi";

import AsyncResource from "../../../shared/components/async/AsyncResource";
import useModalDispatch from "../../../shared/hooks/useModalDispatch";
import useRelativeTime from "../../../shared/hooks/useRelativeTime";
import DeclineButton from "../Chat/components/buttons/DeclineButton";
import FollowButton from "../Chat/components/buttons/FollowButton";
import ShowButton from "../Chat/components/buttons/ShowButton";
import userName from "../Chat/lib/userName";
import { ExpertiseDomainModal } from "../Chat/modals/ExpertiseDomainModal";

import { ChatViewerCardProps } from "./ChatViewerCard";
import { ChatViewerTabKey } from "./lib/tabs";
import UserQuestionsLink from "./UserQuestionsLink";

const NO_OF_LINES = 3;

const ChatViewerVolunteerCard = ({
  chatConversation,
  fullQuestion,
  tabKey,
}: ChatViewerCardProps) => {
  const { attributes, relationships } = chatConversation;
  const date =
    tabKey === ChatViewerTabKey.ForYouNew
      ? attributes.createdAt
      : attributes.lastMessageAt;
  const relativeDateAt = useRelativeTime(new Date(date));
  const datePrefix =
    tabKey === ChatViewerTabKey.ForYouNew
      ? t("main:chat.buttons.recieved")
      : t("main:chat.buttons.lastMessage");
  const dispatchExpertiseDomainModal = useModalDispatch(ExpertiseDomainModal);
  const showExpertiseDomain = tabKey === ChatViewerTabKey.ForYouNew;

  return (
    <>
      <HStack justifyContent="space-between" width="100%">
        <AsyncResource
          resource={relationships.user.data}
          onLoad={() => (
            <Skeleton height="1.7em" title={t("shared:status.loading")!} />
          )}
        >
          {(creator) => (
            <Box
              fontWeight="bold"
              marginTop={showExpertiseDomain ? undefined : "0.5em"}
              noOfLines={1}
            >
              {userName(creator)}
            </Box>
          )}
        </AsyncResource>
        {showExpertiseDomain && (
          <AsyncResource resource={relationships.expertiseDomain.data}>
            {(domain) => (
              <Button
                _hover={{
                  background: "gray.light",
                }}
                background="gray.lighter"
                borderBottom="1px solid"
                borderBottomColor="background.stroke"
                borderBottomLeftRadius="0.375em"
                borderBottomRightRadius="0"
                borderLeft="1px solid"
                borderLeftColor="background.stroke"
                borderTopLeftRadius="0"
                borderTopRightRadius="0.375em"
                color="blackish"
                flexShrink={0}
                fontSize="sm"
                marginRight="-16px"
                marginTop="-0.1em"
                padding="8px"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatchExpertiseDomainModal({
                    chatConversation,
                  });
                }}
              >
                {domain.attributes.name}
                <chakra.span color="primary.darkActive" marginLeft="8px">
                  <FiEdit />
                </chakra.span>
              </Button>
            )}
          </AsyncResource>
        )}
      </HStack>
      <Box>
        <UserQuestionsLink chatConversation={chatConversation} />
      </Box>
      <Text fontSize="14px" lineHeight="21px" marginTop="8px">
        {datePrefix}
        {relativeDateAt}
      </Text>
      <Text
        fontStyle="italic"
        marginRight="16px"
        marginTop="8px"
        noOfLines={fullQuestion ? undefined : NO_OF_LINES}
      >
        {attributes.initialMessage}
      </Text>
      <HStack>
        <DeclineButton chatConversation={chatConversation} tabKey={tabKey} />
        <FollowButton chatConversation={chatConversation} tabKey={tabKey} />
        <ShowButton chatConversation={chatConversation} tabKey={tabKey} />
      </HStack>
    </>
  );
};

export default ChatViewerVolunteerCard;

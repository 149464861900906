import { Box, Grid, Image, List, ListItem, Text } from "@chakra-ui/react";
import React from "react";

import Link from "../../../shared/components/link";

interface LinkType {
  href: string;
  name: string;
}

interface PillarShowcaseCardProps {
  imageSrc: string;
  links: LinkType[];
  title: string;
}

const IMAGE_SIZE = { base: "28px", md: "40px" };

const PillarShowcaseCard: React.FC<PillarShowcaseCardProps> = ({
  imageSrc,
  title,
  links,
}) => {
  return (
    <Box
      bg="background.card"
      borderRadius="lg"
      height="100%"
      m={{ base: 0, md: 1 }}
      maxW="430px"
      overflow="hidden"
      p={{ base: 4, md: 8 }}
    >
      <Grid gap={{ base: 2, lg: 6 }} templateColumns="auto 1fr">
        <Image
          alt={title}
          boxSize={IMAGE_SIZE}
          minWidth={{ base: "28px", md: "40px" }}
          src={imageSrc}
        />
        <Box>
          <Text fontWeight="Bold">{title}</Text>
          <List mt="1.5em" spacing={5}>
            {links.map((link: LinkType, i: number) => (
              <ListItem key={i} role="listitem">
                <Link href={link.href}>{link.name}</Link>
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    </Box>
  );
};

export default PillarShowcaseCard;

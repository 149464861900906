import { Alert, Link, Spinner } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";

import { useRequestAuthCode } from "../../lib/auth";

const RequestNewCode = ({
  email,
  float,
}: {
  email: string;
  float?: "right";
}) => {
  const [newCodeRequested, setNewCodeRequested] = useState(false);
  const [requestNewCode, loadingNewCode, newCodeError] = useRequestAuthCode(
    email,
    () => setNewCodeRequested(true)
  );

  if (newCodeError) {
    return (
      <Alert marginTop="1em" status="error">
        {newCodeError}
      </Alert>
    );
  }

  if (newCodeRequested) {
    return (
      <Alert marginTop="1em" status="success">
        {t("main:questionFlow.enterOTPStep.newCodeRequested")}
      </Alert>
    );
  }

  return (
    <Link
      as="button"
      disabled={loadingNewCode}
      float={float}
      marginTop="0.5em"
      type="button"
      onClick={requestNewCode}
    >
      {loadingNewCode && <Spinner size="sm" />}
      {t("main:questionFlow.enterOTPStep.buttons.sendAgain")}
    </Link>
  );
};

export default RequestNewCode;

import { ChannelNameWithParams, Subscription } from "@rails/actioncable";
import React from "react";

import { CableContext } from "../components/cable/CableProvider";

export enum WEBSOCKET_STATUS {
  connecting = 0,
  connected = 1,
  disconnected = 2,
}

export enum ClientActionType {
  AppendChatMessage = "append_chat_message",
  IsTyping = "is_typing",
  SyncResource = "sync_resource",
  UpdateCounts = "update_counts",
}

export enum ServerActionType {
  IsTyping = "is_typing",
  ReadMessages = "read_messages",
}

export interface ClientActionMessage {
  data?: any;
  type: ClientActionType;
}

export type OnReceive = (message: ClientActionMessage) => void;
export type OnConnect = () => void;
export type OnDisconnect = () => void;

export interface Handlers {
  onConnect?: OnConnect;
  onDisconnect?: OnDisconnect;
  onReceive?: OnReceive;
}

const useSubscription = (
  channel: string | ChannelNameWithParams,
  { onConnect, onDisconnect, onReceive }: Handlers = {}
) => {
  const cable = React.useContext(CableContext);
  const [subscription, setSubscription] = React.useState<Subscription | null>(
    null
  );
  const [websocketStatus, setWebsocketStatus] = React.useState(
    WEBSOCKET_STATUS.connecting
  );
  const handleConnect = () => {
    setWebsocketStatus(WEBSOCKET_STATUS.connected);
    if (onConnect) {
      onConnect();
    }
  };
  const handleDisconnect = () => {
    setWebsocketStatus(WEBSOCKET_STATUS.disconnected);
    if (onDisconnect) {
      onDisconnect();
    }
  };
  const callbacks = React.useMemo(
    () => ({
      connected: handleConnect,
      disconnected: handleDisconnect,
      received: onReceive,
    }),
    []
  );

  React.useEffect(() => {
    if (cable) {
      const newSubscription = cable.subscriptions.create(channel, callbacks);
      setSubscription(newSubscription);

      return () => {
        newSubscription.unsubscribe();
      };
    }

    return () => null;
  }, [cable, channel, callbacks]);

  return { subscription, websocketStatus };
};

export default useSubscription;

import { Button } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiCheckSquare } from "react-icons/fi";

import useModalDispatch from "../../../../../shared/hooks/useModalDispatch";
import {
  ChatConversationResource,
  ChatConversationStatus,
} from "../../../../../types/serializers";
import { FeedbackConfirmationModal } from "../../modals/FeedbackConfirmationModal";

interface FeedbackButtonProps {
  chatConversation: ChatConversationResource;
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({
  chatConversation,
}) => {
  const dispatchModal = useModalDispatch(FeedbackConfirmationModal);

  if (chatConversation.attributes.status !== ChatConversationStatus.Active) {
    return null;
  }

  const handleOpenFeedbackModal = () => {
    dispatchModal({ chatConversationId: chatConversation.id });
  };

  return (
    <Button
      leftIcon={<FiCheckSquare />}
      variant="nude"
      onClick={handleOpenFeedbackModal}
    >
      {t("main:kandoorGPT.chat.feedback")}
    </Button>
  );
};

export default FeedbackButton;

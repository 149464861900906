import React from "react";

import { ChatConversationStatus } from "../../../../../types/serializers";
import { MessageRenderer } from "../ChatMessage";

import SystemMessage from "./SystemMessage";

const QuestionReceivedMessage = ({
  chatConversation,
  chatMessage,
  handleKandoorRequest,
}: MessageRenderer) => {
  if (
    !chatConversation ||
    chatConversation.attributes.status !==
      ChatConversationStatus.PendingAcceptance
  ) {
    return null;
  }

  return (
    <SystemMessage
      chatConversation={chatConversation}
      chatMessage={chatMessage}
      handleKandoorRequest={handleKandoorRequest}
    />
  );
};

export default QuestionReceivedMessage;

import { Card, Flex } from "@chakra-ui/react";
import React from "react";

import { ChatConversationResource } from "../../../types/serializers";

import ChatViewerUserCard from "./ChatViewerUserCard";
import ChatViewerVolunteerCard from "./ChatViewerVolunteerCard";
import { ChatViewerTabKey } from "./lib/tabs";

export interface ChatViewerCardProps {
  chatConversation: ChatConversationResource;
  fullQuestion?: boolean;
  selectConversationId: (id?: string) => void;
  selectedConversationId?: string;
  tabKey: ChatViewerTabKey;
}

const ChatViewerCard = (props: ChatViewerCardProps) => {
  const handleSelect = () =>
    props.selectConversationId(props.chatConversation.id);
  const selected = props.selectedConversationId === props.chatConversation.id;
  const isUser = props.tabKey === ChatViewerTabKey.User;

  return (
    <Card
      aria-selected={selected}
      background={selected ? "background.accordion" : undefined}
      border="1px solid"
      borderColor="background.stroke"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      cursor="pointer"
      marginBottom="0.5em"
      padding="16px"
      paddingTop={isUser ? undefined : "0.1em"}
      position="relative"
      role="tab"
      tabIndex={0}
      width="100%"
      onClick={handleSelect}
      onKeyUp={(e) => (e.key === "Enter" ? handleSelect() : null)}
    >
      <Flex direction="column" p={0}>
        {isUser ? (
          <ChatViewerUserCard {...props} />
        ) : (
          <ChatViewerVolunteerCard {...props} />
        )}
      </Flex>
    </Card>
  );
};

export default ChatViewerCard;

import React from "react";
import { useTranslation } from "react-i18next";

import AsyncResources from "../../../shared/components/async/AsyncResources";
import Link from "../../../shared/components/link";
import Main from "../../../shared/components/main/Main";
import MainBody from "../../../shared/components/main/MainBody";
import MainHeader from "../../../shared/components/main/MainHeader";
import useResourceRoute from "../../../shared/components/store/hooks/useResourceRoute";
import {
  BlogAuthorResource,
  CollectionResource,
  ResourceType,
} from "../../../types/serializers";
import Breadcrumbs from "../../components/breadcrumbs";
import DefaultLayout from "../../layouts/DefaultLayout";

const BlogAuthorItem = ({ id, attributes, type }: BlogAuthorResource) => {
  const route = useResourceRoute({ id, type });

  return (
    <li key={id}>
      <Link href={route}>{attributes.name}</Link>
    </li>
  );
};

const BlogAuthorIndexPage = ({
  resource,
}: {
  resource: CollectionResource<ResourceType.BlogAuthor>;
}) => {
  const { t } = useTranslation();

  return (
    <Main>
      <DefaultLayout>
        <Breadcrumbs
          items={[{ href: "/", label: t("main:breadcrumbs.home") }]}
        />
        <MainHeader title={t("main:blogAuthors.showAll")} />
        <MainBody>
          <ul>
            <AsyncResources resources={resource.relationships.items.data}>
              {(blogAuthor) => <BlogAuthorItem {...blogAuthor} />}
            </AsyncResources>
          </ul>
        </MainBody>
      </DefaultLayout>
    </Main>
  );
};

export default BlogAuthorIndexPage;

import { Text } from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC, MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";

import useCollection from "../../../shared/hooks/useCollection";
import { getCollectionId } from "../../../shared/lib/ids";
import {
  ChatConversationResource,
  ChatType,
  CollectionOrderDirection,
  ResourceType,
} from "../../../types/serializers";

interface UserQuestionsLinkProps {
  chatConversation: ChatConversationResource;
}

const UserQuestionsLink: FC<UserQuestionsLinkProps> = ({
  chatConversation,
}) => {
  const navigate = useNavigate();
  const collectionOpts = {
    filter: {
      chatType: [ChatType.VolunteerChat],
      userId: chatConversation.relationships.user.data
        ? [chatConversation.relationships.user.data.id]
        : [],
    },
    order: {
      lastMessageAt: CollectionOrderDirection.Desc,
    },
  };
  const chatConversationCollection = useCollection(
    ResourceType.ChatConversation,
    collectionOpts
  );
  const navigateToUserQuestions: MouseEventHandler<HTMLParagraphElement> = (
    e
  ) => {
    e.stopPropagation();
    const location = `${getCollectionId(
      ResourceType.ChatConversation,
      collectionOpts
    ).replace("chat_conversations", "/dashboard")}#archive`;
    navigate(location);
  };

  return (
    <Text
      _hover={{ textDecoration: "underline" }}
      cursor="pointer"
      display="inline"
      fontSize="14px"
      lineHeight="21px"
      onClick={navigateToUserQuestions}
    >
      {t("main:chat.card.questionsCount", {
        count: chatConversationCollection?.attributes.totalCount ?? 0,
      })}
    </Text>
  );
};

export default UserQuestionsLink;

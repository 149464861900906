import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import { FaqAccordion, useAccordionItems } from "../faqAccordion";

const VolunteerHelpTab = () => {
  const generalAccordionItems = useAccordionItems("main:general.accordion");
  const dashboardAccordionItems = useAccordionItems(
    "main:faqDashboard.accordion"
  );
  const platformUsageAccordionItems = useAccordionItems(
    "main:platformUsage.accordion"
  );
  const workplaceUsageAccordionItems = useAccordionItems(
    "main:workplaceUsage.accordion"
  );
  const rulesQualityPolicyAccordionItems = useAccordionItems(
    "main:rulesQualityPolicy.accordion"
  );

  return (
    <Box>
      <Heading as="h2" variant="lg">
        {t("main:account.volunteer.help.header")}
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="20px">
        <Box>
          <Heading as="h2" my="1em" textAlign="left" variant="sm" w="full">
            {t("main:general.accordion_title")}
          </Heading>
          <Box flex="1" maxW="880px" mb="2em">
            <FaqAccordion
              accordionItems={generalAccordionItems}
              variant="home"
            />
          </Box>
        </Box>
        <Box>
          <Heading as="h2" my="1em" textAlign="left" variant="sm" w="full">
            {t("main:faqDashboard.accordion_title")}
          </Heading>
          <Box flex="1" maxW="880px" mb="2em">
            <FaqAccordion
              accordionItems={dashboardAccordionItems}
              variant="home"
            />
          </Box>
        </Box>
        <Box>
          <Heading as="h2" my="1em" textAlign="left" variant="sm" w="full">
            {t("main:platformUsage.accordion_title")}
          </Heading>
          <Box flex="1" maxW="880px" mb="2em">
            <FaqAccordion
              accordionItems={platformUsageAccordionItems}
              variant="home"
            />
          </Box>
        </Box>
        <Box>
          <Heading as="h2" my="1em" textAlign="left" variant="sm" w="full">
            {t("main:workplaceUsage.accordion_title")}
          </Heading>
          <Box flex="1" maxW="880px" mb="2em">
            <FaqAccordion
              accordionItems={workplaceUsageAccordionItems}
              variant="home"
            />
          </Box>
        </Box>
        <Box>
          <Heading as="h2" my="1em" textAlign="left" variant="sm" w="full">
            {t("main:rulesQualityPolicy.accordion_title")}
          </Heading>
          <Box flex="1" maxW="880px" mb="2em">
            <FaqAccordion
              accordionItems={rulesQualityPolicyAccordionItems}
              variant="home"
            />
          </Box>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default VolunteerHelpTab;

import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import { ModalRenderSharedProps } from "../../../../shared/components/modal/ModalContextProvider";
import useActionCallback from "../../../../shared/hooks/useActionCallback";
import {
  ChatConversationResource,
  ChatConversationStatus,
} from "../../../../types/serializers";

export interface CloseConfirmationModalProps extends ModalRenderSharedProps {
  chatConversation: ChatConversationResource;
}

export const CloseConfirmationModal = ({
  handleClose,
  chatConversation,
}: CloseConfirmationModalProps) => {
  const [callback, pending] = useActionCallback(chatConversation, "wrap_up");
  const pendingAcceptance =
    chatConversation?.attributes.status ===
    ChatConversationStatus.PendingAcceptance;

  const handleClick = () => {
    callback({}, handleClose);
  };

  return (
    <ModalContent>
      <ModalHeader>
        {pendingAcceptance
          ? t("main:chat.cancelConfirmation.label")
          : t("main:chat.closeConfirmation.label")}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {pendingAcceptance
          ? t("main:chat.cancelConfirmation.body")
          : t("main:chat.closeConfirmation.body")}
      </ModalBody>
      <ModalFooter>
        <Button isDisabled={pending} variant="solid" onClick={handleClick}>
          {pendingAcceptance
            ? t("main:chat.cancelConfirmation.button")
            : t("main:chat.closeConfirmation.button")}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

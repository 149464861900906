import { Button } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";

import { ChatConversationResource } from "../../../../../types/serializers";
import { ChatViewerTabKey } from "../../../chatViewer/lib/tabs";

const ShowButton = ({
  chatConversation,
  tabKey,
}: {
  chatConversation: ChatConversationResource;
  tabKey: ChatViewerTabKey;
}) => {
  if (tabKey === ChatViewerTabKey.Review) {
    return null;
  }

  return (
    <Button
      as={Link}
      color="primary.dark"
      leftIcon={<FiEye />}
      marginTop="12px"
      to={`/chat_conversations/${chatConversation.id}`}
      variant="link"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {t("main:chat.buttons.show")}
    </Button>
  );
};

export default ShowButton;

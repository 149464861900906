import React, { PropsWithChildren } from "react";

import Layout from "../components/layout/Layout";

const desktopTemplate = '"breadcrumbs" "header" "body" "footer"';
const mobileTemplate = '"breadcrumbs" "header" "body" "footer"';

const DefaultLayout = ({ children }: PropsWithChildren) => {
  return (
    <Layout
      gridTemplateColumns="1fr"
      templateAreas={{ base: mobileTemplate, md: desktopTemplate }}
    >
      {children}
    </Layout>
  );
};

export default DefaultLayout;

import { Box, Heading, LinkBox, chakra } from "@chakra-ui/react";
import React from "react";

import Image from "../../../shared/components/image";
import Link from "../../../shared/components/link";
import RichText from "../../../shared/components/richText";
import useResourceRoute from "../../../shared/components/store/hooks/useResourceRoute";
import useResource from "../../../shared/hooks/useResource";
import { BlogResource } from "../../../types/serializers";

import BlogDetails from "./BlogDetails";

interface BlogPreviewProps {
  blog: BlogResource;
  details?: boolean;
  headingSize?: string;
}

const BlogPreview = ({ blog, details, headingSize }: BlogPreviewProps) => {
  const { attributes, id, relationships, type } = blog;
  const coverImage = useResource(relationships.coverImage.data);
  const route = useResourceRoute({ id, type });

  return (
    <LinkBox
      _hover={{ borderColor: "gray.dark" }}
      border="1px solid"
      borderColor="background.stroke"
      borderRadius="0.5em"
      overflow="hidden"
    >
      <Box>
        {coverImage ? (
          <Image
            alt={coverImage.attributes.alt}
            baseEncoded={coverImage.attributes.baseEncoded}
            height={coverImage.attributes.coverHeight}
            opacity={attributes.published ? undefined : "0.5"}
            src={coverImage.attributes.coverUrl}
            width={coverImage.attributes.coverWidth}
          />
        ) : (
          <chakra.div
            backgroundColor="gray.200"
            paddingTop="66.66%"
            width="100%"
          />
        )}
      </Box>
      <Link overlay href={route}>
        <Box display="none">{blog.attributes.title}</Box>
      </Link>
      <Box height="14em" overflow="hidden" padding="0.8em" position="relative">
        <Heading as="h3" paddingBottom="0.5em" size={headingSize ?? "md"}>
          <Link overlay href={route}>
            {attributes.published
              ? attributes.title
              : `${attributes.title} (concept)`}
          </Link>
        </Heading>
        {details && <BlogDetails blog={blog} />}
        <RichText textStyle="bodyBlog">{blog.attributes.intro}</RichText>
        <Box
          bgGradient="linear(to-b, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)"
          bottom="0"
          height="2em"
          pointerEvents="none"
          position="absolute"
          width="100%"
        />
      </Box>
    </LinkBox>
  );
};

export default BlogPreview;

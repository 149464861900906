import React from "react";

import Main from "../../../shared/components/main/Main";
import HomeAbout from "../../components/home/HomeAbout";
import HomeAnecdotes from "../../components/home/HomeAnecdotes";
import HomeHeader from "../../components/home/HomeHeader";
import PillarShowcase from "../../components/home/PillarShowcase";

const HomePage: React.FC = () => (
  <Main marginTop={0}>
    <HomeHeader />
    <PillarShowcase />
    <HomeAbout />
    <HomeAnecdotes />
  </Main>
);

export default HomePage;

import { t } from "i18next";
import React from "react";

import { getCollectionId } from "../../../../shared/lib/ids";
import {
  ChatConversationStatus,
  ChatType,
  CollectionOrderDirection,
  ResourceType,
} from "../../../../types/serializers";
import { ChatViewerTab } from "../ChatViewerTabs";
import EmptyMessage from "../EmptyMessage";

export const reverseDirection = (direction: CollectionOrderDirection) =>
  direction === CollectionOrderDirection.Desc
    ? CollectionOrderDirection.Asc
    : CollectionOrderDirection.Desc;

export const getTabCollectionId = (
  tab: ChatViewerTab,
  reverseList?: boolean
) => {
  const collectionOpts = {
    filter: tab.filter,
    order: {
      [tab.orderKey]: reverseList
        ? reverseDirection(tab.orderDirection)
        : tab.orderDirection,
    },
    pageSize: 10,
  };

  return getCollectionId(ResourceType.ChatConversation, collectionOpts);
};

export enum ChatViewerTabKey {
  ForYouNew = "for_you_new",
  ForYouTodo = "for_you_todo",
  ForYouWaiting = "for_you_waiting",
  Following = "following",
  Review = "review",
  User = "user",
}

export const forYouTabs = [
  {
    filter: {
      chatType: [ChatType.VolunteerChat],
      declined: ["false"],
      followed: ["false"],
      relevant: ["true"],
      status: [ChatConversationStatus.PendingAcceptance],
    },
    key: ChatViewerTabKey.ForYouNew,
    label: t("main:dashboard.forYou.tabs.new.label"),
    onEmpty: () => (
      <EmptyMessage
        icon="party"
        message={t("main:dashboard.forYou.tabs.new.empty")}
      />
    ),
    orderDirection: CollectionOrderDirection.Asc,
    orderKey: "createdAt",
    showCount: true,
  },
  {
    filter: {
      accepted: ["true"],
      chatType: [ChatType.VolunteerChat],
      status: [ChatConversationStatus.Active],
      waitingForGuide: ["true"],
    },
    key: ChatViewerTabKey.ForYouTodo,
    label: t("main:dashboard.forYou.tabs.todo.label"),
    onEmpty: () => (
      <EmptyMessage
        icon="party"
        message={t("main:dashboard.forYou.tabs.todo.empty")}
      />
    ),
    orderDirection: CollectionOrderDirection.Desc,
    orderKey: "lastMessageAt",
    showCount: true,
  },
  {
    filter: {
      accepted: ["true"],
      chatType: [ChatType.VolunteerChat],
      status: [ChatConversationStatus.Active],
      waitingForGuide: ["false"],
    },
    key: ChatViewerTabKey.ForYouWaiting,
    label: t("main:dashboard.forYou.tabs.waiting.label"),
    onEmpty: () => (
      <EmptyMessage
        icon="party"
        message={t("main:dashboard.forYou.tabs.waiting.empty")}
      />
    ),
    orderDirection: CollectionOrderDirection.Desc,
    orderKey: "lastMessageAt",
    showCount: true,
  },
  {
    filter: {
      accepted: ["true"],
      chatType: [ChatType.VolunteerChat],
      status: [
        ChatConversationStatus.HelpedQuery,
        ChatConversationStatus.Closed,
      ],
    },
    key: ChatViewerTabKey.ForYouWaiting,
    label: t("main:dashboard.forYou.tabs.finished.label"),
    onEmpty: () => (
      <EmptyMessage
        icon="speech"
        message={t("main:dashboard.forYou.tabs.finished.empty")}
      />
    ),
    orderDirection: CollectionOrderDirection.Desc,
    orderKey: "lastMessageAt",
  },
];

export const followingTabs = [
  {
    filter: {
      chatType: [ChatType.VolunteerChat],
      followed: ["true"],
    },
    key: ChatViewerTabKey.Following,
    label: t("main:dashboard.following.label"),
    onEmpty: () => (
      <EmptyMessage
        icon="speech"
        message={t("main:dashboard.following.empty")}
      />
    ),
    orderDirection: CollectionOrderDirection.Desc,
    orderKey: "lastMessageAt",
  },
];

export const reviewTabs = [
  {
    filter: {
      toReview: ["true"],
    },
    key: ChatViewerTabKey.Review,
    label: t("main:dashboard.review.label"),
    onEmpty: () => (
      <EmptyMessage icon="speech" message={t("main:dashboard.review.empty")} />
    ),
    orderDirection: CollectionOrderDirection.Desc,
    orderKey: "createdAt",
  },
];

export const userTabs = [
  {
    filter: {
      chatType: [ChatType.VolunteerChat],
    },
    key: ChatViewerTabKey.User,
    label: t("main:dashboard.user.label"),
    onEmpty: () => (
      <EmptyMessage icon="speech" message={t("main:account.user.chat.empty")} />
    ),
    orderDirection: CollectionOrderDirection.Desc,
    orderKey: "lastMessageAt",
  },
];

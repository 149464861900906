import {
  Button,
  Container,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";

import Main from "../../../shared/components/main/Main";
import MainHeader from "../../../shared/components/main/MainHeader";
import useCurrentUser from "../../../shared/hooks/useCurrentUser";
import useUpdateCallback from "../../../shared/hooks/useUpdateCallback";
import { CURRENT_USER_ID } from "../../../shared/lib/ids";
import { ResourceType } from "../../../types/serializers";

const CodeOfConduct = () => {
  const [currentUser] = useCurrentUser();
  const [updateCallback, loading] = useUpdateCallback({
    id: CURRENT_USER_ID,
    type: ResourceType.User,
  });

  return (
    <Main>
      <Container maxWidth="container.lg">
        <MainHeader title="Gedragscode Kandoor voor Vrijwilligers" />
        <Heading as="h3" variant="sm">
          Waarom bestaat Kandoor en wat is Kandoor?
        </Heading>
        <Text marginBottom="1em">
          Het vertrouwen in financiële instellingen is laag. Daar is genoeg over
          gezegd en geschreven. Wij willen vooruitkijken. Dat vooruitkijken
          begint met nadenken over een antwoord op de kapitale vraag: hoe kunnen
          wij bijdragen aan een financieel gezondere maatschappij? Niet
          gebaseerd op ieder-voor-zich, maar samen voor elkaar. Zoals wij mensen
          van nature zijn: sociaal en behulpzaam.
        </Text>
        <Text marginBottom="1em">
          Vanuit die visie bestaat Kandoor: een platform waar vrijwilligers
          antwoord geven op vragen rondom geldzaken aan mensen die financiële
          onrust of stress ervaren. De vrijwilligers geven geen financieel
          advies. Ze geven informatie en richting. Bijvoorbeeld over pensioen,
          belasting, schulden, studie, werk, wonen of een ander financieel
          onderwerp. En de vraagsteller kan daarna zelf een beslissing nemen of
          door naar een pensioenfonds, financieel adviseur of bank.
        </Text>
        <Heading as="h4" variant="xs">
          De pijlers van onze gedragscode zijn:
        </Heading>
        <UnorderedList marginBottom="1em">
          <ListItem>Respect</ListItem>
          <ListItem>Integriteit</ListItem>
          <ListItem>Deskundigheid</ListItem>
          <ListItem>Zorgvuldigheid</ListItem>
        </UnorderedList>
        <Heading as="h4" variant="xs">
          Ook nemen wij de volgende punten op:
        </Heading>
        <UnorderedList marginBottom="1em">
          <ListItem>Aansprakelijkheid</ListItem>
          <ListItem>Copyright</ListItem>
          <ListItem>Intellectuele eigendomsrechten</ListItem>
          <ListItem>Klachtenregeling</ListItem>
        </UnorderedList>
        <Heading as="h3" variant="sm">
          Respect
        </Heading>
        <Heading as="h4" variant="xs">
          Respect voor elkaar
        </Heading>
        <Text marginBottom="1em">
          Kandoor vindt het respecteren van anderen belangrijk. Eén van de
          grootste kwaliteiten van Kandoor is dat men zich gehoord en
          onbevooroordeeld voelt.
        </Text>
        <Text marginBottom="1em">
          Vrijwilligers behandelen gebruikers en andere vrijwilligers daarom met
          respect. Zij maken geen racistische, beledigende, seksistische,
          discriminerende, bedreigende of andere kwetsende opmerkingen. Ze
          gebruiken geen grof taalgebruik, maken geen misplaatste grappen en
          vallen andere gebruikers of vrijwilligers op geen enkele manier
          lastig.
        </Text>
        <Heading as="h4" variant="xs">
          Respect voor het platform
        </Heading>
        <Text marginBottom="1em">
          Het platform wordt met respect gebruikt. Dit betekent niet gebruiken
          als testomgeving, niet in presentaties voor andere partijen zonder
          toestemming van Kandoor. Je mag de werking van het platform, de
          website, andere computers of systemen van Kandoor en andere gebruikers
          niet verstoren. Denk aan het verspreiden van virussen (inclusief
          Trojaanse paarden, tijdbommen e.d.) en het hacken of een ander toegang
          geven tot de computers of systemen van Kandoor of andere gebruikers.
          Of het wissen van bestanden op computers of systemen van Kandoor of
          andere gebruikers.
        </Text>
        <Heading as="h3" variant="sm">
          Integriteit
        </Heading>
        <Text marginBottom="1em">
          Vrijwilligers gaan zorgvuldig om met de informatie die zij delen met
          gebruikers/vraagstellers, zowel in de eigen teksten die zij schrijven,
          als de links naar websites met informatie.
        </Text>
        <Heading as="h4" variant="xs">
          Het online platform mag niet worden gebruikt voor:
        </Heading>
        <UnorderedList marginBottom="1em">
          <ListItem>
            het geven van (financieel) advies. Bijvoorbeeld het sturen naar een
            financieel product of het adviseren bij een financiële keuze.
          </ListItem>
          <ListItem>
            commerciële activiteiten. Bijvoorbeeld het aanprijzen of de verkoop
            van producten of diensten, het sturen van commerciële boodschappen
            (spam), het aanbieden van (interactieve) spelletjes of het
            aanprijzen van andere chatkanalen of websites.
          </ListItem>
          <ListItem>
            het onnodig opvragen van gevoelige persoonsgegevens van gebruikers
            in het kader van de AVG. Alleen informatie die nodig is om de vraag
            van de gebruiker te beantwoorden.
          </ListItem>
          <ListItem>
            wettelijk niet toegestane activiteiten en handelingen, zoals de
            handel in soft- en harddrugs, de handel in of verspreiding van
            (kinder)porno, de handel of verspreiding van bijvoorbeeld
            mp3-bestanden of andere zaken waar auteursrecht op zit.
          </ListItem>
          <ListItem>
            het hengelen naar contacten. Kandoor.nl is geen datingsite;
          </ListItem>
          <ListItem>
            het vragen om antwoorden en/of reacties via privébericht, telefoon
            of per e-mail.
          </ListItem>
        </UnorderedList>
        <Text marginBottom="1em">
          Vrijwilligers mogen niet doen alsof ze moderator van het online
          platform zijn of andermans identiteit gebruiken. Zij zullen
          aanwijzingen van de redactie en moderators steeds opvolgen.
        </Text>
        <Heading as="h3" variant="sm">
          Deskundigheid
        </Heading>
        <Heading as="h4" variant="xs">
          Vrijwilligers zijn helder en bondig in het beantwoorden van de vragen:
        </Heading>
        <UnorderedList marginBottom="1em">
          <ListItem>
            De tekst van je bericht is een antwoord op de vraag, en in het
            antwoord zal alleen ingegaan worden op onderwerpen die bijdragen aan
            het beantwoorden van de vraag.
          </ListItem>
          <ListItem>
            Bronnen die worden gebruikt in antwoorden zijn bij voorkeur van
            Kandoor zelf, of een onafhankelijke en niet-commerciële bron. Denk
            bijvoorbeeld aan Rijksoverheid, AFM, Wijzer in Geldzaken of de SVB.
          </ListItem>
          <ListItem>
            Wees begripvol, empathisch en respectvol als je reageert richting
            andere gebruikers, vrijwilligers, supervisors, moderators en het
            team van Kandoor.
          </ListItem>
          <ListItem>
            Gebruik geen shockerende of ongepaste afbeeldingen of teksten bij je
            reactie.
          </ListItem>
        </UnorderedList>
        <Heading as="h3" variant="sm">
          Zorgvuldigheid
        </Heading>
        <Heading as="h4" variant="xs">
          Kandoor adviseert vrijwilligers om zorgvuldig te zijn bij het gebruik
          van het online platform:
        </Heading>
        <UnorderedList marginBottom="1em">
          <ListItem>
            Maak geen persoonlijke informatie aan gebruikers bekend.{" "}
          </ListItem>
          <ListItem>
            Verplaats je in de vraagsteller: verwacht deze meer dan informatie?
            Leg uit dat je geen financieel advies geeft.{" "}
          </ListItem>
          <ListItem>
            Kandoor zorgt daarnaast ook voor duidelijke verwachtingspatronen, en
            staat altijd open voor verbetering van de werking en ervaring op het
            platform.
          </ListItem>
        </UnorderedList>
        <Heading as="h4" variant="xs">
          Belangrijk:
        </Heading>
        <UnorderedList marginBottom="1em">
          <ListItem>
            Zorg voor goede beveiliging van je computer(s) en andere systemen.
            Installeer hiervoor software of hardware die toegang tot jouw
            computer(s) of systemen blokkeert.
          </ListItem>
          <ListItem>
            Zorg er daarnaast voor dat jouw computer(s) of systemen geen
            virussen verspreiden. Denk hierbij aan firewalls en
            antivirus-software. Zorg er tot slot voor dat deze software
            up-to-date is.
          </ListItem>
        </UnorderedList>
        <Heading as="h3" variant="sm">
          Aansprakelijkheid
        </Heading>
        <Text marginBottom="1em">
          Als vrijwilliger ben je zelf verantwoordelijk voor berichten die je
          stuurt. Kandoor is niet aansprakelijk voor onjuiste informatie die
          gegeven is door een vrijwilliger. Als vrijwilliger geef je geen
          (financieel) advies.
        </Text>
        <Text marginBottom="1em">
          Kandoor is niet aansprakelijk voor enige schade als gevolg van het
          gebruik van het online platform door vrijwilligers. De financieel
          vrijwilliger vrijwaart hierbij Kandoor voor alle schade en kosten die
          Kandoor lijdt of maakt als gevolg van aanspraken van gebruikers of
          derden, die worden veroorzaakt door het in strijd met deze gedragscode
          of anderszins onrechtmatig handelen van de vrijwilliger op het online
          platform.
        </Text>
        <Heading as="h3" variant="sm">
          Copyright
        </Heading>
        <Text marginBottom="1em">
          Kandoor behoudt het recht, tenzij anders overeengekomen met de
          vrijwilliger, om ingezonden materiaal in te korten en aan te passen.
          Een vrijwilliger mag geen auteursrechtelijk beschermde werken van
          derden of van Kandoor of andere in Kandoor.nl opgeslagen informatie
          en/of dialogen openbaar maken of verveelvoudigen zonder toestemming
          van Kandoor of de eigenaar van de auteursrechtelijk beschermde werken.
        </Text>
        <Heading as="h3" variant="sm">
          Intellectuele eigendomsrechten
        </Heading>
        <Text marginBottom="1em">
          Alle (intellectuele) eigendomsrechten met betrekking tot de naam
          Kandoor en het logo van Kandoor, inclusief de merkrechten, berusten
          uitsluitend bij APG Groep N.V.. De (auteurs-)rechten met betrekking
          tot het format en de inhoud van de website Kandoor (onder meer de
          daarin opgenomen gegevens, afbeeldingen, geluiden, teksten, look &
          feel, lay-out) berusten uitsluitend bij APG Groep N.V.
        </Text>
        <Text marginBottom="1em">
          Zonder voorafgaande schriftelijke toestemming van APG Groep N.V. is
          het onder meer niet toegestaan de website Kandoor.nl, of enig
          onderdeel daarvan, openbaar te maken en/of te (doen) verveelvoudigen
          (waaronder in ieder geval begrepen aanbieden, (doen) vervaardigen,
          verkopen, leveren of anderszins verhandelen). Zogenaamde ‘framing’ en
          ‘webscraping’ valt uitdrukkelijk onder het op ontoelaatbare wijze
          openbaar maken/verveelvoudigen van (onderdelen van) de website.
        </Text>
        <Heading as="h3" variant="sm">
          Klachtenregeling
        </Heading>
        <Text marginBottom="1em">
          Vind je dat het gedrag van andere gebruikers of van vrijwilligers niet
          past binnen de gebruikersvoorwaarden van de website of deze
          gedragscode? Stuur dan een e-mail aan info@kandoor.nl. Als we merken
          dat een gebruiker of vrijwilliger zich niet aan de
          gebruikersvoorwaarden of de gedragscode houdt, nemen we maatregelen.
          Zo passen we bijvoorbeeld berichten aan of verwijderen we een bericht.
          Soms vragen we een gebruiker of vrijwilliger de berichten zelf aan te
          passen of te verwijderen. Ook kunnen we een gebruiker of vrijwilliger
          tijdelijk of permanent uitsluiten van toegang tot het Kandoorplatform.
        </Text>
        <Heading as="h3" variant="sm">
          Accepteren van de Gedragscode door de vrijwilliger
        </Heading>
        <Text marginBottom="1em">
          Voordat je als vrijwilliger wordt toegelaten op het Kandoorplatform
          heb je het verzoek gekregen om deze gedragscode te accepteren.
        </Text>
        <Text marginBottom="1em">
          Kandoor behoudt zich het recht om wijzigingen aan te brengen aan deze
          gedragscode. Wij adviseren je deze gedragscode daarom met regelmaat te
          lezen.
        </Text>
        <Text fontStyle="italic" marginBottom="1em">
          Deze gedragscode is voor het laatst bijgewerkt op 21 mei 2024.
        </Text>
        {currentUser && !currentUser.attributes.codeOfConductApprovedAt && (
          <Button
            isDisabled={loading}
            onClick={() =>
              updateCallback({
                codeOfConductApprovedAt: new Date().toString(),
              })
            }
          >
            Accepteer
          </Button>
        )}
      </Container>
    </Main>
  );
};

export default CodeOfConduct;

import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import Container from "../../../shared/components/layout/Container";

import AnecdoteCollection from "./AnecdoteCollection";
import DieuwertjeIcon from "./images/dieuwertje.webp";
import EmmaIcon from "./images/emma.webp";
import RonIcon from "./images/ron.webp";
import PrimaryAnecdote from "./PrimaryAnecdote";

const HomeAnecdotes: React.FC = () => {
  const { t } = useTranslation();

  const previews = [
    {
      href: "/blogs/interview-beleggen-pensioen-ron",
      imageSrc: RonIcon,
      name: t("main:home.anecdotes.preview3.name"),
      previewText: t("main:home.anecdotes.preview3.text"),
    },
    {
      href: "/blogs/interview-moederschap-emma",
      imageSrc: EmmaIcon,
      name: t("main:home.anecdotes.preview1.name"),
      previewText: t("main:home.anecdotes.preview1.text"),
    },
    {
      href: "/blogs/interview-werken-naast-pensioen-dieuwertje",
      imageSrc: DieuwertjeIcon,
      name: t("main:home.anecdotes.preview2.name"),
      previewText: t("main:home.anecdotes.preview2.text"),
    },
  ];

  return (
    <Container mt="2.5em">
      <Heading as="h2" mb={1} variant="lg">
        {t("main:home.anecdotes.title")}
      </Heading>
      <Text color="gray.subheader" fontSize={18} fontWeight={500} mb="1em">
        {t("main:home.anecdotes.subtitle")}
      </Text>
      <Box
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Box
          flexBasis={{ base: "100%", md: "50%", lg: "60%", xl: "70%" }}
          marginBottom={{ base: "2em", md: "0" }}
          marginRight={{ md: "2em", lg: "4em" }}
          position="relative"
        >
          <PrimaryAnecdote />
        </Box>
        <Box flexBasis={{ base: "100%", md: "50%", lg: "40%", xl: "30%" }}>
          <Heading as="h2" mb="1em" variant="sm">
            {t("main:home.anecdotes.more")}
          </Heading>
          <AnecdoteCollection previews={previews} />
        </Box>
      </Box>
    </Container>
  );
};

export default HomeAnecdotes;

import ReactOnRails from "react-on-rails";
import { ReactComponentOrRenderFunction } from "react-on-rails/node_package/lib/types";

import "../shared/lib/i18n";

import Main from "../mainPack/Main";

// This is how react_on_rails can see the SERVER_BUNDLE_ONLY in the browser.
ReactOnRails.register({ Main } as unknown as {
  [key: string]: ReactComponentOrRenderFunction;
});

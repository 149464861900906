import { Box } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

import Container from "../../shared/components/layout/Container";

const DEFAULT_PADDING_Y = 20;

interface PageRowProps {
  backdrop?: boolean;
  paddingY?: number;
}

const PageRow = ({
  backdrop,
  children,
  paddingY = DEFAULT_PADDING_Y,
}: PropsWithChildren<PageRowProps>) => (
  <Box backgroundColor={backdrop ? "background.footer" : undefined}>
    <Container paddingY={paddingY}>{children}</Container>
  </Box>
);

export default PageRow;

import {
  Box,
  Flex,
  Heading,
  List,
  ListItem,
  VStack,
  chakra,
} from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Container from "../../../shared/components/layout/Container";
import RichText from "../../../shared/components/richText";
import GptButton from "../buttons/GptButton";
import QuestionButton from "../buttons/QuestionButton";

import HomeAnimation from "./images/home_animation.svg";

const HomeHeader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isABTestActive = queryParams.get("abt") === "true";

  return (
    <Box width="100%">
      <Helmet>
        <title>{t("main:home.header.title.window")}</title>
      </Helmet>
      <Container minHeight="300px" position="relative">
        <Flex
          align="start"
          borderBottom="solid 0.5px"
          borderColor="background.stroke"
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          paddingY="3em"
        >
          <VStack align="start" flexShrink={0} mr={4} spacing={8}>
            <Box>
              <Heading as="h1" color="#7A7A7A" mb="0.1em" variant="xl">
                {t("main:home.header.title.part_1")}
              </Heading>
              <Heading as="h1" mb={0} variant="xxl">
                {t("main:home.header.title.part_2")}
              </Heading>
            </Box>
            <List
              listStylePosition="outside"
              paddingLeft="1.5em"
              spacing={2}
              styleType="disc"
            >
              <ListItem>{t("main:home.header.subtitle1")}</ListItem>
              <ListItem>
                <RichText>{t("main:home.header.subtitle2")!}</RichText>
              </ListItem>
            </List>
            {isABTestActive ? (
              <>
                <GptButton variant="solid">
                  {t("main:home.header.askChatQuestion")}
                </GptButton>
                <QuestionButton
                  referer="homeHeaderAB"
                  trackEventName="click_volunteer_button"
                >
                  {t("main:home.header.askVolunteerQuestion")}
                </QuestionButton>
              </>
            ) : (
              <QuestionButton referer="homeHeader">
                {t("main:home.header.askQuestion")}
              </QuestionButton>
            )}
          </VStack>
          <chakra.object
            data={HomeAnimation}
            marginTop={["2em", "2em", "1em"]}
            maxWidth="40em"
            minWidth={0}
            type="image/svg+xml"
          >
            svg-animation
          </chakra.object>
        </Flex>
      </Container>
    </Box>
  );
};

export default HomeHeader;

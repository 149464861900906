import React, { PropsWithChildren } from "react";

import Layout from "../components/layout/Layout";

const desktopTemplate =
  '"breadcrumbs breadcrumbs" ' +
  '"header header" ' +
  '"left body" ' +
  '"left footer"';

const mobileTemplate =
  '"breadcrumbs" ' + '"header" ' + '"left" ' + '"body" ' + '"footer" ';

const Layout3_9 = ({ children }: PropsWithChildren) => {
  return (
    <Layout
      as="article"
      gridTemplateColumns={{
        base: "1fr",
        lg: "1fr 3fr",
      }}
      templateAreas={{ base: mobileTemplate, lg: desktopTemplate }}
    >
      {children}
    </Layout>
  );
};

export default Layout3_9;

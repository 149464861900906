import {
  Box,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";

import { ModalRenderSharedProps } from "../../../../shared/components/modal/ModalContextProvider";
import useUpdateCallback from "../../../../shared/hooks/useUpdateCallback";
import {
  ChatConversationResource,
  ChatMessageResource,
} from "../../../../types/serializers";

export interface FeedbackModalProps extends ModalRenderSharedProps {
  chatConversation: ChatConversationResource;
  chatMessage: ChatMessageResource;
}

export const FeedbackModal = ({
  handleClose,
  chatMessage,
}: FeedbackModalProps) => {
  const [updateChatConversation, loading] = useUpdateCallback(
    chatMessage.relationships.feedback!.data!
  );
  const toast = useToast();
  const [customFeedback, setCustomFeedback] = useState("");
  const handleSubmitFeedback = (feedback: string) => {
    const toastDescription = t(
      "main:kandoorGPT.chat.toast_success_description"
    );
    const handleSuccess = () => {
      toast({
        description: toastDescription,
      });
      handleClose();
    };
    updateChatConversation(
      {
        feedback,
      },
      handleSuccess
    );
  };

  return (
    <ModalContent>
      <ModalHeader>
        {t("main:kandoorGPT.chat.negativefeedback_header")}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box fontSize="lg" mb="32px">
          {t("main:kandoorGPT.chat.negativefeedback_subheader")}
        </Box>
        <Textarea
          border="1px"
          height="150px"
          isDisabled={loading}
          placeholder={
            t("main:kandoorGPT.chat.negativefeedback_textarea_placeholder")!
          }
          value={customFeedback}
          onChange={(e) => setCustomFeedback(e.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          isDisabled={loading}
          onClick={() => handleSubmitFeedback(customFeedback)}
        >
          {t("main:kandoorGPT.chat.input.sendButton")}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export default FeedbackModal;

import { Box, List, ListItem, Text } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";

import useCurrentUser from "../../../../shared/hooks/useCurrentUser";
import SignInForm from "../../auth/SignInForm";

import { ChatInput } from "./ChatInput";

interface InformationCardProps {
  onSubmit: (message: string) => void;
  requireAuthentication?: boolean;
}

const InformationCard: React.FC<InformationCardProps> = ({
  onSubmit,
  requireAuthentication,
}) => {
  const [currentUser] = useCurrentUser();
  const [message, setMessage] = useState("");
  const [showSignInForm, setShowSignInForm] = useState(false);
  const handleSubmit = (msg: string) => {
    if (requireAuthentication && !currentUser?.attributes.firstname) {
      setMessage(msg);
      setShowSignInForm(true);
    } else {
      onSubmit(msg);
    }
  };
  const handleSignIn = () => {
    onSubmit(message);
  };

  return (
    <Box bg="white" borderRadius="md" boxShadow="md" mb="40px" p="20px">
      <Text color="gray.800" fontSize="xl" fontWeight="bold" mb="10px">
        {t("main:kandoorGPT.infoCard.title")}
      </Text>
      <List
        listStylePosition="outside"
        paddingLeft="1.5em"
        spacing={3}
        styleType="disc"
      >
        <ListItem>{t("main:kandoorGPT.infoCard.listItem1")}</ListItem>
        <ListItem>{t("main:kandoorGPT.infoCard.listItem2")}</ListItem>
        <ListItem>{t("main:kandoorGPT.infoCard.listItem3")}</ListItem>
        <ListItem>{t("main:kandoorGPT.infoCard.listItem4")}</ListItem>
        <ListItem>{t("main:kandoorGPT.infoCard.listItem5")}</ListItem>
      </List>
      <Box mb="10px" mt="20px">
        {showSignInForm ? (
          <Box display="flex" flexDirection="column">
            <SignInForm requireName onSuccess={handleSignIn} />
          </Box>
        ) : (
          <ChatInput multiLine={true} onSubmit={handleSubmit} />
        )}
      </Box>
    </Box>
  );
};

export default InformationCard;

import { t } from "i18next";
import React from "react";
import { FiFastForward } from "react-icons/fi";

import Button from "../../../../../shared/components/button";
import useActionCallback from "../../../../../shared/hooks/useActionCallback";
import { ChatConversationResource } from "../../../../../types/serializers";
import { ChatViewerTabKey } from "../../../chatViewer/lib/tabs";

const DeclineButton = ({
  chatConversation,
  tabKey,
}: {
  chatConversation: ChatConversationResource;
  tabKey: ChatViewerTabKey;
}) => {
  const [callback, loading] = useActionCallback(chatConversation, "decline");

  if (tabKey !== ChatViewerTabKey.ForYouNew) {
    return null;
  }

  return (
    <Button
      color="primary.dark"
      isDisabled={loading}
      leftIcon={<FiFastForward />}
      marginRight="0.5em"
      marginTop="12px"
      variant="link"
      onClick={(e) => {
        e.stopPropagation();
        callback();
      }}
    >
      {t("main:chat.buttons.decline")}
    </Button>
  );
};

export default DeclineButton;

import useResources from "../../../../shared/hooks/useResources";
import {
  ChatConversationResource,
  ChatParticipationResource,
} from "../../../../types/serializers";

const participationRead = (
  chatParticipation: ChatParticipationResource,
  createdAt: number
) => {
  const readAt = Date.parse(chatParticipation?.attributes.readAt ?? "");

  return createdAt < readAt;
};

const useReadStatus = (
  isFromCurrentUser: boolean,
  creatorId: string | undefined,
  createdAt: string | undefined,
  chatConversation: ChatConversationResource
) => {
  const chatParticipations = useResources(
    chatConversation.relationships.chatParticipations.data
  );
  if (!isFromCurrentUser) {
    return false;
  }

  const paresedCreatedAt = Date.parse(createdAt ?? "");

  return (
    chatParticipations
      .filter(Boolean)
      .findIndex(
        (p) =>
          p!.relationships.user.data?.id !== creatorId &&
          participationRead(p!, paresedCreatedAt)
      ) !== -1
  );
};

export default useReadStatus;

import { Box, Text } from "@chakra-ui/react";
import React from "react";

import AsyncResource from "../../../shared/components/async/AsyncResource";
import BlogAuthorDetail from "../../../shared/components/blogAuthor/blogAuthorDetail";
import { formatDate, formatDateTime } from "../../../shared/lib/date";
import { BlogResource } from "../../../types/serializers";
import { formatPageViews } from "../../pages/blog/BlogShowPage";

const BlogDetails = ({
  author,
  blog,
}: {
  author?: boolean;
  blog: BlogResource;
}) => {
  const pageViews = blog.attributes.pageViews;
  const dateTime = blog.attributes.publishedAt ?? blog.attributes.updatedAt;

  return (
    <Box marginBottom="1em">
      {author && (
        <>
          <AsyncResource resource={blog.relationships.blogAuthor.data}>
            {(blogAuthor) => <BlogAuthorDetail blogAuthor={blogAuthor} />}
          </AsyncResource>
          <Text
            as="span"
            display={{ base: "none", md: "inline" }}
            ml="0.6em"
            mr="0.6em"
          >
            |
          </Text>
        </>
      )}
      <Text as="span" display={{ base: "block", md: "inline" }}>
        <time dateTime={dateTime} title={formatDateTime(dateTime)}>
          {`${formatDate(dateTime)}`}
        </time>
        <Text as="span" ml="0.6em" mr="0.6em">
          |
        </Text>
        {`${formatPageViews(pageViews)} x gelezen`}
      </Text>
    </Box>
  );
};

export default BlogDetails;

import { Heading } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import AsyncResources from "../../../shared/components/async/AsyncResources";
import Link from "../../../shared/components/link";
import Main from "../../../shared/components/main/Main";
import MainBody from "../../../shared/components/main/MainBody";
import MainHeader from "../../../shared/components/main/MainHeader";
import useIndexRoute from "../../../shared/components/store/hooks/useIndexRoute";
import useCollection from "../../../shared/hooks/useCollection";
import useStore from "../../../shared/hooks/useStore";
import { BlogAuthorResource, ResourceType } from "../../../types/serializers";
import Breadcrumbs from "../../components/breadcrumbs";
import DefaultLayout from "../../layouts/DefaultLayout";

const BlogAuthorShowPage = ({ resource }: { resource: BlogAuthorResource }) => {
  const { t } = useTranslation();
  const store = useStore();
  const blogs = useCollection(ResourceType.Blog, {
    filter: {
      blogAuthorId: [resource.id],
    },
  });
  const indexRoute = useIndexRoute(resource.type);

  return (
    <Main>
      <DefaultLayout>
        <Breadcrumbs
          items={[
            { href: "/", label: t("main:breadcrumbs.home") },
            {
              href: indexRoute,
              label: t("main:blogAuthors.showAll"),
            },
          ]}
        />
        <MainHeader title={resource.attributes.name} />
        <MainBody>
          <p>{resource.attributes.bio}</p>
          <Heading as="h2" variant="lg">
            {t("main:blogAuthors.myBlogs")}
          </Heading>
          <ul>
            <AsyncResources resources={blogs?.relationships.items.data}>
              {(blog) => (
                <li>
                  <Link href={store.router.resourceRoute(blog)}>
                    {blog.attributes.title}
                  </Link>
                </li>
              )}
            </AsyncResources>
          </ul>
        </MainBody>
      </DefaultLayout>
    </Main>
  );
};

export default BlogAuthorShowPage;

import React, { FC, PropsWithChildren } from "react";

import AppContextProvider from "../../../shared/components/appContext/AppContextProvider";
import CableProvider from "../../../shared/components/cable/CableProvider";
import ErrorHandler from "../../../shared/components/errorHandler";
import ModalContextProvider from "../../../shared/components/modal/ModalContextProvider";
import Router from "../../../shared/components/router";
import StoreContextProvider, {
  JsonAPIData,
} from "../../../shared/components/store/StoreContextProvider";
import StyleProvider from "../../../shared/components/StyleProvider";

interface WrapperProps extends PropsWithChildren {
  path: string;
  seed: JsonAPIData;
}

const Wrappers: FC<WrapperProps> = ({ children, path, seed }) => (
  <StoreContextProvider seed={{ [GLOBALS.root]: seed }}>
    <StyleProvider>
      <ErrorHandler token={GLOBALS.rollbarToken}>
        <Router location={path}>
          <ModalContextProvider>
            <CableProvider>
              <AppContextProvider>{children}</AppContextProvider>
            </CableProvider>
          </ModalContextProvider>
        </Router>
      </ErrorHandler>
    </StyleProvider>
  </StoreContextProvider>
);

export default Wrappers;

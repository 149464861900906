import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiAlertCircle } from "react-icons/fi";

import Button from "../../../../../shared/components/button";
import useCurrentUser from "../../../../../shared/hooks/useCurrentUser";
import { ChatClosedReason } from "../../../../../types/serializers";

const closedMessage = (reason?: ChatClosedReason) => {
  switch (reason) {
    case ChatClosedReason.Inactive:
      return t("main:kandoorGPT.chat.closedConversation.inactive");
    case ChatClosedReason.NotAccepted:
      return t("main:kandoorGPT.chat.closedConversation.notAccepted");
    default:
      return t("main:kandoorGPT.chat.closedConversation.default");
  }
};
const ClosedMessage = ({
  closedReason,
  isParticipant,
  reopen,
  setReopen,
}: {
  closedReason?: ChatClosedReason;
  isParticipant?: boolean;
  reopen: boolean;
  setReopen: (reopen: boolean) => void;
}) => {
  const [currentUser] = useCurrentUser();
  const allowReopen = isParticipant || currentUser?.attributes.admin;

  return (
    <>
      <Flex alignItems="center" bg="background.system" borderRadius="lg" p={4}>
        <Icon as={FiAlertCircle} boxSize="20px" color="blackish" mr={2} />
        <Text color="blackish" fontSize="lg">
          {closedMessage(closedReason)}
        </Text>
      </Flex>
      {!reopen && allowReopen && (
        <Box display="flex" justifyContent="center">
          <Button onClick={() => setReopen(true)}>
            {t("main:kandoorGPT.chat.reopen")}
          </Button>
        </Box>
      )}
    </>
  );
};

export default ClosedMessage;

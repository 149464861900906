import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import AsyncResource from "../../../shared/components/async/AsyncResource";
import AsyncResources from "../../../shared/components/async/AsyncResources";
import MainFooterItem from "../../../shared/components/main/MainFooterItem";
import { EmptyResource, ResourceType } from "../../../types/serializers";
import BlogPreview from "../blog/BlogPreview";

export interface PillarPageBlockBlogsProps {
  blogs?: EmptyResource<ResourceType.EmbeddedBlog>[];
}

const PillarPageBlockBlogs = ({ blogs }: PillarPageBlockBlogsProps) => {
  if (!blogs || blogs.length === 0) {
    return null;
  }

  return (
    <MainFooterItem>
      <Flex overflowX="auto">
        <AsyncResources resources={blogs}>
          {(embeddedBlog) => (
            <AsyncResource resource={embeddedBlog.relationships.blog.data}>
              {(blog) => (
                <Box
                  backgroundPosition="center center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                  borderRadius="10px"
                  flexShrink="0"
                  marginLeft="10px"
                  maxWidth={{ base: "15em", xl: "18em" }}
                  width="80%"
                >
                  <BlogPreview blog={blog} />
                </Box>
              )}
            </AsyncResource>
          )}
        </AsyncResources>
      </Flex>
    </MainFooterItem>
  );
};

export default PillarPageBlockBlogs;

import { Box, HStack, Text, chakra } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import AsyncResource from "../../../shared/components/async/AsyncResource";
import {
  ChatClosedReason,
  ChatConversationResource,
  ChatConversationStatus,
} from "../../../types/serializers";
import useChatParticipation from "../Chat/hooks/useChatParticipation";
import userName from "../Chat/lib/userName";

import { ChatViewerCardProps } from "./ChatViewerCard";

const ChatStatus = ({
  chatConversation,
}: {
  chatConversation: ChatConversationResource;
}) => {
  if (chatConversation.attributes.status === ChatConversationStatus.Closed) {
    if (
      chatConversation.attributes.closedReason === ChatClosedReason.Cancelled
    ) {
      return <Box>{t("main:chat.status.cancelled")}</Box>;
    }

    return <Box>{t("main:chat.status.closed")}</Box>;
  }

  if (
    !chatConversation.attributes.waitingForGuide ||
    chatConversation.attributes.status === ChatConversationStatus.HelpedQuery
  ) {
    return (
      <Box alignItems="center" display="flex">
        <Box
          backgroundColor="status.neutral"
          borderRadius="full"
          height="1em"
          marginRight="0.4em"
          width="1em"
        />
        {t("main:chat.status.waitingForUser")}
      </Box>
    );
  }

  return (
    <Box alignItems="center" display="flex">
      <Box
        backgroundColor="status.warning"
        borderRadius="full"
        height="1em"
        marginRight="0.4em"
        width="1em"
      />
      {t("main:chat.status.waitingForGuide")}
    </Box>
  );
};

const ChatViewerVolunteerCard = ({ chatConversation }: ChatViewerCardProps) => {
  const { attributes, relationships } = chatConversation;
  const volunteer = relationships.volunteer;
  const participation = useChatParticipation(
    chatConversation,
    volunteer.data?.id
  );

  return (
    <>
      <HStack justifyContent="space-between" width="100%">
        <ChatStatus chatConversation={chatConversation} />
        <AsyncResource resource={participation}>
          {(chatParticipation) => (
            <Box
              alignItems="center"
              background="gray.lighter"
              borderBottom="1px solid"
              borderBottomColor="background.stroke"
              borderBottomLeftRadius="0.375em"
              borderBottomRightRadius="0"
              borderLeft="1px solid"
              borderLeftColor="background.stroke"
              borderTopLeftRadius="0"
              borderTopRightRadius="0.375em"
              color="blackish"
              display="inline-flex"
              flexShrink={0}
              fontSize="sm"
              fontWeight="400"
              marginRight="-16px"
              marginTop="-16px"
              padding="8px"
            >
              {userName(chatParticipation)}
              <chakra.img
                alt={chatParticipation.attributes.firstname}
                borderRadius="full"
                flexShrink={0}
                height="32px"
                marginLeft="0.5em"
                src={chatParticipation?.attributes.avatarUrl}
                width="32px"
              />
            </Box>
          )}
        </AsyncResource>
      </HStack>
      <Text fontStyle="italic" marginRight="16px" marginTop="8px" noOfLines={3}>
        {attributes.initialMessage}
      </Text>
    </>
  );
};

export default ChatViewerVolunteerCard;

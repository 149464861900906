import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";
import { FiChevronRight } from "react-icons/fi";

import { UserResource } from "../../../types/serializers";
import { useVerifyPassword } from "../../lib/auth";

import RequestPasswordReset from "./RequestPasswordReset";

const EnterPasswordForm = ({
  email,
  onBack,
  onSuccess,
}: {
  email: string;
  onBack: () => void;
  onSuccess: (user?: UserResource, location?: string | null) => void;
}) => {
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);
  const [handleSubmit, loading, error] = useVerifyPassword(
    email,
    password,
    remember,
    onSuccess
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormControl>
        <FormLabel fontWeight="bold">{t("main:signIn.email.label")}</FormLabel>
        <Input
          readOnly
          background="#f3f3f3"
          color="grey"
          type="email"
          value={email}
        />
      </FormControl>
      <Link
        as="button"
        marginBottom="2em"
        marginTop="0.5em"
        type="button"
        onClick={onBack}
      >
        {t("main:signIn.password.back")}
      </Link>
      <FormControl>
        <FormLabel fontWeight="bold">
          {t("main:signIn.password.label")}
        </FormLabel>
        <Input
          required
          placeholder={t("main:signIn.password.placeholder")!}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormControl>
      <FormControl marginY="1em">
        <Checkbox
          isChecked={remember}
          onChange={(e) => setRemember(e.target.checked)}
        >
          {t("main:signIn.password.remember")}
        </Checkbox>
      </FormControl>
      <Text color="status.error">{error}</Text>
      <Button
        float="right"
        isDisabled={loading}
        marginTop="1em"
        rightIcon={<FiChevronRight />}
        type="submit"
      >
        {t("main:signIn.password.button")}
      </Button>
      <Box>
        <RequestPasswordReset email={email} />
      </Box>
    </form>
  );
};

export default EnterPasswordForm;

import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { ModalRenderSharedProps } from "../../../../shared/components/modal/ModalContextProvider";
import useResourceRoute from "../../../../shared/components/store/hooks/useResourceRoute";
import useCurrentUser from "../../../../shared/hooks/useCurrentUser";
import useFetchCallback from "../../../../shared/hooks/useFetchCallback";
import useRefreshResource from "../../../../shared/hooks/useRefreshResource";
import { CURRENT_USER_ID } from "../../../../shared/lib/ids";
import { ResourceType } from "../../../../types/serializers";

export interface FeedbackConfirmationModalProps extends ModalRenderSharedProps {
  chatConversationId: string;
}

export const DeleteAccountConfirmationModal = ({
  handleClose,
}: ModalRenderSharedProps) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [currentUser] = useCurrentUser();
  const path = useResourceRoute({
    id: currentUser?.attributes.id,
    type: ResourceType.User,
  })!;
  const [callback, pending] = useFetchCallback(path, "DELETE");
  const refreshResource = useRefreshResource();

  const handleSuccess = () => {
    toast({
      description: t("main:account.delete_account_success"),
      status: "success",
    });
    handleClose();
    refreshResource({ id: CURRENT_USER_ID, type: ResourceType.User });
    navigate("/");
  };

  const handleSubmit = () => {
    callback({}, handleSuccess);
  };

  return (
    <ModalContent>
      <ModalHeader>{t("main:account.delete_account_header")}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>{t("main:account.confirm_delete_account")}</ModalBody>
      <ModalFooter>
        <Button mr={3} variant="ghost" onClick={handleClose}>
          {t("main:account.cancel")}
        </Button>
        <Button
          _hover={{ backgroundColor: "#964D4D" }}
          backgroundColor="status.error"
          color="blackish"
          isLoading={pending}
          leftIcon={<FiTrash2 />}
          onClick={handleSubmit}
        >
          {t("main:account.delete_account")}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC, PropsWithChildren } from "react";

import Collection from "../../../shared/components/collection";
import CollectionItems from "../../../shared/components/collection/CollectionItems";
import InlineInput from "../../../shared/components/inputs/InlineInput";
import { InputTypes } from "../../../shared/components/inputs/types";
import useCurrentUser from "../../../shared/hooks/useCurrentUser";
import useUpdateCallback from "../../../shared/hooks/useUpdateCallback";
import { getCollectionId } from "../../../shared/lib/ids";
import { ResourceType, UserResource } from "../../../types/serializers";

const ToggleBox: FC<
  PropsWithChildren<{
    loading?: boolean;
    onClick: () => void;
    selected?: boolean;
  }>
> = ({ children, loading, onClick, selected }) => (
  <Box
    aria-pressed={selected}
    as="button"
    background={selected ? "background.accordion" : "white"}
    borderRadius="0.5em"
    boxShadow="0px 4px 4px 0px #00000040"
    cursor={loading ? "wait" : undefined}
    display="inline-block"
    margin="0.3em"
    padding="0.3em 0.4em 0.2em"
    onClick={onClick}
  >
    {children}
  </Box>
);

const AvailabilityToggle = ({
  currentUser,
  day,
}: {
  currentUser: UserResource;
  day: string;
}) => {
  const [callback, loading] = useUpdateCallback(currentUser);
  const selected = currentUser.attributes.availabilities?.[day];
  const handleClick = () => {
    callback({
      availabilities: {
        ...currentUser.attributes.availabilities,
        [day]: !selected,
      },
    });
  };

  return (
    <ToggleBox
      key={day}
      loading={loading}
      selected={selected}
      onClick={handleClick}
    >
      {day.charAt(0).toUpperCase()}
      {day.charAt(1)}
    </ToggleBox>
  );
};

const ExpertiseDomainToggle = ({
  currentUser,
  name,
}: {
  currentUser: UserResource;
  name: string;
}) => {
  const [callback, loading] = useUpdateCallback(currentUser);
  const selected = currentUser.attributes.expertiseDomainNames?.includes(name);
  const handleClick = () => {
    const newValue = currentUser.attributes.expertiseDomainNames?.slice() ?? [];
    if (selected) {
      newValue.splice(newValue.indexOf(name), 1);
    } else {
      newValue.push(name);
    }
    callback({
      expertiseDomainNames: newValue,
    });
  };

  return (
    <ToggleBox loading={loading} selected={selected} onClick={handleClick}>
      {name}
    </ToggleBox>
  );
};

const VolunteerProfileTab = () => {
  const [currentUser] = useCurrentUser();
  const expertiseDomainCollection = getCollectionId(
    ResourceType.ExpertiseDomain
  );

  if (!currentUser) {
    return null;
  }

  return (
    <Box>
      <Heading as="h2" variant="lg">
        {t("main:account.volunteer.profile.header")}
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} gap="3em">
        <Box>
          <Heading as="h3" variant="sm">
            {t("main:account.volunteer.profile.data.header")}
          </Heading>
          <Box
            background="background.footer"
            borderRadius="0.5em"
            marginTop="1em"
            padding="1em"
          >
            <InlineInput
              attribute="avatarUrl"
              label={t("main:account.volunteer.profile.data.avatar")}
              resource={currentUser}
              type={InputTypes.File}
            />
            <InlineInput
              attribute="firstname"
              label={t("main:account.volunteer.profile.data.firstname")}
              resource={currentUser}
            />
            <InlineInput
              attribute="lastname"
              label={t("main:account.volunteer.profile.data.lastname")}
              resource={currentUser}
            />
            <InlineInput
              attribute="email"
              label={t("main:account.volunteer.profile.data.email")}
              resource={currentUser}
            />
            <InlineInput
              attribute="birthdate"
              label={t("main:account.volunteer.profile.data.birthdate")}
              resource={currentUser}
              type={InputTypes.Date}
            />
            <InlineInput
              attribute="emailNotifications"
              label={t(
                "main:account.volunteer.profile.data.emailNotifications.helper"
              )}
              resource={currentUser}
              type={InputTypes.Checkbox}
            />
          </Box>
        </Box>
        <Box>
          <Heading as="h3" variant="sm">
            {t("main:account.volunteer.profile.availability.header")}
          </Heading>
          <Box
            background="background.footer"
            borderRadius="0.5em"
            marginBottom="3em"
            marginTop="1em"
            padding="1em"
          >
            <FormControl>
              <FormLabel>
                {t("main:account.volunteer.profile.data.availableDays")}
              </FormLabel>
              <Box marginBottom="1em">
                {Object.keys(currentUser.attributes.availabilities ?? {}).map(
                  (day) => (
                    <AvailabilityToggle
                      currentUser={currentUser}
                      day={day}
                      key={day}
                    />
                  )
                )}
              </Box>
              <InlineInput
                attribute="notAvailableUntilOn"
                label={t(
                  "main:account.volunteer.profile.data.notAvailableUntilOn"
                )}
                resource={currentUser}
                type={InputTypes.Date}
              />
            </FormControl>
          </Box>
          <Heading as="h3" variant="sm">
            {t("main:account.volunteer.profile.expertiseDomains.header")}
          </Heading>
          <Box
            background="background.footer"
            borderRadius="0.5em"
            marginTop="1em"
            padding="1em"
          >
            <Collection id={expertiseDomainCollection}>
              <CollectionItems<ResourceType.ExpertiseDomain>>
                {(item) => (
                  <ExpertiseDomainToggle
                    currentUser={currentUser}
                    name={item.attributes.name}
                  />
                )}
              </CollectionItems>
            </Collection>
          </Box>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default VolunteerProfileTab;

import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiChevronRight, FiMessageCircle } from "react-icons/fi";
import { Link } from "react-router-dom";

import { trackEvent } from "../../../shared/lib/analytics";

interface GptButtonProps extends ButtonProps {
  children?: string | null;
  showRightIcon?: boolean;
  size?: string;
  variant?: string;
}

const GptButton = ({
  children,
  size = "lg",
  variant,
  showRightIcon = false,
}: GptButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      as={Link}
      leftIcon={<FiMessageCircle />}
      rightIcon={showRightIcon ? <FiChevronRight /> : undefined}
      size={size}
      to="/gpt"
      variant={variant}
      onClick={() => trackEvent("click_ai_button")}
    >
      {children ?? t("main:askQuestion")}
    </Button>
  );
};

export default GptButton;

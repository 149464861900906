import { Subscription } from "@rails/actioncable";
import { useEffect, useMemo, useState } from "react";

import useCurrentUser from "../../../../shared/hooks/useCurrentUser";
import useRefreshResource from "../../../../shared/hooks/useRefreshResource";
import useStore from "../../../../shared/hooks/useStore";
import useSubscription, {
  ClientActionType,
  OnConnect,
  OnDisconnect,
  OnReceive,
  ServerActionType,
} from "../../../../shared/hooks/useSubscription";
import { GUEST_USER_ID } from "../../../../shared/lib/ids";
import {
  ChatConversationResource,
  EmptyResource,
  ResourceType,
} from "../../../../types/serializers";

import useIsTyping from "./useIsTyping";

const includesAllMessages = (
  newMessages: EmptyResource[],
  oldMessages: EmptyResource[]
) => {
  const newIds = newMessages.map(({ id }) => id);
  const oldIds = oldMessages.map(({ id }) => id);

  return oldIds.findIndex((value) => !newIds.includes(value)) === -1;
};

const useChatSubscription = (
  chatConversation: ChatConversationResource
): {
  connected: boolean;
  handleIsTyping: (typing: boolean) => void;
  isTyping: boolean;
  messages: EmptyResource<ResourceType.ChatMessage>[];
  subscription: Subscription | null;
} => {
  const store = useStore();
  const refreshResource = useRefreshResource();
  const [currentUser] = useCurrentUser();
  const [connected, setConnected] = useState(false);
  const [typingUserIds, setTypingUserIds] = useState(
    chatConversation.attributes.typingUserIds
  );
  const [messages, setMessages] = useState<
    EmptyResource<ResourceType.ChatMessage>[]
  >([]);
  const [storedConversationId, setStoredConversationId] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    setTypingUserIds(chatConversation.attributes.typingUserIds);
  }, [chatConversation.attributes.typingUserIds]);

  const onReceive: OnReceive = (body) => {
    switch (body.type) {
      case ClientActionType.IsTyping:
        setTypingUserIds(body.data);
        break;
      case ClientActionType.AppendChatMessage:
        store.processData({ data: body.data });
        setMessages((prev) => {
          const index = prev.findIndex(
            (message) => message.id === body.data.id
          );
          if (index === -1) {
            return [...prev, { id: body.data.id, type: body.data.type }];
          }

          return prev;
        });
        break;
      case ClientActionType.SyncResource:
        store.processData({ data: body.data });
        break;
    }
  };

  const onConnect: OnConnect = () => {
    setConnected(true);
    refreshResource(chatConversation);
  };

  const onDisconnect: OnDisconnect = () => {
    setConnected(false);
  };

  const channel = useMemo(
    () => ({ channel: "ChatConversationChannel", id: chatConversation.id }),
    [chatConversation.id]
  );

  const { subscription } = useSubscription(channel, {
    onConnect,
    onDisconnect,
    onReceive,
  });

  const [userIsTyping, handleIsTyping] = useIsTyping();

  useEffect(() => {
    subscription?.perform(ServerActionType.IsTyping, {
      is_typing: userIsTyping,
    });
  }, [userIsTyping]);

  const isTyping =
    typingUserIds?.findIndex(
      (userId) => userId !== (currentUser?.attributes.id ?? GUEST_USER_ID)
    ) != -1;

  useEffect(() => {
    if (chatConversation.relationships.chatMessages.data) {
      if (
        chatConversation.id !== storedConversationId ||
        includesAllMessages(
          chatConversation.relationships.chatMessages.data,
          messages
        )
      ) {
        setMessages(chatConversation.relationships.chatMessages.data);
        setStoredConversationId(chatConversation.id);
      }
      subscription?.perform(ServerActionType.ReadMessages);
    }
  }, [chatConversation.relationships.chatMessages.data]);

  return {
    connected,
    handleIsTyping,
    isTyping,
    messages,
    subscription,
  };
};

export default useChatSubscription;

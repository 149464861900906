import {
  Accordion,
  Box,
  FormControl,
  FormLabel,
  SimpleGrid,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Collection, { FilterType } from "../../../shared/components/collection";
import CollectionFilters from "../../../shared/components/collection/CollectionFilters";
import CollectionSearch from "../../../shared/components/collection/CollectionSearch";
import CollectionSorts from "../../../shared/components/collection/CollectionSorts";
import CollectionTable from "../../../shared/components/collection/CollectionTable";
import { Loader } from "../../../shared/components/loader";
import { Column } from "../../../shared/components/table";
import useCollection from "../../../shared/hooks/useCollection";
import useResource from "../../../shared/hooks/useResource";
import useResources from "../../../shared/hooks/useResources";
import useStore from "../../../shared/hooks/useStore";
import { formatDateTime } from "../../../shared/lib/date";
import {
  NULL_FILTER,
  collectionOptsFromId,
  collectionOptsFromQuery,
  getCollectionId,
} from "../../../shared/lib/ids";
import { userFullName } from "../../../shared/lib/users";
import {
  ChatConversationResource,
  ChatType,
  CollectionOrderDirection,
  CollectionResource,
  ResourceType,
} from "../../../types/serializers";
import userName from "../Chat/lib/userName";

import Feedback from "./cells/Feedback";
import HelpedQuery from "./cells/helpedQuery";
import ChatArchiveUserFilter from "./ChatArchiveUserFilter";

const COLUMNS: Column<ResourceType.ChatConversation>[] = [
  {
    attribute: "initialMessage",
    label: t("main:chatArchive.columns.initialMessage"),
    render: (item) => (
      <Box minHeight="3.7em" minWidth="min(calc(80vw - 2em), 20em)">
        <Box
          bottom="1em"
          left="0em"
          overflow="hidden"
          position="absolute"
          right="1em"
          top="1em"
          whiteSpace="normal"
        >
          {item.attributes.initialMessage}
        </Box>
      </Box>
    ),
    width: "30em",
    wrap: true,
  },
  {
    attribute: "volunteerId",
    label: t("main:chatArchive.columns.volunteer"),
    render: (item) => userName(useResource(item.relationships.volunteer.data)),
    sortable: true,
  },
  {
    attribute: "lastMessageAt",
    label: t("main:chatArchive.columns.lastMessageAt"),
    render: (item) => (
      <time dateTime={item.attributes.lastMessageAt}>
        {item.attributes.lastMessageAt &&
          formatDateTime(item.attributes.lastMessageAt)}
      </time>
    ),
    sortable: true,
  },
  {
    attribute: "helped_query",
    label: t("main:chatArchive.columns.helpedQuery"),
    render: HelpedQuery,
  },
  {
    attribute: "feedback",
    label: t("main:chatArchive.columns.feedback"),
    render: Feedback,
    wrap: true,
  },
];

const AVAILABLE_FILTERS = [
  {
    attribute: "expertiseDomainId",
    label: t("main:chatArchive.filters.expertise_domain_id.label"),
    type: FilterType.Select,
    useOptions: () => {
      const collection = useCollection(ResourceType.ExpertiseDomain);
      const domains = useResources(collection?.relationships.items.data).map(
        (domain) => ({
          label: domain?.attributes.name ?? "loading",
          value: domain?.id ?? "loading",
        })
      );

      return [
        {
          label: t("main:chatArchive.filters.expertise_domain_id.all"),
          value: "",
        },
        ...domains,
      ];
    },
  },
  {
    attribute: "volunteerId",
    label: t("main:chatArchive.filters.volunteer_id.label"),
    type: FilterType.Select,
    useOptions: () => {
      const collection = useCollection(ResourceType.User, {
        filter: { isGuide: ["true"], stoppedAsGuideOn: [NULL_FILTER] },
        order: { firstname: CollectionOrderDirection.Asc },
        pageSize: 999,
      });
      const volunteers = useResources(collection?.relationships.items.data).map(
        (volunteer) => ({
          label: volunteer ? userFullName(volunteer) : "loading",
          value: volunteer?.id ?? "loading",
        })
      );

      if (!collection) {
        return [];
      }

      return [
        {
          label: t("main:chatArchive.filters.volunteer_id.all"),
          value: "",
        },
        ...volunteers,
      ];
    },
  },
  {
    attribute: "createdAt",
    label: t("main:chatArchive.filters.created_at.label"),
    options: [
      {
        label: t("main:chatArchive.filters.created_at.all"),
        value: "",
      },
      {
        label: t("main:chatArchive.filters.created_at.day"),
        value: "day",
      },
      {
        label: t("main:chatArchive.filters.created_at.week"),
        value: "week",
      },
      {
        label: t("main:chatArchive.filters.created_at.month"),
        value: "month",
      },
      {
        label: t("main:chatArchive.filters.created_at.year"),
        value: "year",
      },
    ],
    type: FilterType.Select,
  },
  {
    attribute: "helpedQuery",
    label: t("main:chatArchive.filters.helped_query.label"),
    options: [
      {
        label: t("main:chatArchive.filters.helped_query.all"),
        value: "",
      },
      {
        label: t("main:chatArchive.filters.helped_query.yes"),
        value: "true",
      },
      {
        label: t("main:chatArchive.filters.helped_query.no"),
        value: "false",
      },
    ],
    type: FilterType.Select,
  },
  {
    attribute: "status",
    label: t("main:chatArchive.filters.status.label"),
    options: [
      {
        label: t("main:chatArchive.filters.status.all"),
        value: "",
      },
      {
        label: t("main:chatArchive.filters.status.active"),
        value: "active_conversation",
      },
      {
        label: t("main:chatArchive.filters.status.helped_query"),
        value: "helped_query",
      },
      {
        label: t("main:chatArchive.filters.status.closed"),
        value: "closed",
      },
    ],
    type: FilterType.Select,
  },
];

const initialCollectionId = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const opts = collectionOptsFromQuery(searchParams);
  const emptyOptions =
    Object.values(opts).findIndex((v) => Object.keys(v ?? "").length > 0) ===
    -1;

  if (emptyOptions) {
    return getCollectionId(ResourceType.ChatConversation, {
      filter: {
        chatType: [ChatType.VolunteerChat],
      },
      order: { lastMessageAt: CollectionOrderDirection.Desc },
    });
  }

  return getCollectionId(ResourceType.ChatConversation, opts);
};

const collectionSearch = (collection: CollectionResource) => {
  const { pageSize, ...otherAttrs } = collectionOptsFromId(collection.id);

  const search = getCollectionId(
    ResourceType.ChatConversation,
    otherAttrs
  ).split("?")[1];

  return `?${search}`;
};

const ChatArchive = () => {
  const [collectionId, setCollectionId] = useState(initialCollectionId());
  const collection = useResource({
    id: collectionId,
    type: ResourceType.Collection,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const store = useStore();

  useEffect(() => {
    if (collection) {
      const newURL =
        location.pathname + collectionSearch(collection) + location.hash;

      navigate(newURL.toString(), { replace: true });
    }
  }, [collection]);

  if (!collection) {
    return <Loader />;
  }

  const handleRowClick = (item: ChatConversationResource) => {
    navigate(store.router.resourceRoute(item)!);
  };
  const handleCollectionChange = (newId: string) => {
    setCollectionId(newId);
  };

  return (
    <Collection
      availableFilters={AVAILABLE_FILTERS}
      id={collection.id}
      onChange={handleCollectionChange}
    >
      <SimpleGrid
        columns={{ base: 2, md: 3 }}
        gap="1em"
        marginBottom="2em"
        marginTop="1em"
      >
        <FormControl flexGrow={1}>
          <FormLabel>{t("shared:search.label")}</FormLabel>
          <CollectionSearch />
        </FormControl>
        <CollectionFilters />
        <ChatArchiveUserFilter />
      </SimpleGrid>
      <Accordion>
        <CollectionSorts />
      </Accordion>
      <CollectionTable
        columns={COLUMNS}
        variant="archive"
        onRowClick={handleRowClick}
      />
    </Collection>
  );
};

export default ChatArchive;

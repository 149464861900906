import { Button } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiMessageCircle } from "react-icons/fi";
import { Link } from "react-router-dom";

import { trackEvent } from "../../../../../shared/lib/analytics";

const NewAIConversationButton = () => (
  <Button
    as={Link}
    leftIcon={<FiMessageCircle />}
    to="/gpt"
    variant="nude"
    onClick={() => trackEvent("click_restart_chat_button")}
  >
    {t("main:kandoorGPT.chat.restart")}
  </Button>
);

export default NewAIConversationButton;

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";
import { FiChevronRight } from "react-icons/fi";

import { UserResource } from "../../../types/serializers";
import { useSetName } from "../../lib/auth";

const EnterNameForm = ({
  onSuccess,
}: {
  onSuccess: (user: UserResource | undefined) => void;
}) => {
  const [firstName, setFirstName] = useState("");
  const [handleSubmit, loading, error] = useSetName(firstName, onSuccess);

  return (
    <form onSubmit={handleSubmit}>
      <FormControl>
        <FormLabel fontWeight="bold">{t("main:signIn.name.label")}</FormLabel>
        <Input
          required
          placeholder={t("main:signIn.name.placeholder")!}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </FormControl>
      <Text color="status.error">{error}</Text>
      <Box float="right">
        <Button
          isDisabled={loading}
          marginTop="1em"
          rightIcon={<FiChevronRight />}
          type="submit"
        >
          {t("main:signIn.name.button")}
        </Button>
      </Box>
    </form>
  );
};

export default EnterNameForm;

import { Text } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";

import useCurrentUser from "../../../shared/hooks/useCurrentUser";
import { UserResource } from "../../../types/serializers";

import EnterEmailForm from "./EnterEmailForm";
import EnterNameForm from "./EnterNameForm";
import EnterOTPForm from "./EnterOTPForm";
import EnterPasswordForm from "./EnterPasswordForm";

export enum FormStep {
  Email,
  OTP,
  Password,
  Name,
}

const SignInForm = ({
  initialStep,
  onSuccess,
  requireName,
}: {
  initialStep?: FormStep;
  onSuccess: (user?: UserResource, location?: string | null) => void;
  requireName?: boolean;
}) => {
  const [currentUser] = useCurrentUser();
  const [step, setStep] = useState<FormStep>(initialStep ?? FormStep.Email);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (currentUser && !currentUser.attributes.firstname) {
      setStep(FormStep.Name);
    }
  }, [currentUser]);

  if (currentUser && step !== FormStep.Name) {
    return <Text>{t("main:signIn.alreadySignedIn")}</Text>;
  }

  switch (step) {
    case FormStep.Email:
      return (
        <EnterEmailForm
          onRequirePassword={(newEmail) => {
            setEmail(newEmail);
            setStep(FormStep.Password);
          }}
          onSuccess={(newEmail) => {
            setEmail(newEmail);
            setStep(FormStep.OTP);
          }}
        />
      );
    case FormStep.OTP:
      return (
        <EnterOTPForm
          email={email}
          onBack={() => setStep(FormStep.Email)}
          onSuccess={(user, location) => {
            if (requireName && !user?.attributes.firstname) {
              setStep(FormStep.Name);
            } else {
              onSuccess(user, location);
            }
          }}
        />
      );
    case FormStep.Password:
      return (
        <EnterPasswordForm
          email={email}
          onBack={() => setStep(FormStep.Email)}
          onSuccess={(user, location) => {
            if (requireName && !user?.attributes.firstname) {
              setStep(FormStep.Name);
            } else {
              onSuccess(user, location);
            }
          }}
        />
      );
    case FormStep.Name:
      return <EnterNameForm onSuccess={onSuccess} />;
  }
};

export default SignInForm;

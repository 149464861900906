import { IconButton, VStack, chakra } from "@chakra-ui/react";
import { t } from "i18next";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FiX } from "react-icons/fi";

import useCurrentUser from "../../../../shared/hooks/useCurrentUser";
import useUpdateCallback from "../../../../shared/hooks/useUpdateCallback";
import { ResourceType } from "../../../../types/serializers";

const DEBOUNCE = 1_000;

const ChatNotepad = ({ closeNotepad }: { closeNotepad?: () => void }) => {
  const [currentUser] = useCurrentUser();
  const [value, setValue] = useState(currentUser?.attributes.notepad ?? "");
  const [updateUser] = useUpdateCallback(
    currentUser
      ? {
          id: currentUser.id,
          type: ResourceType.User,
        }
      : undefined
  );

  useEffect(() => {
    if (currentUser) {
      setValue(currentUser.attributes.notepad ?? "");
    }
  }, [!!currentUser]);

  const handleUpdate = useCallback((notepad: string) => {
    if (updateUser) {
      updateUser({ notepad });
    }
  }, []);
  const syncChange = useMemo(
    () => debounce(handleUpdate, DEBOUNCE),
    [handleUpdate]
  );

  useEffect(() => {
    syncChange(value);
  }, [value]);

  return (
    <VStack
      background="background.avatar"
      border="1px solid"
      borderColor="tertiary"
      borderRadius="0.5em"
      gap={0}
      height="100%"
      width="100%"
    >
      {closeNotepad && (
        <IconButton
          alignSelf="flex-end"
          aria-label="Sluiten"
          icon={<FiX />}
          variant="ghost"
          onClick={closeNotepad}
        />
      )}
      <chakra.textarea
        autoFocus
        background="none"
        border="none"
        fontSize="16px"
        height="100%"
        lineHeight="22px"
        outline="none"
        padding="1em"
        placeholder={t("main:chat.notepad.placeholder")!}
        resize="none"
        value={value}
        width="100%"
        onChange={(e) => setValue(e.target.value)}
      />
    </VStack>
  );
};

export default ChatNotepad;

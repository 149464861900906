import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ScrollLink } from "react-scroll";
import type { Descendant } from "slate";

import Link from "../../../shared/components/link";
import {
  CustomText,
  ElementType,
  HeadingNode,
  isElement,
} from "../../../shared/components/richText/lib/types";
import { trackEvent } from "../../../shared/lib/analytics";

interface Heading {
  id: string;
  value: string;
}

const ScrollableLink = ScrollLink(Link);

const isHeadingOne = (element: Descendant): element is HeadingNode =>
  isElement(element) && element.type === ElementType.headingOne;

const isHeadingTwo = (element: Descendant): element is HeadingNode =>
  isElement(element) && element.type === ElementType.headingTwo;

export const headingToString = (element: HeadingNode) =>
  (element.children as CustomText[]).map((child) => child?.text).join("");

const useParsedHeaders = (slateValue: Descendant[]) =>
  useMemo(() => {
    const headings: { headingOne: Heading; headingTwos: Heading[] }[] = [];

    slateValue.forEach((element) => {
      if (isHeadingOne(element)) {
        const headingOne = {
          id: element.id ?? "",
          value: headingToString(element),
        };
        headings.push({ headingOne, headingTwos: [] });
      } else if (isHeadingTwo(element) && headings.length > 0) {
        const headingTwo = {
          id: element.id ?? "",
          value: headingToString(element),
        };
        headings[headings.length - 1].headingTwos.push(headingTwo);
      }
    });

    return headings;
  }, [slateValue]);

const TableOfContents = ({ slateValue }: { slateValue: Descendant[] }) => {
  const headingsParsed = useParsedHeaders(slateValue);
  const { t } = useTranslation();
  const renderTOC = headingsParsed.length > 0;
  const handleClick = (heading: string) => () => {
    trackEvent("inhoudsopgave_click", { heading });
  };

  if (!renderTOC) {
    return null;
  }

  return (
    <Box
      marginBottom={{ base: "2.5em", md: "3em" }}
      maxHeight={{ base: "initial", lg: "100vh" }}
      overflowY={{ base: "visible", lg: "auto" }}
      position={{ base: "static", lg: "sticky" }}
      top={{ base: "initial", lg: "60px" }}
    >
      <VStack
        align="start"
        border={{ base: "1px dashed", lg: "none" }}
        borderColor="gray.main"
        lineHeight={2}
        px={{ base: 4, lg: 0 }}
        py={{ base: 4, lg: 0 }}
      >
        <Heading lineHeight={10} variant="xs">
          {t("main:toc.header")}
        </Heading>
        <VStack align="start" color="primary.darkActive">
          {headingsParsed.map(({ headingOne, headingTwos }) => (
            <Box key={headingOne.id}>
              <ScrollableLink
                duration={500}
                href={`#${headingOne.id}`}
                offset={-100}
                smooth={true}
                to={headingOne.id}
                onClick={handleClick(headingOne.id)}
              >
                <Text
                  _hover={{ color: "primary.dark" }}
                  as="h3"
                  fontSize="md"
                  fontWeight={600}
                  wordBreak="break-word"
                >
                  {headingOne.value}
                </Text>
              </ScrollableLink>
              <VStack align="start" pl={3} spacing={2}>
                {headingTwos.map((headingTwo) => (
                  <ScrollableLink
                    duration={500}
                    href={`#${headingTwo.id}`}
                    key={headingTwo.id}
                    offset={-100}
                    smooth={true}
                    to={headingTwo.id}
                    onClick={handleClick(headingTwo.id)}
                  >
                    <Text
                      _hover={{ color: "primary.dark" }}
                      as="h4"
                      fontSize="sm"
                      marginY="0.2em"
                    >
                      {headingTwo.value}
                    </Text>
                  </ScrollableLink>
                ))}
              </VStack>
            </Box>
          ))}
        </VStack>
      </VStack>
    </Box>
  );
};
export default TableOfContents;

import { Alert, Box, Heading, SimpleGrid } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import Container from "../../../shared/components/layout/Container";
import Main from "../../../shared/components/main/Main";
import { ChatType } from "../../../types/serializers";
import { ChatWrapper } from "../../components/Chat/components/ChatWrapper";
import InformationCard from "../../components/Chat/components/InformationCard";
import useCreateConversationHook from "../chatConversations/hooks/CreateConversationHook";

const GptNewPage = () => {
  const { handleCreate, error } = useCreateConversationHook(ChatType.AI);

  return (
    <Main>
      <Container>
        <Box>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <ChatWrapper>
              <Box mb={4} textAlign="center" w="full">
                <Heading as="h2" color="primary.dark" mb={2} size="xl">
                  {t("main:kandoorGPT.header.subtitle")}
                </Heading>
                <Heading as="h3" color="primary.grey" mb={2} size="md">
                  {t("main:kandoorGPT.header.version")}
                </Heading>
              </Box>
              <InformationCard onSubmit={handleCreate} />
              {error && <Alert status="error">{error}</Alert>}
            </ChatWrapper>
          </SimpleGrid>
        </Box>
      </Container>
    </Main>
  );
};

export default GptNewPage;

import { GridItem } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

const MainLeft = ({ children }: PropsWithChildren) => (
  <GridItem area="left" as="aside">
    {children}
  </GridItem>
);

export default MainLeft;

import { useRollbar } from "@rollbar/react";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useCreateCallback from "../../../../shared/hooks/useCreateCallback";
import {
  CallbackErrorHandler,
  CallbackSuccessHandler,
} from "../../../../shared/hooks/useResourceCallback";
import useStore from "../../../../shared/hooks/useStore";
import { ChatType, ResourceType } from "../../../../types/serializers";

const useCreateConversationHook = (chatType: ChatType) => {
  const rollbar = useRollbar();
  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined>(undefined);
  const store = useStore();

  const [createConversation] = useCreateCallback(ResourceType.ChatConversation);

  const handleError: CallbackErrorHandler = ({ response }) => {
    rollbar.error(`Wrong status: ${response.status}`);
    setError(t("shared:status.error")!);
  };
  const handleSuccess: CallbackSuccessHandler = ({ json }) => {
    const location = store.router.resourceRoute(json.data);
    if (location) {
      navigate(location);
    }
  };

  const handleCreate = (userMessage: string) => {
    createConversation(
      {
        chatType: chatType,
        initialMessage: userMessage,
      },
      handleSuccess,
      handleError
    );
  };

  return { error, handleCreate };
};

export default useCreateConversationHook;

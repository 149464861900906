import { useMemo } from "react";

import useSubscription from "../../../../shared/hooks/useSubscription";

const useAppearanceSubscription = () => {
  const channel = useMemo(() => ({ channel: "AppearanceChannel" }), []);

  useSubscription(channel);
};

export default useAppearanceSubscription;

import React from "react";

import RichText from "../../../../../shared/components/richText";
import useCurrentUser from "../../../../../shared/hooks/useCurrentUser";
import useModalDispatch from "../../../../../shared/hooks/useModalDispatch";
import useUpdateCallback from "../../../../../shared/hooks/useUpdateCallback";
import { ChatConversationResource } from "../../../../../types/serializers";
import { ComplimentsModal } from "../../modals/ComplimentsModal";
import NegativeFeedbackModal from "../../modals/NegativeFeedbackModal";
import HelpedQueryChatButton from "../buttons/HelpedQueryChatButton";
import ChatCard from "../ChatCard";
import { MessageRenderer } from "../ChatMessage";

const HelpedQuery = ({
  chatMessage,
  chatConversation,
}: MessageRenderer & {
  chatConversation: ChatConversationResource;
}) => {
  const [callback, pending] = useUpdateCallback(
    chatMessage.relationships.feedback!.data!
  );
  const dispatchComplimentModal = useModalDispatch(ComplimentsModal, false);
  const dispatchNegativeFeedbackModal = useModalDispatch(
    NegativeFeedbackModal,
    false
  );
  const [currentUser] = useCurrentUser();

  const isConversationCreator =
    currentUser?.attributes.id === chatConversation.relationships.user.data?.id;

  const handleResponse = (helpedQuery: boolean) => {
    const handleSuccess = () => {
      if (helpedQuery) {
        dispatchComplimentModal({ chatConversation, chatMessage });
      } else {
        dispatchNegativeFeedbackModal({ chatConversation, chatMessage });
      }
    };

    callback({ helpedQuery }, handleSuccess);
  };

  return (
    <ChatCard
      background="none"
      chatConversation={chatConversation}
      createdAt={chatMessage.attributes.createdAt}
      creator={chatMessage.relationships.user.data}
    >
      <RichText>{chatMessage.attributes.content}</RichText>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <HelpedQueryChatButton
          helped
          isDisabled={!isConversationCreator}
          loading={pending}
          mr={2}
          onClick={() => handleResponse(true)}
        />

        <HelpedQueryChatButton
          helped={false}
          isDisabled={!isConversationCreator}
          loading={pending}
          onClick={() => handleResponse(false)}
        />
      </div>
    </ChatCard>
  );
};

export default HelpedQuery;

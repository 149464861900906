import { t } from "i18next";
import React, { Dispatch, SetStateAction } from "react";
import { FiPenTool } from "react-icons/fi";

import Button from "../../../../../shared/components/button";
import { trackEvent } from "../../../../../shared/lib/analytics";

const NotepadButton = ({
  setShowNotepad,
  showNotepad,
}: {
  setShowNotepad: Dispatch<SetStateAction<boolean>>;
  showNotepad: boolean;
}) => {
  const handleClick = () => {
    setShowNotepad((previousValue) => {
      if (!previousValue) {
        trackEvent("click_notes");
      }

      return !previousValue;
    });
  };

  return (
    <Button
      isActive={showNotepad}
      leftIcon={<FiPenTool />}
      variant="nude"
      onClick={handleClick}
    >
      {t("main:chat.notepad.title")}
    </Button>
  );
};

export default NotepadButton;

// components/FaqAccordion.tsx
import { Accordion } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { AccordionItemProps, FaqAccordionItem } from "./faqAccordionItem";

export const useAccordionItems = (
  translationKey: string
): AccordionItemProps[] => {
  const { t } = useTranslation();

  return t(translationKey, {
    returnObjects: true,
  });
};

interface FaqAccordionProps {
  accordionItems: AccordionItemProps[];
  setDefaultIndex?: boolean;
  variant: "home" | "basic";
}

export const FaqAccordion = ({
  accordionItems,
  variant,
  setDefaultIndex = false,
}: FaqAccordionProps) => {
  return (
    <Accordion
      allowToggle
      {...(setDefaultIndex ? { defaultIndex: [0] } : {})}
      variant={variant}
    >
      {accordionItems.map(({ title, content }, index) => (
        <FaqAccordionItem content={content} key={index} title={title} />
      ))}
    </Accordion>
  );
};

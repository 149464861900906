import HttpStatus from "http-status-codes";
import { t } from "i18next";
import { FormEvent, useCallback, useState } from "react";
import { useLocation } from "react-router";

import useFetchCallback from "../../shared/hooks/useFetchCallback";
import {
  CallbackErrorHandler,
  CallbackSuccessHandler,
} from "../../shared/hooks/useResourceCallback";
import useStore from "../../shared/hooks/useStore";
import useUpdateCallback from "../../shared/hooks/useUpdateCallback";
import { setCsrfToken } from "../../shared/lib/api";
import { CURRENT_USER_ID } from "../../shared/lib/ids";
import { ResourceType, UserResource } from "../../types/serializers";

export const useRequestAuthCode = (
  email: string,
  handleSuccess: () => void,
  onRequirePassword?: () => void
): [callback: (e?: FormEvent) => void, loading: boolean, error?: string] => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [callback, loading] = useFetchCallback(GLOBALS.root + "/auth", "POST");
  const handleError: CallbackErrorHandler = ({ response }) => {
    if (response.status === HttpStatus.FORBIDDEN && onRequirePassword) {
      onRequirePassword();
    } else {
      setError(t("main:auth.error")!);
    }
  };
  const requestAuthCode = useCallback(
    (e?: FormEvent) => {
      e?.preventDefault();

      callback({ email: email }, handleSuccess, handleError);
    },
    [email, handleSuccess]
  );

  return [requestAuthCode, loading, error];
};

export const useVerifyAuthCode = (
  email: string,
  authCode: string,
  onSuccess: (user?: UserResource, location?: string | null) => void
): [callback: (e?: FormEvent) => void, loading: boolean, error?: string] => {
  const store = useStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [error, setError] = useState<string | undefined>(undefined);
  const [callback, loading] = useFetchCallback(
    GLOBALS.root +
      `/auth/verify?redirect_url=${queryParams.get("redirect_url") ?? ""}`,
    "POST"
  );

  const handleSuccess: CallbackSuccessHandler<ResourceType.User> = ({
    json,
    response,
  }) => {
    store.processData(json);
    onSuccess(json.data, response.headers.get("Location"));
  };
  const handleError: CallbackErrorHandler = ({ response }) => {
    if (response.status === HttpStatus.UNAUTHORIZED) {
      setError(t("main:auth.wrongCode")!);
    } else {
      setError(t("main:auth.error")!);
    }
  };
  const verifyAuthCode = useCallback(
    (e?: FormEvent) => {
      e?.preventDefault();

      callback(
        { auth_code: authCode, email: email },
        handleSuccess as CallbackSuccessHandler,
        handleError
      );
    },
    [authCode, email, onSuccess]
  );

  return [verifyAuthCode, loading, error];
};

export const useVerifyPassword = (
  email: string,
  password: string,
  remember: boolean,
  onSuccess: (user?: UserResource, location?: string | null) => void
): [callback: (e?: FormEvent) => void, loading: boolean, error?: string] => {
  const store = useStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [error, setError] = useState<string | undefined>(undefined);
  const [callback, loading] = useFetchCallback(
    GLOBALS.root +
      `/users/sign_in?redirect_url=${queryParams.get("redirect_url") ?? ""}`,
    "POST"
  );
  const handleSuccess: CallbackSuccessHandler<ResourceType.User> = ({
    json,
    response,
  }) => {
    setCsrfToken(response.headers.get("X-CSRF-Token"));
    store.processData(json);
    onSuccess(json.data, response.headers.get("Location"));
  };
  const handleError: CallbackErrorHandler = ({ response }) => {
    if (response.status === HttpStatus.UNAUTHORIZED) {
      setError(t("main:auth.wrongPassword")!);
    } else {
      setError(t("main:auth.error")!);
    }
  };

  const verifyAuthCode = useCallback(
    (e?: FormEvent) => {
      e?.preventDefault();

      callback(
        {
          user: { email: email, password: password, remember_me: remember },
        },
        handleSuccess as CallbackSuccessHandler,
        handleError
      );
    },
    [password, email, onSuccess, remember]
  );

  return [verifyAuthCode, loading, error];
};

export const useSetName = (
  name: string,
  onSuccess: (user: UserResource | undefined) => void
): [callback: (e?: FormEvent) => void, loading: boolean, error?: string] => {
  const [updateCallback, loading] = useUpdateCallback({
    id: CURRENT_USER_ID,
    type: ResourceType.User,
  });
  const [error, setError] = useState<string | undefined>(undefined);
  const handleError: CallbackErrorHandler = ({ json }) => {
    if (json.errors && json.errors.length > 0) {
      setError(json.errors[0].title);
    } else {
      setError(t("main:auth.error")!);
    }
  };
  const handleSuccess: CallbackSuccessHandler<ResourceType.User> = ({
    json,
  }) => {
    onSuccess(json.data);
  };

  const setName = useCallback(
    (e?: FormEvent) => {
      e?.preventDefault();

      updateCallback({ firstname: name }, handleSuccess, handleError);
    },
    [name]
  );

  return [setName, loading, error];
};

export const useRequestPasswordReset = (
  email: string,
  handleSuccess: () => void
): [callback: (e?: FormEvent) => void, loading: boolean, error?: string] => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [callback, loading] = useFetchCallback(
    GLOBALS.root + "/users/password",
    "POST"
  );
  const handleError = () => {
    setError(t("main:auth.error")!);
  };

  const requestPasswordReset = useCallback(
    (e?: FormEvent) => {
      e?.preventDefault();

      callback({ user: { email: email } }, handleSuccess, handleError);
    },
    [email, handleSuccess]
  );

  return [requestPasswordReset, loading, error];
};

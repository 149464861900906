import {
  Box,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";

import { ModalRenderSharedProps } from "../../../../shared/components/modal/ModalContextProvider";
import useModalDispatch from "../../../../shared/hooks/useModalDispatch";
import useUpdateCallback from "../../../../shared/hooks/useUpdateCallback";
import {
  ChatConversationResource,
  ChatMessageResource,
} from "../../../../types/serializers";
import useChatParticipation from "../hooks/useChatParticipation";
import userName from "../lib/userName";

import NewsletterModal from "./NewsletterModal";

export interface FeedbackModalProps extends ModalRenderSharedProps {
  chatConversation: ChatConversationResource;
  chatMessage: ChatMessageResource;
}

const FEEDBACK_OPTIONS = [
  t("main:kandoorGPT.chat.feedback_option.relief"),
  t("main:kandoorGPT.chat.feedback_option.taken_seriously"),
  t("main:kandoorGPT.chat.feedback_option.can_continue"),
];
export const PositiveFeedbackModal = ({
  chatConversation,
  chatMessage,
}: FeedbackModalProps) => {
  const dispatchModal = useModalDispatch(NewsletterModal, true);
  const [updateChatConversation, loading] = useUpdateCallback(
    chatMessage.relationships.feedback!.data!
  );
  const [customFeedback, setCustomFeedback] = useState("");

  const participation = useChatParticipation(
    chatConversation,
    chatConversation.relationships.volunteer.data!.id
  );
  const firstName = userName(participation);

  const handleSubmitFeedback = (feedback: string) => {
    const handleSuccess = () => {
      dispatchModal();
    };
    updateChatConversation(
      {
        feedback,
      },
      handleSuccess
    );
  };

  return (
    <ModalContent>
      <ModalHeader> {t("main:kandoorGPT.chat.feedback_header")}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box fontSize="lg" mb="16px">
          {t("main:kandoorGPT.chat.feedback_subheader", {
            volunteer: firstName,
          })}
        </Box>
        <Textarea
          autoFocus
          border="1px"
          fontSize="17px"
          height="150px"
          isDisabled={loading}
          placeholder={t("main:kandoorGPT.chat.feedback_textarea_placeholder")!}
          value={customFeedback}
          onChange={(e) => setCustomFeedback(e.target.value)}
        />
        <Stack spacing={4}>
          {FEEDBACK_OPTIONS.map((option, index) => (
            <Button
              border="2px"
              fontSize="17px"
              isDisabled={loading}
              key={index}
              px="16px"
              py="24px"
              variant="outline"
              onClick={() => handleSubmitFeedback(option)}
            >
              {option}
            </Button>
          ))}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          isDisabled={loading}
          onClick={() => handleSubmitFeedback(customFeedback)}
        >
          {t("main:kandoorGPT.chat.input.sendButton")}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export default PositiveFeedbackModal;

import { Flex, VStack } from "@chakra-ui/react";
import React from "react";

import Container from "../../../shared/components/layout/Container";
import Link from "../../../shared/components/link";
import Main from "../../../shared/components/main/Main";
import MainHeader from "../../../shared/components/main/MainHeader";

const UpgradeBrowserPage = () => {
  return (
    <Main>
      <Container>
        <MainHeader title="Je webbrowser is verouderd" />
        <Flex justify="center">
          <VStack align="start" maxW="800px">
            <p>
              Helaas kunnen wij geen goede werking garanderen met de versie van
              Google Chrome die je nu gebruikt. De nieuwste versie van Chrome
              kun je <Link href="https://www.google.com/chrome/">hier </Link>
              downloaden. Je kunt onze website natuurlijk ook bezoeken met een
              tablet of smartphone. Of een alternatieve browser installeren.
            </p>
          </VStack>
        </Flex>
      </Container>
    </Main>
  );
};

export default UpgradeBrowserPage;

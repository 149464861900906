import { useBreakpointValue } from "@chakra-ui/react";
import { useEffect } from "react";

import { ResourceStatus } from "../../../../shared/components/store/Store";
import useResource from "../../../../shared/hooks/useResource";
import useResources from "../../../../shared/hooks/useResources";
import useStatus from "../../../../shared/hooks/useStatus";
import {
  CollectionResource,
  EmptyResource,
  ResourceType,
} from "../../../../types/serializers";
import { ChatViewerTab } from "../ChatViewerTabs";
import { getTabCollectionId } from "../lib/tabs";

const useAutoSelect = (
  selectConversationId: (id?: string, replace?: boolean) => void,
  selectedConversationId: string | undefined,
  setSelectedTab: (tab: ChatViewerTab) => void,
  selectedTab: ChatViewerTab,
  tabs: ChatViewerTab[]
) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const selectedCollection = useResource({
    id: getTabCollectionId(selectedTab),
    type: ResourceType.Collection,
  });
  const selectedStatus = useStatus(selectedCollection);
  const collectionIdentifiers = tabs.map<
    EmptyResource<ResourceType.Collection>
  >((tab) => ({
    id: getTabCollectionId(tab),
    type: ResourceType.Collection,
  }));
  const collections = useResources<ResourceType.Collection, CollectionResource>(
    collectionIdentifiers
  );

  useEffect(() => {
    if (selectedCollection) {
      if (selectedConversationId) {
        const tabIndex = collections.findIndex(
          (collection) =>
            collection &&
            collection.relationships.items.data?.findIndex(
              (item) => item.id === selectedConversationId
            ) !== -1
        );
        if (tabIndex !== -1) {
          setSelectedTab(tabs[tabIndex]);
        }
      } else if (
        !isMobile &&
        ![ResourceStatus.MarkedForRefresh, ResourceStatus.Refreshing].includes(
          selectedStatus
        )
      ) {
        selectConversationId(
          selectedCollection.relationships.items.data?.[0]?.id,
          true
        );
      }
    }
  }, [isMobile, collections, selectedStatus]);
};

export default useAutoSelect;

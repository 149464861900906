import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

export interface AppCtx {
  previousLocation?: string;
  referer: string | null;
  setShowFooter: (showFooter: boolean) => void;
  showFooter: boolean;
}

export const defaultAppContext = {
  referer: null,
  setShowFooter: () => null,
  showFooter: true,
};

export const AppContext = React.createContext<AppCtx>(defaultAppContext);

const useReferrer = () =>
  useMemo(() => {
    const search = typeof window === "undefined" ? "" : window.location.search;
    const searchParams = new URLSearchParams(search);

    return searchParams.get("referer");
  }, []);

const AppContextProvider: FC<
  PropsWithChildren<{ overrides?: Partial<AppCtx> }>
> = ({ children, overrides }) => {
  const referer = useReferrer();
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const [_location, setLocation] = useState<undefined | string>();
  const [previousLocation, setPreviousLocation] = useState<
    undefined | string
  >();
  const location = useLocation();

  useEffect(() => {
    setLocation((prev) => {
      setPreviousLocation(prev);

      return location.pathname + location.search + location.hash;
    });
  }, [location]);

  return (
    <AppContext.Provider
      value={{
        previousLocation,
        referer,
        setShowFooter,
        showFooter,
        ...(overrides ?? {}),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;

import {
  Box,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";

import { ModalRenderSharedProps } from "../../../../shared/components/modal/ModalContextProvider";
import useCollection from "../../../../shared/hooks/useCollection";
import useResources from "../../../../shared/hooks/useResources";
import useUpdateCallback from "../../../../shared/hooks/useUpdateCallback";
import {
  ChatConversationResource,
  ResourceType,
} from "../../../../types/serializers";

export interface ExpertiseDomainProps extends ModalRenderSharedProps {
  chatConversation: ChatConversationResource;
}

export const ExpertiseDomainModal = ({
  handleClose,
  chatConversation,
}: ExpertiseDomainProps) => {
  const [selectedDomain, setSelectedDomain] = useState("");
  const toast = useToast();

  const [callback, loading] = useUpdateCallback({
    id: chatConversation.id,
    type: ResourceType.ChatConversation,
  });

  const collection = useCollection(ResourceType.ExpertiseDomain);
  const items = useResources(collection?.relationships.items.data);

  const handleSubmit = () => {
    callback({ expertiseDomainId: selectedDomain }, () => {
      toast({
        description: t("main:chat.toasts.expertiseDomainUpdated"),
      });
      handleClose();
    });
  };

  return (
    <ModalContent>
      <ModalHeader>
        {t("main:kandoorGPT.chat.change_expertise_domain_header")}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box fontSize="lg" mb="0.5em">
          {t("main:kandoorGPT.chat.change_expertise_domain_subheader")}
        </Box>
        <VStack
          gap="0.1em"
          maxHeight="50vh"
          overflowY="auto"
          paddingRight="0.5em"
        >
          {items.map((item) => (
            <Button
              isDisabled={loading}
              justifyContent="flex-start"
              key={item!.id}
              minHeight="1.5em"
              textAlign="left"
              variant={selectedDomain === item!.id ? "solid" : "outline"}
              width="100%"
              onClick={() => setSelectedDomain(item!.id)}
            >
              {item!.attributes.name}
            </Button>
          ))}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button isDisabled={loading || !selectedDomain} onClick={handleSubmit}>
          {t("main:kandoorGPT.chat.input.editButton")}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useCurrentUser from "../../../shared/hooks/useCurrentUser";
import CountBubble from "../Chat/components/CountBubble";
import ChatArchive from "../chatArchive";
import ChatViewer from "../chatViewer/ChatViewer";
import useTabPageCounts from "../chatViewer/hooks/useTabPageCounts";
import { followingTabs, forYouTabs, reviewTabs } from "../chatViewer/lib/tabs";

const TAB_KEYS = ["forYou", "following", "archive", "review"];

const VolunteerTabs = () => {
  const [currentUser] = useCurrentUser();
  const [newCount, toDoCount] = useTabPageCounts(forYouTabs);
  const location = useLocation();
  const navigate = useNavigate();
  const index = TAB_KEYS.findIndex((key) => key === location.hash.slice(1));
  const handleTabChange = (newIndex: number) => {
    navigate(`#${TAB_KEYS[newIndex]}`);
  };

  const count = (newCount ?? 0) + (toDoCount ?? 0);

  return (
    <Tabs
      isLazy
      colorScheme="primary"
      display="flex"
      flex={1}
      flexDirection="column"
      flexGrow={1}
      index={index >= 0 ? index : 0}
      maxWidth="100%"
      onChange={handleTabChange}
    >
      <TabList>
        <Tab fontWeight="bold">
          {t("main:dashboard.tabs.forYou")}
          <CountBubble
            background="status.error"
            color="white"
            count={count}
            title={t("main:dashboard.forYou.countBubble")!}
          />
        </Tab>
        <Tab fontWeight="bold">{t("main:dashboard.tabs.following")}</Tab>
        <Tab fontWeight="bold">{t("main:dashboard.tabs.allConversations")}</Tab>
        {currentUser?.attributes.isSupervisor && (
          <Tab fontWeight="bold">{t("main:dashboard.tabs.supervise")}</Tab>
        )}
      </TabList>
      <TabPanels
        display="flex"
        flexDirection="column"
        flexGrow={1}
        overflow="auto"
      >
        <TabPanel
          display="flex"
          flexDirection="column"
          flexGrow={1}
          overflow="auto"
          paddingX={0}
        >
          <ChatViewer sortable tabs={forYouTabs} />
        </TabPanel>
        <TabPanel
          display="flex"
          flexDirection="column"
          flexGrow={1}
          overflow="auto"
          paddingX={0}
        >
          <ChatViewer sortable tabs={followingTabs} />
        </TabPanel>
        <TabPanel paddingX={0}>
          <ChatArchive />
        </TabPanel>
        <TabPanel
          display="flex"
          flexDirection="column"
          flexGrow={1}
          overflow="auto"
          paddingX={0}
        >
          <ChatViewer review tabs={reviewTabs} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default VolunteerTabs;

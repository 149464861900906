import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Link,
  PinInput,
  PinInputField,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";

import { UserResource } from "../../../types/serializers";
import { useVerifyAuthCode } from "../../lib/auth";

import RequestNewCode from "./RequestNewCode";

const CODE_LENGTH = 6;

const EnterOTPStep = ({
  email,
  onBack,
  onSuccess,
}: {
  email: string;
  onBack: () => void;
  onSuccess: (user?: UserResource, location?: string | null) => void;
}) => {
  const [authCode, setAuthCode] = useState("");
  const [handleSubmit, loading, error] = useVerifyAuthCode(
    email,
    authCode,
    onSuccess
  );
  useEffect(() => {
    if (authCode.length === CODE_LENGTH) {
      handleSubmit();
    }
  }, [authCode]);

  return (
    <form onSubmit={handleSubmit}>
      <FormControl>
        <FormLabel fontWeight="bold">{t("main:signIn.email.label")}</FormLabel>
        <Input
          readOnly
          background="#f3f3f3"
          color="grey"
          type="email"
          value={email}
        />
      </FormControl>
      <Link as="button" marginBottom="2em" marginTop="0.5em" onClick={onBack}>
        {t("main:signIn.otp.back")}
      </Link>
      <FormControl>
        <Text>{t("main:signIn.otp.helper")}</Text>
        <Box display="inline-block" marginTop="1em">
          <PinInput
            otp
            value={authCode}
            onChange={(value) => {
              setAuthCode(value);
            }}
          >
            <PinInputField autoFocus marginRight="1em" />
            <PinInputField marginRight="1em" />
            <PinInputField marginRight="1em" />
            <PinInputField marginRight="1em" />
            <PinInputField marginRight="1em" />
            <PinInputField />
          </PinInput>
          {loading && <Spinner />}
        </Box>
        <Text color="status.error">{error}</Text>
      </FormControl>
      <Box>
        <RequestNewCode email={email} />
      </Box>
    </form>
  );
};

export default EnterOTPStep;

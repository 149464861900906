import { Box, HStack, Heading, IconButton, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import AsyncResource from "../../../../shared/components/async/AsyncResource";
import useCollection from "../../../../shared/hooks/useCollection";
import useCurrentUser from "../../../../shared/hooks/useCurrentUser";
import { NOT_NULL_FILTER } from "../../../../shared/lib/ids";
import {
  CollectionOrderDirection,
  ResourceType,
} from "../../../../types/serializers";
import userName from "../../Chat/lib/userName";

const FeedbackSlider = () => {
  const [currentUser] = useCurrentUser();
  const feedbacks = useCollection(ResourceType.Feedback, {
    filter: { feedback: [NOT_NULL_FILTER], volunteerId: [currentUser!.id] },
    order: { createdAt: CollectionOrderDirection.Desc },
  });
  const [index, setIndex] = useState(0);
  const goBack = () => {
    setIndex(
      index > 0
        ? index - 1
        : (feedbacks?.relationships.items.data?.length ?? 1) - 1
    );
  };
  const goForward = () => {
    setIndex(
      index < (feedbacks?.relationships.items.data?.length ?? 1) - 1
        ? index + 1
        : 0
    );
  };

  return (
    <Box width="100%">
      <Heading as="h3" marginBottom="1em" variant="xs">
        {t("main:account.volunteer.stats.feedbacks.recent")}
      </Heading>
      <HStack justifyContent="space-between" width="100%">
        <IconButton
          aria-label="Vorige"
          icon={<FiChevronLeft />}
          variant="ghost"
          onClick={goBack}
        />
        <AsyncResource resource={feedbacks?.relationships.items.data?.[index]}>
          {(feedback) => (
            <VStack flexGrow={1}>
              <Box>{feedback.attributes.feedback}</Box>
              <AsyncResource resource={feedback.relationships.user.data}>
                {(user) => <Box alignSelf="flex-end">- {userName(user)}</Box>}
              </AsyncResource>
            </VStack>
          )}
        </AsyncResource>
        <IconButton
          aria-label="Volgende"
          icon={<FiChevronRight />}
          variant="ghost"
          onClick={goForward}
        />
      </HStack>
    </Box>
  );
};

export default FeedbackSlider;

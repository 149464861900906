import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  InputGroup,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";
import { FiCheck } from "react-icons/fi";

import { FormFieldAttribute } from "../../../types/forms";
import { JSONValue, Resource } from "../../../types/serializers";
import { CallbackErrorHandler } from "../../hooks/useResourceCallback";
import useUpdateCallback from "../../hooks/useUpdateCallback";

import OmniInput from "./OmniInput";
import { InputTypes } from "./types";

interface InlineInputProps<T extends Resource> {
  attribute: FormFieldAttribute<T["type"]>;
  helper?: string;
  label: string;
  resource?: T;
  type?: InputTypes;
}

const getEventValue = (value: any) =>
  typeof value === "object" && value.target
    ? value.target.type === "checkbox"
      ? value.target.checked
      : value.target.value
    : value;

const DELAYED_INPUTS = [
  InputTypes.Date,
  InputTypes.DateTime,
  InputTypes.Email,
  InputTypes.Markdown,
  InputTypes.Number,
  InputTypes.Text,
  InputTypes.TextArea,
];

const InlineInput = <T extends Resource>({
  attribute,
  helper,
  label,
  resource,
  type = InputTypes.Text,
}: InlineInputProps<T>) => {
  const [callback, loading] = useUpdateCallback(resource);
  const initialValue = (resource?.attributes as T["attributes"])?.[attribute];
  const [value, setValue] = useState<JSONValue>(initialValue ?? "");
  const [error, setError] = useState<string | undefined>(undefined);
  const [saved, setSaved] = useState<boolean>(false);

  const handleError: CallbackErrorHandler = ({ json }) => {
    setError(
      json.errors?.map((err) => err.title)?.join("\n") ??
        t("shared:status.error")!
    );
  };
  const handleSuccess = () => {
    setSaved(true);
  };
  const handleUpdate = (newValue?: JSONValue) => {
    setError(undefined);
    setSaved(false);
    if (callback && newValue !== initialValue) {
      callback({ [attribute]: newValue }, handleSuccess, handleError);
    }
  };

  if (!resource) {
    return null;
  }
  const inputProps = {
    name: attribute,
    onBlur: () => handleUpdate(value),
    onChange: (event: unknown) => {
      const newValue = getEventValue(event);
      setValue(newValue);
      if (!DELAYED_INPUTS.includes(type)) {
        handleUpdate(newValue);
      }
    },
    value: value,
  };

  return (
    <FormControl isInvalid={!!error} marginBottom="1em">
      {type !== InputTypes.Checkbox && <FormLabel>{label}</FormLabel>}
      <InputGroup>
        <OmniInput<T["type"]>
          attribute={attribute}
          helper={helper}
          inputProps={inputProps}
          inputType={type}
          label={label}
        />
        {saved && (
          <InputRightElement>
            <Icon
              as={FiCheck}
              color="status.success"
              title={t("shared:status.saved")}
            />
          </InputRightElement>
        )}
        {loading && (
          <InputRightElement>
            <Spinner />
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
export default InlineInput;

import { GridItem } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

interface MainBodyProps extends PropsWithChildren {
  display?: string;
  height?: string;
  minHeight?: string;
}

const MainBody = ({ children, display, height, minHeight }: MainBodyProps) => (
  <GridItem
    area="body"
    as="section"
    display={display}
    height={height}
    minHeight={minHeight}
    overflow="auto"
  >
    {children}
  </GridItem>
);

export default MainBody;

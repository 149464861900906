import { useCallback, useEffect, useState } from "react";

import {
  getSessionStorage,
  removeSessionStorage,
  setSessionStorage,
} from "../lib/storage";

interface StoredStateOpts {
  defaultValue?: string | undefined;
  persist?: boolean;
}

const useStoredState = (
  key?: string,
  opts: StoredStateOpts = {}
): [
  value: string | undefined,
  setValue: (newValue: string | undefined) => void
] => {
  const { defaultValue, persist } = opts;
  const initialValue = key
    ? () => getSessionStorage(key, persist) ?? defaultValue
    : defaultValue;
  const [value, setValue] = useState(initialValue);
  const setStoredValue = useCallback(
    (newValue: string | undefined) => {
      setValue(newValue);
      if (key) {
        if (newValue) {
          setSessionStorage(key, newValue, persist);
        } else {
          removeSessionStorage(key, persist);
        }
      }
    },
    [key]
  );

  useEffect(() => {
    setValue(initialValue);
  }, [key]);

  return [value, setStoredValue];
};

export default useStoredState;

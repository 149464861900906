import {
  Box,
  Button,
  HStack,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiCheck, FiX } from "react-icons/fi";

import { ModalRenderSharedProps } from "../../../../shared/components/modal/ModalContextProvider";
import useCreateCallback from "../../../../shared/hooks/useCreateCallback";
import useCurrentUser from "../../../../shared/hooks/useCurrentUser";
import { trackEvent } from "../../../../shared/lib/analytics";
import { ResourceType } from "../../../../types/serializers";

export const NewsletterModal = ({ handleClose }: ModalRenderSharedProps) => {
  const toast = useToast();
  const [currentUser] = useCurrentUser();
  const [createSubscription, isLoading] = useCreateCallback(
    ResourceType.NewsletterSubscription
  );
  const { email, firstname } = currentUser?.attributes ?? {};

  const handleSubscribe = () => {
    const handleSuccess = () => {
      trackEvent("newsletter_subscribe", { source: "chat" });
      toast({
        description: t("main:account.user.settings.newsletter.subscribed"),
      });
      handleClose();
    };

    createSubscription({ email, firstname }, handleSuccess);
  };

  return (
    <ModalContent>
      <ModalHeader> {t("main:chat.newsletter.header")}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <HStack alignItems="flex-start">
          <Icon
            as={FiCheck}
            background="status.success"
            borderRadius="full"
            color="white"
            flex="none"
            fontSize="1.5em"
            marginTop=".1em"
            padding=".1em"
          />
          <Text fontSize="lg" mb="1em">
            {t("main:chat.newsletter.thanks")}
          </Text>
        </HStack>
        <Box>
          <Text fontSize="lg" mb="1em">
            {t("main:chat.newsletter.question")}
          </Text>
        </Box>
      </ModalBody>
      <ModalFooter display="flex" gap="0.5em">
        <Button
          isDisabled={isLoading}
          leftIcon={<FiX />}
          variant="outline"
          width="50%"
          onClick={handleClose}
        >
          {t("main:chat.newsletter.no")}
        </Button>
        <Button
          isLoading={isLoading}
          leftIcon={<FiCheck />}
          variant="outline"
          width="50%"
          onClick={handleSubscribe}
        >
          {t("main:chat.newsletter.yes")}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export default NewsletterModal;

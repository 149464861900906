import { VStack, useBreakpointValue } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { RemoveScrollBar } from "react-remove-scroll-bar";

import Collection from "../../../shared/components/collection";
import CollectionItems from "../../../shared/components/collection/CollectionItems";
import CollectionPagination from "../../../shared/components/collection/CollectionPagination";
import { Loader } from "../../../shared/components/loader";
import { ResourceType } from "../../../types/serializers";

import ChatViewerCard from "./ChatViewerCard";
import { ChatViewerTab } from "./ChatViewerTabs";
import { getTabCollectionId } from "./lib/tabs";

interface ChatViewerSelectorProps {
  fullQuestion?: boolean;
  reverseList?: boolean;
  selectConversationId: (id?: string) => void;
  selectedConversationId?: string;
  tab: ChatViewerTab;
}

const ChatViewerList = ({
  fullQuestion,
  selectConversationId,
  selectedConversationId,
  reverseList,
  tab,
}: ChatViewerSelectorProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Collection
      fresh
      id={getTabCollectionId(tab, reverseList)}
      wrapper={Fragment}
      onLoad={() => <Loader width="100%" />}
    >
      {isMobile && selectedConversationId && <RemoveScrollBar />}
      <VStack gap="1em" height="100%" overflow="auto" width="100%">
        <VStack height="100%" overflow="auto" role="tablist" width="100%">
          <CollectionItems<ResourceType.ChatConversation> onEmpty={tab.onEmpty}>
            {(item) => (
              <ChatViewerCard
                chatConversation={item}
                fullQuestion={fullQuestion}
                selectConversationId={selectConversationId}
                selectedConversationId={selectedConversationId}
                tabKey={tab.key}
              />
            )}
          </CollectionItems>
        </VStack>
        <CollectionPagination />
      </VStack>
    </Collection>
  );
};

export default ChatViewerList;

import { Box } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import QuestionButton from "../buttons/QuestionButton";
import ChatViewer from "../chatViewer/ChatViewer";
import { userTabs } from "../chatViewer/lib/tabs";
import useHideFooter from "../footer/hooks/useHideFooter";

const UserQuestionsTab = () => {
  useHideFooter();

  return (
    <>
      <Box alignSelf="end">
        <QuestionButton referer="profile-page" variant="outline">
          {t("main:account.user.questionButton")}
        </QuestionButton>
      </Box>
      <ChatViewer tabs={userTabs} />
    </>
  );
};

export default UserQuestionsTab;

import { Tag, TagLabel, chakra } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import Link from "../../../shared/components/link";
import MainFooterItem from "../../../shared/components/main/MainFooterItem";
import useStore from "../../../shared/hooks/useStore";
import { BlogResource, ResourceType } from "../../../types/serializers";

const BlogRelatedCategories = ({ blog }: { blog: BlogResource }) => {
  const { t } = useTranslation();
  const store = useStore();

  return (
    <MainFooterItem>
      <chakra.span
        fontSize={15}
        fontWeight="bold"
        marginRight={2}
        verticalAlign="middle"
      >
        {t("main:blogs.related")}
      </chakra.span>
      {blog.attributes.categoryList.map((category) => (
        <Link
          href={store.router.indexRoute(ResourceType.Blog, {
            filter: { categoryList: [category] },
          })}
          key={category}
        >
          <Tag
            borderRadius="full"
            color="primary.darkActive"
            marginBottom={1}
            marginRight={2}
            paddingX={4}
            paddingY={2}
            variant="outline"
            verticalAlign="unset"
          >
            <TagLabel>{category}</TagLabel>
          </Tag>
        </Link>
      ))}
    </MainFooterItem>
  );
};

export default BlogRelatedCategories;

import {
  Box,
  Grid,
  GridItem,
  HStack,
  Heading,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";

import { Loader } from "../../../shared/components/loader";
import useCurrentUser from "../../../shared/hooks/useCurrentUser";
import useFetchCallback from "../../../shared/hooks/useFetchCallback";
import { Compliment } from "../../../types/serializers";
import {
  COMPLIMENT_TRANSLATIONS,
  ICONS,
} from "../Chat/modals/ComplimentsModal";

import BarChart from "./stats/BarChart";
import FeedbackSlider from "./stats/FeedbackSlider";

export interface Stats {
  compliments: Array<{ count: number; name: Compliment }>;
  helpedCount: number;
  helpedPerWeek: Array<{ count: number; week: number }>;
  questions: Array<{ count: number; name: number }>;
}

interface ComplimentStatProps {
  compliment: Compliment;
  count: number;
}

const ComplimentStat = ({ compliment, count }: ComplimentStatProps) => {
  const icon = ICONS[compliment];
  const label = COMPLIMENT_TRANSLATIONS[compliment];

  if (count === 0) {
    return null;
  }

  return (
    <tr>
      <td>
        <VStack paddingBottom="1em" paddingRight="1em">
          <img alt={label} src={icon} />
          <Text textStyle="bodySmall">{label}</Text>
        </VStack>
      </td>
      <td>
        <Heading as="h4" variant="xl">
          {count}x
        </Heading>
      </td>
    </tr>
  );
};

const VolunteerStatsTab = () => {
  const [currentUser] = useCurrentUser();
  const [statsCallback, loading] = useFetchCallback("/stats", "GET");
  const [stats, setStats] = useState<Stats | undefined>(undefined);

  useEffect(() => {
    statsCallback(undefined, ({ json }) => {
      setStats(json as Stats);
    });
  }, []);
  if (loading) {
    return <Loader />;
  }

  if (!currentUser || !stats) {
    return null;
  }

  return (
    <Box>
      <Heading as="h2" variant="lg">
        {t("main:account.volunteer.stats.header")}
      </Heading>
      <Grid gap="1em" templateColumns="repeat(3, 1fr)">
        <GridItem background="background.banner" padding="1em">
          <Heading as="h3" marginBottom="1em" variant="xs">
            {t("main:account.volunteer.stats.questions.header")}
          </Heading>
          <table>
            {stats.questions.map(({ name, count }) => (
              <chakra.tr height="35px" key={name}>
                <td>
                  <Box
                    color="status.neutral"
                    fontFamily="bree"
                    fontSize="30px"
                    fontWeight="bold"
                    paddingRight="0.5em"
                  >
                    {count}
                  </Box>
                </td>
                <chakra.td verticalAlign="bottom">{name}</chakra.td>
              </chakra.tr>
            ))}
          </table>
        </GridItem>
        <GridItem background="background.banner" padding="1em">
          <VStack height="100%" justifyContent="space-between">
            <Box>
              <Heading as="h3" marginBottom="1em" variant="xs">
                {t("main:account.volunteer.stats.feedbacks.header")}
              </Heading>
              <HStack justifyContent="space-between" width="100%">
                <Box>{t("main:account.volunteer.stats.feedbacks.count")}</Box>
                <Box
                  color="status.success"
                  fontFamily="bree"
                  fontSize="40px"
                  fontWeight="bold"
                >
                  {stats.helpedCount}
                </Box>
              </HStack>
            </Box>
            <FeedbackSlider />
            <Box />
          </VStack>
        </GridItem>
        <GridItem background="background.banner" padding="1em">
          <Heading as="h3" marginBottom="1em" variant="xs">
            {t("main:account.volunteer.stats.compliments.header")}
          </Heading>
          <Box>
            <table>
              {Object.values(Compliment).map((compliment) => (
                <ComplimentStat
                  compliment={compliment}
                  count={
                    stats.compliments.find(({ name }) => compliment === name)
                      ?.count ?? 0
                  }
                  key={compliment}
                />
              ))}
            </table>
          </Box>
        </GridItem>
      </Grid>
      <Box background="background.banner" marginTop="1em" padding="2em">
        <Heading as="h3" marginBottom="1em" variant="xs">
          {t("main:account.volunteer.stats.chart.header")}
        </Heading>
        <BarChart stats={stats} />
      </Box>
    </Box>
  );
};

export default VolunteerStatsTab;

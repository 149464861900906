import {
  ChatParticipationResource,
  UserResource,
} from "../../../../types/serializers";

const userName = (
  user: UserResource | ChatParticipationResource | undefined
) => {
  if (!user) {
    return undefined;
  }

  const { firstname } = user.attributes;

  if (firstname === "x") {
    return "[Verwijderd]";
  }

  return firstname;
};

export default userName;

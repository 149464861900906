import { Box, GridItem } from "@chakra-ui/react";
import React from "react";

import Layout from "../layout/Layout";

import NewsletterForm from "./NewsletterForm";

const BlogNewsletterForm = () => {
  return (
    <Box backgroundColor="background.banner" paddingY="2em">
      <Layout
        as="footer"
        gridTemplateColumns={{
          base: "1fr",
          lg: "1fr 2fr 1fr",
        }}
        templateAreas={{ base: '"footer"', lg: '"left footer right"' }}
      >
        <GridItem gridArea="footer">
          <NewsletterForm source="blog" />
        </GridItem>
      </Layout>
    </Box>
  );
};

export default BlogNewsletterForm;

import { useToast } from "@chakra-ui/react";
import copyToClipboard from "clipboard-copy";
import { t } from "i18next";
import React from "react";
import { FiShare2 } from "react-icons/fi";

import Button from "../../../../../shared/components/button";
import useResourceRoute from "../../../../../shared/components/store/hooks/useResourceRoute";
import { trackEvent } from "../../../../../shared/lib/analytics";
import { ChatConversationResource } from "../../../../../types/serializers";

const CopyUrlButton = ({
  chatConversation,
}: {
  chatConversation: ChatConversationResource;
}) => {
  const toast = useToast();
  const route = useResourceRoute(chatConversation);

  return (
    <Button
      leftIcon={<FiShare2 />}
      variant="nude"
      onClick={() => {
        trackEvent("click_share_question");

        copyToClipboard(GLOBALS.root + route).then(() => {
          toast({
            description: t("main:chat.toasts.copySuccess"),
          });
        });
      }}
    >
      {t("main:chat.shareQuestion")}
    </Button>
  );
};

export default CopyUrlButton;

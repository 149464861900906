import { Alert, Link, Spinner } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";

import { useRequestPasswordReset } from "../../lib/auth";

const RequestPasswordReset = ({
  email,
  float,
}: {
  email: string;
  float?: "right";
}) => {
  const [newPasswordRequested, setPasswordResetRequested] = useState(false);
  const [requestPasswordReset, loadingPasswordReset, passwordResetError] =
    useRequestPasswordReset(email, () => setPasswordResetRequested(true));

  if (passwordResetError) {
    return (
      <Alert marginTop="1em" status="error">
        {passwordResetError}
      </Alert>
    );
  }

  if (newPasswordRequested) {
    return (
      <Alert marginTop="1em" status="success">
        {t("main:questionFlow.enterPasswordStep.passwordResetRequested")}
      </Alert>
    );
  }

  return (
    <Link
      as="button"
      disabled={loadingPasswordReset}
      float={float}
      marginTop="0.5em"
      type="button"
      onClick={requestPasswordReset}
    >
      {loadingPasswordReset && <Spinner size="sm" />}
      {t("main:questionFlow.enterPasswordStep.buttons.passwordReset")}
    </Link>
  );
};

export default RequestPasswordReset;

import { Flex } from "@chakra-ui/react";
import React from "react";

import { ChatConversationResource } from "../../../../types/serializers";
import useCurrentFeedback from "../hooks/useCurrentFeedback";

import { FeedbackItem } from "./FeedbackBox";
import FeedbackForm from "./FeedbackForm";

const InlineFeedback = ({
  chatConversation,
}: {
  chatConversation: ChatConversationResource;
}) => {
  const [currentFeedback, refreshCurrentFeedback] =
    useCurrentFeedback(chatConversation);

  if (currentFeedback) {
    return (
      <Flex
        border="1px solid"
        borderColor="background.stroke"
        borderRadius="lg"
        direction={{ base: "column", md: "row" }}
        flexShrink={0}
        gap={{ base: "1em", md: "2em" }}
        overflow="auto"
        p={4}
      >
        <FeedbackItem feedback={currentFeedback} />
      </Flex>
    );
  }

  return (
    <FeedbackForm
      chatConversation={chatConversation}
      onSubmit={refreshCurrentFeedback}
    />
  );
};

export default InlineFeedback;

import { Button } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiXCircle } from "react-icons/fi";

import useModalDispatch from "../../../../../shared/hooks/useModalDispatch";
import {
  ChatConversationResource,
  ChatConversationStatus,
} from "../../../../../types/serializers";
import { CloseConfirmationModal } from "../../modals/CloseConfirmationModal";

interface CloseButtonProps {
  chatConversation: ChatConversationResource;
}

const CloseButton: React.FC<CloseButtonProps> = ({ chatConversation }) => {
  const dispatchModal = useModalDispatch(CloseConfirmationModal);

  if (
    ![
      ChatConversationStatus.Active,
      ChatConversationStatus.PendingAcceptance,
    ].includes(chatConversation.attributes.status)
  ) {
    return null;
  }

  const handleOpenModal = () => {
    dispatchModal({ chatConversation });
  };
  const pendingAcceptance =
    chatConversation.attributes.status ===
    ChatConversationStatus.PendingAcceptance;

  return (
    <Button leftIcon={<FiXCircle />} variant="ghost" onClick={handleOpenModal}>
      {pendingAcceptance
        ? t("main:kandoorGPT.chat.cancelButton")
        : t("main:kandoorGPT.chat.close")}
    </Button>
  );
};

export default CloseButton;

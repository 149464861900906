import useResources from "../../../../shared/hooks/useResources";
import {
  CollectionResource,
  EmptyResource,
  ResourceType,
} from "../../../../types/serializers";
import { ChatViewerTab } from "../ChatViewerTabs";
import { getTabCollectionId } from "../lib/tabs";

const useTabPageCounts = (tabs: ChatViewerTab[]) => {
  const collectionIdentifiers = tabs.map<
    EmptyResource<ResourceType.Collection>
  >((tab) => ({
    id: getTabCollectionId(tab),
    type: ResourceType.Collection,
  }));
  const collections = useResources<ResourceType.Collection, CollectionResource>(
    collectionIdentifiers
  );

  return collections.map((collection) => collection?.attributes.totalCount);
};

export default useTabPageCounts;

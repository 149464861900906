import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  GridItem,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import Link from "../../../shared/components/link";

interface BreadcrumbItem {
  href: string;
  label: string;
}

interface BreadcrumbProps {
  items: Array<BreadcrumbItem | undefined>;
}

const BreadcrumbsInner = ({ item }: { item: BreadcrumbItem }) => (
  <BreadcrumbLink
    as={Link}
    color="primary.darkActive"
    fontSize="14px"
    href={item.href}
    property="item"
    textTransform="lowercase"
    typeof="WebPage"
  >
    {/* eslint-disable-next-line react/no-unknown-property */}
    <span property="name">{item.label}</span>
  </BreadcrumbLink>
);

const Breadcrumbs = ({ items }: BreadcrumbProps) => {
  const breadcrumbItems = items.filter(Boolean) as BreadcrumbItem[];
  const lastItem = breadcrumbItems.pop();

  return (
    <GridItem area="breadcrumbs" marginBottom={7} marginTop={1}>
      <Breadcrumb
        separator={<Icon as={FaChevronRight} fontSize={10} marginX={3} />}
        typeof="BreadcrumbList"
        vocab="https://schema.org/"
      >
        {breadcrumbItems.map((item, index) => (
          <BreadcrumbItem
            display={{ base: "none", md: "block" }}
            key={`${item.label}-${index}`}
            property="itemListElement"
            typeof="ListItem"
          >
            <BreadcrumbsInner item={item} key={`item-${index}`} />
            <meta content={(index + 1).toString()} property="position" />
          </BreadcrumbItem>
        ))}
        {lastItem && (
          <BreadcrumbItem property="itemListElement" typeof="ListItem">
            <Icon
              as={FaChevronLeft}
              display={{ base: "block", md: "none" }}
              fontSize={10}
              marginRight={3}
            />
            <BreadcrumbsInner item={lastItem} />
            <meta content={items.length.toString()} property="position" />
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </GridItem>
  );
};

export default Breadcrumbs;

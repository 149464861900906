import { chakra } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

const MainFooterItem = ({ children }: PropsWithChildren) => {
  return (
    <chakra.div marginBottom={{ base: "2.5em", md: "3em" }}>
      {children}
    </chakra.div>
  );
};

export default MainFooterItem;

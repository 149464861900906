import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import Container from "../../../shared/components/layout/Container";
import { FaqAccordion, useAccordionItems } from "../faqAccordion/index";

const HomeAbout = () => {
  const { t } = useTranslation();
  const accordionItems = useAccordionItems("main:aboutKandoor.accordion");

  return (
    <Container py="2.5em">
      <SimpleGrid columns={{ base: 1, md: 2 }}>
        <Box pr={8}>
          <Heading as="h2" mb={1} variant="lg">
            {t("main:home.aboutKandoor.title")}
          </Heading>
          <Text color="gray.subheader" fontSize={18} fontWeight={500} mb="1em">
            {t("main:home.aboutKandoor.subtitle")}
          </Text>
          <Text lineHeight="34px" mb="2em">
            {t("main:home.aboutKandoor.intro")}
          </Text>
          <Heading mb="0.2em" size="sm">
            {t("main:home.aboutKandoor.workings.label")}
          </Heading>
          <Text lineHeight="34px">
            {t("main:home.aboutKandoor.workings.body")}
          </Text>
        </Box>
        <Box mt={{ base: "1em", md: "10px" }}>
          <FaqAccordion
            setDefaultIndex
            accordionItems={accordionItems}
            variant="home"
          />
        </Box>
      </SimpleGrid>
    </Container>
  );
};

export default HomeAbout;

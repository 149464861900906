import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiBell, FiBellOff } from "react-icons/fi";

import Button from "../../../../../shared/components/button";
import useActionCallback from "../../../../../shared/hooks/useActionCallback";
import { ChatConversationResource } from "../../../../../types/serializers";
import { ChatViewerTabKey } from "../../../chatViewer/lib/tabs";

const ALLOWED_TABS = [ChatViewerTabKey.Following, ChatViewerTabKey.ForYouNew];

const FollowButton = ({
  chatConversation,
  tabKey,
}: {
  chatConversation: ChatConversationResource;
  tabKey: ChatViewerTabKey;
}) => {
  const following = tabKey === ChatViewerTabKey.Following;
  const [callback, loading] = useActionCallback(
    chatConversation,
    "follow",
    following ? "DELETE" : "PUT"
  );
  const toast = useToast();

  if (!ALLOWED_TABS.includes(tabKey)) {
    return null;
  }

  return (
    <Button
      color="primary.dark"
      isDisabled={loading}
      leftIcon={following ? <FiBellOff /> : <FiBell />}
      marginRight="0.5em"
      marginTop="12px"
      variant="link"
      onClick={(e) => {
        e.stopPropagation();
        callback({}, () => {
          toast({
            description: following
              ? t("main:chat.toasts.unfollowed")
              : t("main:chat.toasts.followed"),
          });
        });
      }}
    >
      {following
        ? t("main:chat.buttons.unfollow")
        : t("main:chat.buttons.follow")}
    </Button>
  );
};

export default FollowButton;

import useCollection from "../../../../shared/hooks/useCollection";
import useCurrentUser from "../../../../shared/hooks/useCurrentUser";
import useRefreshResource from "../../../../shared/hooks/useRefreshResource";
import useResources from "../../../../shared/hooks/useResources";
import {
  ChatConversationResource,
  FeedbackResource,
  ResourceType,
  UserType,
} from "../../../../types/serializers";

const useCurrentFeedback = (
  chatConversation: ChatConversationResource
): [
  currentFeedback: FeedbackResource | undefined,
  refreshCurrentFeedback: () => void
] => {
  const refreshResource = useRefreshResource();
  const [currentUser] = useCurrentUser();
  const feedbackCollection = useCollection(ResourceType.Feedback, {
    filter: {
      chatConversationId: [chatConversation.id],
      userType: [UserType.Volunteer, UserType.Supervisor],
    },
  });
  const feedbacks = useResources(feedbackCollection?.relationships.items.data);
  const currentFeedback = feedbacks.find(
    (feedback) =>
      feedback?.relationships.user.data?.id === currentUser?.attributes.id
  );

  const refreshCurrentFeedback = () => {
    if (feedbackCollection) {
      refreshResource(feedbackCollection);
    }
  };

  return [currentFeedback, refreshCurrentFeedback];
};

export default useCurrentFeedback;

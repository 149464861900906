import { Heading } from "@chakra-ui/react";
import React from "react";

import MainFooterItem from "../../../shared/components/main/MainFooterItem";
import useResources from "../../../shared/hooks/useResources";
import { EmptyResource, ResourceType } from "../../../types/serializers";
import { FaqAccordion } from "../faqAccordion";

export interface PillarPageBlockFaqsProps {
  embeddedFaqs?: EmptyResource<ResourceType.EmbeddedFaq>[];
  faqHeading?: string;
}

const PillarPageBlockFaqs = ({
  embeddedFaqs: embeddedFaqsData,
  faqHeading,
}: PillarPageBlockFaqsProps) => {
  const embeddedFaqs = useResources(embeddedFaqsData);
  if (!embeddedFaqs || embeddedFaqs.length === 0) {
    return null;
  }
  const accordionItems = embeddedFaqs.filter(Boolean).map((embeddedFaq) => ({
    content: embeddedFaq!.attributes.answer,
    title: embeddedFaq!.attributes.question,
  }));

  return (
    <MainFooterItem>
      {faqHeading && (
        <Heading as="h3" color="#7A7A7A" paddingBottom="1em" variant="sm">
          {faqHeading}
        </Heading>
      )}
      <FaqAccordion accordionItems={accordionItems} variant="basic" />
    </MainFooterItem>
  );
};

export default PillarPageBlockFaqs;

/* istanbul ignore file */

import { useEffect, useState } from "react";

const usePageViewport = () => {
  const [height, setHeight] = useState(0);
  const [bottom, setBottom] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    const listener = () => {
      setHeight(window.innerHeight);
      setTop(window.scrollY);
      setBottom(window.innerHeight - window.scrollY);
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, []);

  return { bottom, height, top };
};

export default usePageViewport;

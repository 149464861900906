import { formatDistanceToNowStrict } from "date-fns";
import { nl } from "date-fns/locale";
import { useEffect, useRef, useState } from "react";

const INTERVAL = 60_000;

const getRelativeTime = (time?: Date) =>
  time
    ? formatDistanceToNowStrict(time, {
        addSuffix: true,
        locale: nl,
      })
    : undefined;

const useRelativeTime = (time?: Date): string | undefined => {
  const timeRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [formatted, setFormatted] = useState<string | undefined>(
    getRelativeTime(time)
  );

  const updateTime = () => {
    setFormatted(getRelativeTime(time));

    timeRef.current = setTimeout(updateTime, INTERVAL);
  };

  // Schedule first update
  useEffect(() => {
    timeRef.current = setTimeout(updateTime, INTERVAL);
  }, [time?.getTime()]);

  // Cleanup Timeout
  useEffect(
    () => () => {
      if (timeRef.current) {
        clearTimeout(timeRef.current);
      }
    },
    []
  );

  return formatted;
};

export default useRelativeTime;

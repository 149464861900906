import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";

import RichText from "../../../shared/components/richText";
import { trackEvent } from "../../../shared/lib/analytics";

export interface AccordionItemProps {
  content: string;
  title: string;
}

export const FaqAccordionItem = ({ title, content }: AccordionItemProps) => {
  const handleClick = (isExpanded: boolean) => () => {
    if (!isExpanded) {
      trackEvent("faq_accordion", { title });
    }
  };

  return (
    <AccordionItem
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton py={4} onClick={handleClick(isExpanded)}>
            <Box flex="1" textAlign="left">
              <span itemProp="name">{title}</span>
            </Box>
            <Box>{isExpanded ? <FaMinus /> : <FaPlus />}</Box>
          </AccordionButton>
          <AccordionPanel
            itemScope
            itemProp="acceptedAnswer"
            itemType="https://schema.org/Answer"
            lineHeight="2em"
            pb={4}
          >
            <RichText itemProp="text">{content}</RichText>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

import { HStack, Image, Text } from "@chakra-ui/react";
import React from "react";

import AsyncResource from "../../../../shared/components/async/AsyncResource";
import Collection from "../../../../shared/components/collection";
import CollectionItems from "../../../../shared/components/collection/CollectionItems";
import useResource from "../../../../shared/hooks/useResource";
import { getCollectionId } from "../../../../shared/lib/ids";
import {
  ChatConversationResource,
  FeedbackResource,
  ResourceType,
  UserType,
} from "../../../../types/serializers";
import userName from "../../Chat/lib/userName";

const FeedbackItem = ({ feedback }: { feedback?: FeedbackResource }) => {
  if (!feedback?.attributes.feedback) {
    return null;
  }

  return (
    <>
      <AsyncResource resource={feedback.relationships.user.data}>
        {(user) => (
          <HStack marginBottom="0.5em">
            {user.attributes.avatarUrl && (
              <Image
                alt={user.attributes.firstname}
                borderRadius="1em"
                height="2em"
                marginRight="0.1em"
                src={user.attributes.avatarUrl}
                verticalAlign="middle"
                width="2em"
              />
            )}
            <Text flexGrow={1}>{userName(user)}</Text>
          </HStack>
        )}
      </AsyncResource>
      <Text fontStyle="italic" noOfLines={2}>
        &quot;{feedback.attributes.feedback}&quot;
      </Text>
    </>
  );
};

const FeedbackCell = (item: ChatConversationResource) => {
  const userFeedback = useResource(item.relationships.activeFeedback?.data);

  return (
    <>
      <FeedbackItem feedback={userFeedback} />
      <Collection
        id={getCollectionId(ResourceType.Feedback, {
          filter: {
            chatConversationId: [item.id],
            userType: [UserType.Volunteer, UserType.Supervisor],
          },
        })}
      >
        <CollectionItems<ResourceType.Feedback>>
          {(feedback) => <FeedbackItem feedback={feedback} />}
        </CollectionItems>
      </Collection>
    </>
  );
};

export default FeedbackCell;

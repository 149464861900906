import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";
import { FiChevronRight } from "react-icons/fi";

import { useRequestAuthCode } from "../../lib/auth";

const EnterEmailForm = ({
  onRequirePassword,
  onSuccess,
}: {
  onRequirePassword: (email: string) => void;
  onSuccess: (email: string) => void;
}) => {
  const [email, setEmail] = useState("");
  const [handleSubmit, loading, error] = useRequestAuthCode(
    email,
    () => onSuccess(email),
    () => onRequirePassword(email)
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormControl>
        <FormLabel fontWeight="bold">{t("main:signIn.email.label")}</FormLabel>
        <Input
          required
          placeholder={t("main:signIn.email.placeholder")!}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <Text color="status.error">{error}</Text>
      <Box float="right">
        <Button
          isDisabled={loading}
          marginTop="1em"
          rightIcon={<FiChevronRight />}
          type="submit"
        >
          {t("main:signIn.email.button")}
        </Button>
      </Box>
    </form>
  );
};

export default EnterEmailForm;

import { Box, Button } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import useStoredState from "../../../../shared/hooks/useStoredState";

import AutoResizeTextarea from "./AutoResizeTextarea";

const MULTI_LINES = 4;

interface ChatInputProps {
  chatConversationId?: string;
  handleIsTyping?: (typing: boolean) => void;
  isDisabled?: boolean;
  multiLine?: boolean;
  onSubmit: (message: string) => void;
}

export const ChatInput: React.FC<ChatInputProps> = ({
  chatConversationId,
  handleIsTyping,
  isDisabled,
  multiLine,
  onSubmit,
}) => {
  const [message, setMessage] = useStoredState(
    chatConversationId
      ? `chat-conversation:${chatConversationId}:input`
      : undefined,
    { defaultValue: "" }
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (handleIsTyping) {
      handleIsTyping(false);
    }
    if (message!.trim()) {
      onSubmit(message!);
      setMessage("");
    }
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMessage(e.target.value);

    if (handleIsTyping) {
      handleIsTyping(true);
    }
  };

  return (
    <Box
      alignItems="end"
      as="form"
      display="flex"
      flexDirection="row"
      onSubmit={handleSubmit}
    >
      <AutoResizeTextarea
        _focus={{ bg: "white" }}
        bg="#F5F5F7"
        flexGrow={1}
        minRows={multiLine ? MULTI_LINES : 1}
        mr={2}
        placeholder={t("main:kandoorGPT.chat.input.placeholder")!}
        rows={4}
        value={message}
        w="100%"
        onChange={handleChange}
      />
      <Button
        flexGrow={0}
        isDisabled={isDisabled}
        type="submit"
        variant="solid"
      >
        {t("main:kandoorGPT.chat.input.sendButton")}
      </Button>
    </Box>
  );
};

import { Box, Button, Center, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";

import Container from "../../../shared/components/layout/Container";
import Pension from "../../../shared/lib/categoryIcons/pension.svg";
import Relations from "../../../shared/lib/categoryIcons/relations.svg";
import Tax from "../../../shared/lib/categoryIcons/tax.svg";

import PillarShowcaseCard from "./PillarShowcaseCard";

const PillarShowcase = () => {
  const { t } = useTranslation();

  const categories = [
    {
      imageSrc: Tax,
      links: [
        {
          href: "/onderwerpen/belastingaangifte",
          name: t("main:home.pillarShowcase.tax.link1"),
        },
        {
          href: "/onderwerpen/toeslagen",
          name: t("main:home.pillarShowcase.tax.link2"),
        },
      ],
      title: t("main:home.pillarShowcase.tax.title"),
    },
    {
      imageSrc: Pension,
      links: [
        {
          href: "/onderwerpen/het-nieuwe-pensioen",
          name: t("main:home.pillarShowcase.pension.link1"),
        },
        {
          href: "/onderwerpen/pensioen-opbouwen",
          name: t("main:home.pillarShowcase.pension.link2"),
        },
        {
          href: "/onderwerpen/eerder-stoppen-met-werken",
          name: t("main:home.pillarShowcase.pension.link3"),
        },
      ],
      title: t("main:home.pillarShowcase.pension.title"),
    },
    {
      imageSrc: Relations,
      links: [
        {
          href: "/onderwerpen/kind-krijgen",
          name: t("main:home.pillarShowcase.relations.link1"),
        },
        {
          href: "/onderwerpen/scheiden",
          name: t("main:home.pillarShowcase.relations.link2"),
        },
        {
          href: "/onderwerpen/financieel-in-je-kracht",
          name: t("main:home.pillarShowcase.relations.link3"),
        },
      ],
      title: t("main:home.pillarShowcase.relations.title"),
    },
  ];

  return (
    <Container overflowX="hidden" py="2.5em">
      <Box>
        <Heading as="h2" mb={1} variant="lg">
          {t("main:home.pillarShowcase.title")}
        </Heading>
        <Text color="gray.subheader" fontSize={18} fontWeight={500} mb="1em">
          {t("main:home.pillarShowcase.subtitle")}
        </Text>
        <Flex
          alignItems="stretch"
          direction="row"
          height="100%"
          justifyContent="space-between"
          mx={{ base: "-2.5em", md: "0" }}
          my="2em"
          overflowX={{ base: "auto", md: "visible" }}
          px={["2.5em", "2.5em", "0"]}
          wrap="nowrap"
        >
          {categories.map((category, i) => {
            const marginRightValue = i !== categories.length - 1 ? "1em" : "0";

            return (
              <Box
                flexShrink={0}
                key={i}
                maxWidth="430px"
                mr={[marginRightValue, marginRightValue, "0.5em"]}
                w={["230px", "230px", "33.33%"]}
              >
                <PillarShowcaseCard {...category} />
              </Box>
            );
          })}
        </Flex>
        <Center>
          <Button
            as={Link}
            color="primary.darkActive"
            rightIcon={<FiChevronRight />}
            size="lg"
            to="/onderwerpen"
            variant="outline"
          >
            {t("main:home.pillarShowcase.seeOnderwerpen")}
          </Button>
        </Center>
      </Box>
    </Container>
  );
};
export default PillarShowcase;

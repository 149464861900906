import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { FiAlertCircle } from "react-icons/fi";

import { MessageRenderer } from "../ChatMessage";

const ErrorMessage = ({ chatMessage }: MessageRenderer) => {
  return (
    <Flex alignItems="center" bg="status.error" borderRadius="lg" p={4}>
      <Icon as={FiAlertCircle} boxSize="20px" color="white" mr={2} />
      <Text color="white" fontSize="lg">
        {chatMessage.attributes.content}
      </Text>
    </Flex>
  );
};

export default ErrorMessage;

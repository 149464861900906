import {
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiLogOut } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

import Container from "../../../shared/components/layout/Container";
import Main from "../../../shared/components/main/Main";
import MainBody from "../../../shared/components/main/MainBody";
import MainHeader from "../../../shared/components/main/MainHeader";
import useCurrentUser from "../../../shared/hooks/useCurrentUser";
import useSignOut from "../../../shared/hooks/useSignOut";
import UserDataTab from "../../components/profile/UserDataTab";
import UserQuestionsTab from "../../components/profile/UserQuestionsTab";
import UserSettingsTab from "../../components/profile/UserSettingsTab";
import usePageViewport from "../../hooks/usePageViewport";

const TAB_KEYS = ["vragen", "gegevens", "instellingen"];

const UserAccountPage = () => {
  const [currentUser] = useCurrentUser();
  const signOutCallback = useSignOut();
  const navigate = useNavigate();
  const location = useLocation();
  const index = TAB_KEYS.findIndex((key) => key === location.hash.slice(1));
  const { top } = usePageViewport();
  const maxHeight = ["", "#vragen"].includes(location.hash)
    ? `min(calc(90vh + ${top}px), calc(100vh + 150px))`
    : undefined;

  const handleTabChange = (newIndex: number) => {
    navigate(`#${TAB_KEYS[newIndex]}`);
  };

  return (
    <Main>
      <Container
        display="flex"
        flexDirection="column"
        maxHeight={maxHeight}
        overflow="auto"
      >
        <HStack justifyContent="space-between">
          <MainHeader
            marginBottom="0.2em"
            pageTitle={t("main:dashboard.header")}
            title={t("main:dashboard.greeting", {
              name: currentUser?.attributes.firstname,
            })}
          >
            {t("main:account.user.intro")}
          </MainHeader>
          <Button
            color="primary.darkActive"
            leftIcon={<FiLogOut />}
            variant="link"
            onClick={signOutCallback}
          >
            {t("main:navbar.signOut")}
          </Button>
        </HStack>
        <MainBody display="flex">
          <Tabs
            isLazy
            colorScheme="primary"
            display="flex"
            flex={1}
            flexDirection="column"
            index={index >= 0 ? index : 0}
            marginTop="3em"
            onChange={handleTabChange}
          >
            <TabList>
              <Tab fontWeight="bold">
                {t("main:account.user.tabs.questions")}
              </Tab>
              <Tab fontWeight="bold">{t("main:account.user.tabs.data")}</Tab>
              <Tab fontWeight="bold">
                {t("main:account.user.tabs.settings")}
              </Tab>
            </TabList>
            <TabPanels display="flex" flexDirection="column" overflow="auto">
              <TabPanel display="flex" flexDirection="column" overflow="auto">
                <UserQuestionsTab />
              </TabPanel>
              <TabPanel>
                <UserDataTab />
              </TabPanel>
              <TabPanel>
                <UserSettingsTab />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </MainBody>
      </Container>
    </Main>
  );
};

export default UserAccountPage;

import { HStack, chakra } from "@chakra-ui/react";
import React from "react";

import useResource from "../../../../shared/hooks/useResource";
import { ChatConversationResource } from "../../../../types/serializers";
import HelpedQueryChatButton from "../../Chat/components/buttons/HelpedQueryChatButton";
import {
  COMPLIMENT_TRANSLATIONS,
  ICONS,
} from "../../Chat/modals/ComplimentsModal";

const HelpedQuery = (item: ChatConversationResource) => {
  const helped = useResource(item.relationships.activeFeedback.data)?.attributes
    .helpedQuery;
  const compliment = useResource(item.relationships.activeFeedback.data)
    ?.attributes.compliment;
  const icon = compliment && ICONS[compliment];
  const label = compliment && COMPLIMENT_TRANSLATIONS[compliment];

  if (helped === undefined || helped === null) {
    return null;
  }

  if (helped) {
    return (
      <HStack>
        <HelpedQueryChatButton helped={helped} />
        <chakra.img
          alt={label}
          marginTop="1em"
          src={icon}
          title={label}
          width="2em"
        />
      </HStack>
    );
  }

  return <HelpedQueryChatButton helped={helped} />;
};

export default HelpedQuery;

import { Button, ButtonProps } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";

interface HelpedQueryChatButtonProps extends ButtonProps {
  helped: boolean;
  loading?: boolean;
}

const HelpedQueryChatButton: React.FC<HelpedQueryChatButtonProps> = ({
  helped,
  loading,
  onClick,
  ...rest
}) => {
  const backgroundColor = helped ? "status.success" : "status.error";
  const hoverBackgroundColor = helped ? "#49814E" : "#964D4D";
  const Icon = helped ? FiThumbsUp : FiThumbsDown;
  const label = helped
    ? t("main:chat.feedback.yes")
    : t("main:chat.feedback.no");

  return (
    <Button
      _active={{
        backgroundColor: onClick ? hoverBackgroundColor : undefined,
      }}
      _hover={{
        backgroundColor: onClick ? hoverBackgroundColor : undefined,
        cursor: onClick ? "pointer" : "unset",
      }}
      backgroundColor={backgroundColor}
      color="blackish"
      isDisabled={loading}
      leftIcon={<Icon />}
      marginTop="16px"
      variant="solid"
      w="80px"
      onClick={onClick}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default HelpedQueryChatButton;

import { Box, Flex, Icon, Text, chakra } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

import useCurrentUser from "../../../../shared/hooks/useCurrentUser";
import { formatDateTime } from "../../../../shared/lib/date";
import {
  AI_USER_ID,
  GUEST_USER_ID,
  SYSTEM_USER_ID,
} from "../../../../shared/lib/ids";
import {
  ChatConversationResource,
  EmptyResource,
  ResourceType,
  UserResource,
} from "../../../../types/serializers";
import useChatParticipation from "../hooks/useChatParticipation";
import useReadStatus from "../hooks/useReadStatus";
import BotIcon from "../images/BotIcon.svg";
import userName from "../lib/userName";

interface ChatCardProps {
  background?: string;
  chatConversation: ChatConversationResource;
  children: React.ReactNode;
  createdAt: string | undefined;
  creator: EmptyResource<ResourceType.User> | undefined;
  fullWidth?: boolean;
}

const senderName = (isAI: boolean, isSystem: boolean, name?: string) => {
  if (isSystem) {
    return t("main:kandoorGPT.chat.names.system")!;
  }

  return isAI
    ? t("main:kandoorGPT.chat.names.gpt")!
    : name || t("main:kandoorGPT.chat.names.user")!;
};

const bgColor = (isFromCurrentUser: boolean) => {
  return isFromCurrentUser ? "background.accordion" : "gray.200";
};

const fromCurrentUser = (creatorId?: string, currentUser?: UserResource) => {
  if (currentUser) {
    return currentUser.attributes.id === creatorId;
  } else {
    return creatorId === GUEST_USER_ID;
  }
};

const ChatCard: React.FC<ChatCardProps> = ({
  chatConversation,
  creator,
  createdAt,
  children,
  background,
  fullWidth,
}) => {
  const [currentUser] = useCurrentUser();
  const creatorId = creator?.id;
  const participation = useChatParticipation(chatConversation, creatorId);

  const isFromCurrentUser = fromCurrentUser(creatorId, currentUser);
  const isAI = creatorId === AI_USER_ID;
  const isSystem = creatorId === SYSTEM_USER_ID;
  const userFirstName = userName(participation);
  const userAvatarUrl = participation?.attributes.avatarUrl;

  const senderImage = isAI || isSystem ? BotIcon : userAvatarUrl;
  const isRead = useReadStatus(
    isFromCurrentUser,
    creatorId,
    createdAt,
    chatConversation
  );

  return (
    <Flex
      alignItems={isFromCurrentUser ? "flex-end" : "flex-start"}
      flexDirection="column"
    >
      <Text color="gray.500" fontSize="sm" marginBottom="0.5em">
        {senderName(isAI, isSystem, userFirstName)}{" "}
        {createdAt && formatDateTime(createdAt)}
        {isFromCurrentUser && (
          <Icon
            as={IoCheckmarkDoneSharp}
            color={isRead ? "status.neutral" : "gray.400"}
            height="1.5em"
            marginLeft="0.2em"
            title={
              isRead ? t("main:chat.read.true") : t("main:chat.read.false")
            }
            verticalAlign="text-bottom"
            width="1.5em"
          />
        )}
      </Text>
      <Flex
        alignItems="flex-start"
        flexDirection={isFromCurrentUser ? "row" : "row-reverse"}
        gap="0.5em"
        width={fullWidth ? "100%" : undefined}
      >
        <Box
          __css={{ "& p:last-child": { marginBottom: 0 } }}
          bg={background ?? bgColor(isFromCurrentUser)}
          borderRadius="md"
          color="blackish"
          overflowWrap="anywhere"
          p={fullWidth ? undefined : "1em"}
          width={fullWidth ? "100%" : undefined}
          wordBreak="break-word"
        >
          {children}
        </Box>
        {senderImage && (
          <chakra.img
            alt="Sender Icon"
            borderRadius="full"
            flexShrink={0}
            height="40px"
            src={senderImage}
            width="40px"
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ChatCard;

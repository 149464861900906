import useResources from "../../../../shared/hooks/useResources";
import { ChatConversationResource } from "../../../../types/serializers";

const useChatParticipation = (
  chatConversation: ChatConversationResource,
  userId: string | undefined
) => {
  const participations = useResources(
    chatConversation.relationships.chatParticipations.data
  );

  return participations.find(
    (participant) => participant?.relationships.user.data?.id === userId
  );
};

export default useChatParticipation;

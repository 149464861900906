import { As, Grid, ResponsiveValue } from "@chakra-ui/react";
import { Globals } from "csstype";
import React, { PropsWithChildren } from "react";

import Container from "../../../shared/components/layout/Container";

const DEFAULT_GAP = { base: "0 16px", lg: "0 30px" };

interface LayoutProps extends PropsWithChildren {
  as?: As;
  gap?: ResponsiveValue<number>;
  gridTemplateColumns: ResponsiveValue<string>;
  templateAreas: ResponsiveValue<Globals | string>;
}

const Layout = ({
  children,
  as,
  gap,
  gridTemplateColumns,
  templateAreas,
}: LayoutProps) => {
  return (
    <Container>
      <Grid
        as={as}
        gap={gap ?? DEFAULT_GAP}
        gridTemplateColumns={gridTemplateColumns}
        templateAreas={templateAreas}
      >
        {children}
      </Grid>
    </Container>
  );
};

export default Layout;

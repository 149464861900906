import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheckCircle, FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";

import MainFooterItem from "../../../shared/components/main/MainFooterItem";
import { trackEvent } from "../../../shared/lib/analytics";

const SIMULATED_NETWORK_DELAY_MS = 200;

interface FeedbackButtonProps extends ButtonProps {
  icon: JSX.Element;
  onClick: () => void;
  text: string;
}

interface DefaultStateProps {
  handleClick: (feedbackAnswer: string) => Promise<void>;
}

const LoadingState: FC = () => (
  <Spinner data-testid="loading-spinner" padding={4} />
);

const FeedbackSubmittedState: FC = () => {
  const { t } = useTranslation();

  return (
    <Flex align="center">
      <Text fontSize={15} padding={2}>
        {t("main:blogs.helped.thanks")}
      </Text>
      <FaCheckCircle color="green" size="16px" />
    </Flex>
  );
};

const FeedbackButton: FC<FeedbackButtonProps> = ({ icon, onClick, text }) => (
  <Button leftIcon={icon} marginRight={2} variant="solid" onClick={onClick}>
    {text}
  </Button>
);

const DefaultState: FC<DefaultStateProps> = ({ handleClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <Text fontSize={15} padding={2}>
        {t("main:blogs.helped.text")}
      </Text>
      <Box>
        <FeedbackButton
          icon={<FaRegThumbsUp color="white" size="20px" />}
          text={t("main:blogs.helped.yes")}
          onClick={() => handleClick("yes")}
        />
        <FeedbackButton
          icon={<FaRegThumbsDown color="white" size="20px" />}
          text={t("main:blogs.helped.no")}
          onClick={() => handleClick("no")}
        />
      </Box>
    </>
  );
};

interface HelpedQueryProps {
  initialLoading?: boolean;
  initialSubmitted?: boolean;
}

const HelpedQuery: FC<HelpedQueryProps> = ({
  initialLoading,
  initialSubmitted,
}) => {
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(
    initialSubmitted ?? false
  );
  const [mockLoading, setMockLoading] = useState(initialLoading ?? false);

  const handleClick = useCallback(async (feedbackAnswer: string) => {
    setMockLoading(true);
    trackEvent("blog_goed_geholpen", {
      feedback: feedbackAnswer,
    });
    await new Promise((resolve) =>
      setTimeout(resolve, SIMULATED_NETWORK_DELAY_MS)
    );
    setMockLoading(false);
    setFeedbackSubmitted(true);
  }, []);

  return (
    <MainFooterItem>
      <Flex
        align="center"
        backgroundColor="background.banner"
        flexDirection={{ base: "column", sm: "row" }}
        justify="center"
        padding="1em"
      >
        {mockLoading ? (
          <LoadingState />
        ) : feedbackSubmitted ? (
          <FeedbackSubmittedState />
        ) : (
          <DefaultState handleClick={handleClick} />
        )}
      </Flex>
    </MainFooterItem>
  );
};

export default HelpedQuery;

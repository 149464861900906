import { Box, Button, Spinner, VStack, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";

import useCreateCallback from "../../../../shared/hooks/useCreateCallback";
import useCurrentUser from "../../../../shared/hooks/useCurrentUser";
import { CallbackErrorHandler } from "../../../../shared/hooks/useResourceCallback";
import { errorMessageFromResponse } from "../../../../shared/lib/api";
import { GUEST_USER_ID } from "../../../../shared/lib/ids";
import {
  ChatConversationResource,
  ChatConversationStatus,
  ResourceType,
} from "../../../../types/serializers";
import useChatParticipation from "../hooks/useChatParticipation";
import useChatSubscription from "../hooks/useChatSubscription";

import { ChatInput } from "./ChatInput";
import { ChatMessage } from "./ChatMessage";
import InlineFeedback from "./InlineFeedback";
import ClosedMessage from "./messages/ClosedMessage";
import TypingIndicator from "./TypingIndicator";

interface ChatWindowProps {
  chatConversation: ChatConversationResource;
  flexGrow?: string;
  height?: string;
  review?: boolean;
  scrollToBottom?: boolean;
}

export const ChatWindow: React.FC<ChatWindowProps> = ({
  chatConversation,
  height,
  flexGrow,
  review,
  scrollToBottom,
}) => {
  const toast = useToast();
  const chatEndRef = useRef<HTMLDivElement>(null);
  const [createMessage] = useCreateCallback(ResourceType.ChatMessage);
  const [createChatParticipation, creatingParticipation] = useCreateCallback(
    ResourceType.ChatParticipation
  );
  const [reopen, setReopen] = useState(false);

  const [currentUser] = useCurrentUser();
  const currentUserId = currentUser?.attributes.id ?? GUEST_USER_ID;

  const isVolunteer = currentUser?.attributes.isGuide;
  const isClosed =
    chatConversation.attributes.status === ChatConversationStatus.Closed;

  const isPendingAcceptance =
    chatConversation.attributes.status === "pending_acceptance";

  const isParticipant = !!useChatParticipation(chatConversation, currentUserId);

  const handleError: CallbackErrorHandler = ({ json }) => {
    toast({
      description: errorMessageFromResponse(json),
      status: "error",
    });
  };
  const handleAddVolunteer = () => {
    createChatParticipation(
      {
        chatConversationId: chatConversation.id,
      },
      undefined,
      handleError
    );
  };

  const { messages, connected, handleIsTyping, isTyping } =
    useChatSubscription(chatConversation);

  const handleScrollToBottom = () => {
    if (chatEndRef.current && scrollToBottom) {
      const scroll =
        chatEndRef.current.scrollHeight - chatEndRef.current.clientHeight;
      chatEndRef.current.scrollTop = scroll;
    }
  };
  useEffect(() => {
    const observer = new ResizeObserver(() => handleScrollToBottom());
    if (chatEndRef.current) {
      observer.observe(chatEndRef.current);
    }
    () => observer.disconnect();
  }, [chatEndRef.current]);
  useEffect(handleScrollToBottom, [messages, isTyping]);
  useEffect(() => setReopen(false), [chatConversation]);

  const handleKandoorRequest = async (userMessage: string) => {
    createMessage({
      chatConversationId: chatConversation.id,
      content: userMessage,
    });
  };

  const chatIsActive = !isClosed || reopen;

  return (
    <VStack
      flexGrow={flexGrow}
      overflowY="auto"
      position="relative"
      spacing={4}
      w="full"
    >
      <VStack
        alignItems="stretch"
        bg="white"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        height={height}
        opacity={connected ? "1" : "0.5"}
        overflowY="auto"
        p={6}
        ref={chatEndRef}
        spacing={6}
        w="100%"
      >
        {messages.map((message, index) => (
          <ChatMessage
            chatConversation={chatConversation}
            handleKandoorRequest={handleKandoorRequest}
            key={index}
            message={message}
          />
        ))}
        {isTyping && <TypingIndicator />}
        {!isClosed && isVolunteer && !isParticipant && (
          <Box display="flex" justifyContent="center">
            <Button
              isLoading={creatingParticipation}
              variant="solid"
              onClick={handleAddVolunteer}
            >
              {isPendingAcceptance
                ? t("main:kandoorGPT.chat.acceptQuestion")
                : t("main:kandoorGPT.chat.joinConversation")}
            </Button>
          </Box>
        )}
        {review ? (
          <InlineFeedback chatConversation={chatConversation} />
        ) : (
          isClosed && (
            <ClosedMessage
              closedReason={chatConversation.attributes.closedReason}
              isParticipant={isParticipant}
              reopen={reopen}
              setReopen={setReopen}
            />
          )
        )}
      </VStack>
      {isParticipant && chatIsActive && (
        <Box w="full">
          <ChatInput
            chatConversationId={chatConversation.id}
            handleIsTyping={handleIsTyping}
            isDisabled={!connected}
            onSubmit={handleKandoorRequest}
          />
        </Box>
      )}
      {!connected && (
        <Box position="absolute" right="2em">
          <Spinner />
        </Box>
      )}
    </VStack>
  );
};

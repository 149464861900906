import { Box, HStack } from "@chakra-ui/react";
import React, { Dispatch, PropsWithChildren, SetStateAction } from "react";

import useCurrentUser from "../../../../shared/hooks/useCurrentUser";
import {
  ChatConversationResource,
  ChatType,
} from "../../../../types/serializers";
import useChatParticipation from "../hooks/useChatParticipation";

import CloseButton from "./buttons/CloseButton";
import CopyUrlButton from "./buttons/CopyUrlButton";
import FeedbackButton from "./buttons/FeedbackButton";
import NewAIConversationButton from "./buttons/NewAIConversationButton";
import NotepadButton from "./buttons/NotepadButton";

const ChatInteractionWrapper = ({ children }: PropsWithChildren) => (
  <HStack
    __css={{
      "& button": {
        flexShrink: 0,
      },
    }}
    flexShrink={0}
    flexWrap="nowrap"
    gap="0.6em"
    overflowX="auto"
    overflowY="hidden"
    paddingY={{ base: 0, md: "1em" }}
    width="100%"
  >
    <Box flexGrow={1} />
    {children}
  </HStack>
);

const ChatInteraction = ({
  chatConversation,
  setShowNotepad,
  showNotepad,
}: {
  chatConversation: ChatConversationResource;
  setShowNotepad?: Dispatch<SetStateAction<boolean>>;
  showNotepad?: boolean;
}) => {
  const [currentUser] = useCurrentUser();
  const isParticipant = !!useChatParticipation(
    chatConversation,
    currentUser?.attributes.id
  );

  if (!isParticipant) {
    return (
      <ChatInteractionWrapper>
        <CopyUrlButton chatConversation={chatConversation} />
      </ChatInteractionWrapper>
    );
  }

  if (chatConversation.attributes.chatType == ChatType.AI) {
    return (
      <ChatInteractionWrapper>
        <NewAIConversationButton />
      </ChatInteractionWrapper>
    );
  }

  if (currentUser?.attributes.isGuide) {
    return (
      <ChatInteractionWrapper>
        {setShowNotepad && (
          <NotepadButton
            setShowNotepad={setShowNotepad}
            showNotepad={showNotepad ?? false}
          />
        )}
        <CopyUrlButton chatConversation={chatConversation} />
        <FeedbackButton chatConversation={chatConversation} />
      </ChatInteractionWrapper>
    );
  }

  return (
    <ChatInteractionWrapper>
      <CloseButton chatConversation={chatConversation} />
    </ChatInteractionWrapper>
  );
};

export default ChatInteraction;

import debounce from "lodash/debounce";
import { useMemo, useState } from "react";

const TYPING_DELAY = 4_000;

const useIsTyping = (): [boolean, (typing: boolean) => void] => {
  const [userIsTyping, setUserIsTyping] = useState(false);
  const handleStoppedTyping = useMemo(
    () =>
      debounce(() => {
        setUserIsTyping(false);
      }, TYPING_DELAY),
    [setUserIsTyping]
  );
  const handleIsTyping = (typing: boolean) => {
    setUserIsTyping(typing);

    if (typing) {
      handleStoppedTyping();
    }
  };

  return [userIsTyping, handleIsTyping];
};

export default useIsTyping;

import { Box, HStack, Icon, Link, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

import useFreshResource from "../../../shared/hooks/useFreshResource";
import useStoredState from "../../../shared/hooks/useStoredState";
import { ResourceType } from "../../../types/serializers";
import ChatInteraction from "../Chat/components/ChatInteraction";
import ChatNotepad from "../Chat/components/ChatNotepad";
import { ChatWindow } from "../Chat/components/ChatWindow";

import ChatViewerList from "./ChatViewerList";
import ChatViewerListHeader from "./ChatViewerListHeader";
import ChatViewerTabs, { ChatViewerTab } from "./ChatViewerTabs";
import useAutoSelect from "./hooks/useAutoSelect";
import useTabRefresh from "./hooks/useTabRefresh";

const FULL_QUESTION_KEY = "full-question";

interface ChatViewerProps {
  review?: boolean;
  sortable?: boolean;
  tabs: ChatViewerTab[];
}

const conversationIdFromQuery = (query: string) => {
  const searchParams = new URLSearchParams(query);

  return searchParams.get("id") ?? undefined;
};

const ChatViewer = ({ review, sortable, tabs }: ChatViewerProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [fullQuestionString, setFullQuestion] = useStoredState(
    FULL_QUESTION_KEY,
    {
      defaultValue: "false",
    }
  );
  const fullQuestion = fullQuestionString == "true";
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | undefined
  >(conversationIdFromQuery(location.search));
  const [reverseList, setReverseList] = useState(false);
  const [showNotepad, setShowNotepad] = useState(false);
  const chatConversation = useFreshResource(
    selectedConversationId
      ? {
          id: selectedConversationId,
          type: ResourceType.ChatConversation,
        }
      : undefined
  );
  useTabRefresh(selectedTab.key, tabs);

  useEffect(() => {
    const conversationId = conversationIdFromQuery(location.search);
    setSelectedConversationId(conversationId);
  }, [location.search]);
  const selectConversationId = (id?: string, replace?: boolean) => {
    setShowNotepad(false);
    setSelectedConversationId(id);
    const newLocation = id ? `?id=${id}${location.hash}` : location.hash;
    if (newLocation !== `${location.search}${location.hash}`) {
      navigate(newLocation, { replace });
    }
  };

  useAutoSelect(
    selectConversationId,
    selectedConversationId,
    setSelectedTab,
    selectedTab,
    tabs
  );

  useEffect(() => {
    setReverseList(false);
  }, [selectedTab]);

  return (
    <>
      <HStack alignItems="flex-start" flexShrink={0} gap="0" overflow="auto">
        <VStack
          alignItems="start"
          flexBasis={{ base: "100%", lg: "30em" }}
          flexShrink={0}
          height="100%"
          maxWidth="100%"
        >
          <ChatViewerTabs
            reverseList={reverseList}
            selectConversationId={selectConversationId}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabs={tabs}
          />
          {sortable && (
            <ChatViewerListHeader
              fullQuestion={fullQuestion}
              reverseList={reverseList}
              selectedTab={selectedTab}
              setFullQuestion={setFullQuestion}
              setReverseList={setReverseList}
            />
          )}
        </VStack>
        <Box flexGrow="1" minWidth={0} zIndex={{ base: "99", lg: "unset" }}>
          {chatConversation && !review && (
            <ChatInteraction
              chatConversation={chatConversation}
              setShowNotepad={setShowNotepad}
              showNotepad={showNotepad}
            />
          )}
        </Box>
      </HStack>
      <HStack alignItems="flex-start" flexGrow={1} gap="1em" overflow="auto">
        <VStack
          alignItems="flex-start"
          alignSelf="flex-start"
          flexBasis={{ base: "100%", lg: "30em" }}
          flexShrink={0}
          height="100%"
        >
          {showNotepad ? (
            <Box
              bottom="0em"
              flexGrow="1"
              height={{ base: "unset", lg: "100%" }}
              minWidth={0}
              padding={{ base: "1em", lg: "0" }}
              position={{ base: "fixed", lg: "unset" }}
              right="0em"
              top={{ base: "0", md: "4em" }}
              width={{ base: "25em", lg: "100%" }}
              zIndex={{
                base: showNotepad ? "elevatedNotepad" : "unset",
                lg: "unset",
              }}
            >
              <ChatNotepad closeNotepad={() => setShowNotepad(false)} />
            </Box>
          ) : (
            <ChatViewerList
              fullQuestion={fullQuestion}
              reverseList={reverseList}
              selectConversationId={selectConversationId}
              selectedConversationId={selectedConversationId}
              tab={selectedTab}
            />
          )}
        </VStack>
        {chatConversation && (
          <VStack
            background="white"
            bottom="0em"
            flexGrow="1"
            height={{ base: "unset", lg: "100%" }}
            left="-0.5em"
            minWidth={0}
            padding={{ base: "1em", lg: "0" }}
            position={{ base: "fixed", lg: "unset" }}
            right="0em"
            role="tabpanel"
            top={{ base: "0", md: "4em" }}
            zIndex={{
              base: "elevatedChat",
              lg: "unset",
            }}
          >
            <Box display={{ base: "block", lg: "none" }} width="100%">
              <Link as="button" onClick={() => selectConversationId(undefined)}>
                <HStack spacing="8px">
                  <Icon as={FiChevronLeft} marginBottom="4px" />
                  <span>{t("main:chat.mobile.closeConversation")}</span>
                </HStack>
              </Link>
            </Box>
            {chatConversation && !review && (
              <Box display={{ base: "block", lg: "none" }} width="100%">
                <ChatInteraction
                  chatConversation={chatConversation}
                  setShowNotepad={setShowNotepad}
                  showNotepad={showNotepad}
                />
              </Box>
            )}
            <ChatWindow
              scrollToBottom
              chatConversation={chatConversation}
              flexGrow="1"
              height="100%"
              review={review}
            />
          </VStack>
        )}
      </HStack>
    </>
  );
};

export default ChatViewer;

import { Box, Checkbox, Link } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import useResource from "../../../shared/hooks/useResource";
import { ResourceType } from "../../../types/serializers";

import ChatSorter from "./ChatSorter";
import { ChatViewerTab } from "./ChatViewerTabs";
import { getTabCollectionId } from "./lib/tabs";

interface ChatViewerListHeaderProps {
  fullQuestion: boolean;
  reverseList: boolean;
  selectedTab: ChatViewerTab;
  setFullQuestion: (fullQuestion: string) => void;
  setReverseList: (reverseList: boolean) => void;
}

const ChatViewerListHeader = ({
  reverseList,
  setReverseList,
  fullQuestion,
  setFullQuestion,
  selectedTab,
}: ChatViewerListHeaderProps) => {
  const collection = useResource({
    id: getTabCollectionId(selectedTab, reverseList),
    type: ResourceType.Collection,
  });

  if (!collection || collection.attributes.totalCount === 0) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      marginBottom="0.5em"
      width="100%"
    >
      <Checkbox
        isChecked={fullQuestion}
        onChange={() => setFullQuestion((!fullQuestion).toString())}
      >
        <Link as="div" fontSize="17px">
          {t("main:chat.fullQuestion")}
        </Link>
      </Checkbox>
      <ChatSorter
        reverseList={reverseList}
        setReverseList={setReverseList}
        tab={selectedTab}
      />
    </Box>
  );
};

export default ChatViewerListHeader;

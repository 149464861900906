import { Badge } from "@chakra-ui/react";
import React from "react";

interface CountBubbleProps {
  background: string;
  color?: string;
  count?: number;
  title?: string;
}
const CountBubble = ({ count, background, color, title }: CountBubbleProps) => {
  if (!count) {
    return null;
  }

  return (
    <Badge
      background={background}
      border="1px solid"
      borderRadius="full"
      color={color}
      flexShrink={0}
      height="1.5em"
      lineHeight="1.4em"
      marginLeft="0.3em"
      minWidth="1.5em"
      title={title}
    >
      {count}
    </Badge>
  );
};

export default CountBubble;

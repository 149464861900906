import { chakra } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import MainFooterItem from "../../../shared/components/main/MainFooterItem";

const BlogDisclaimer = () => {
  const { t } = useTranslation();

  return (
    <MainFooterItem>
      <chakra.div
        fontFamily="inter"
        fontSize={15}
        fontStyle="italic"
        lineHeight="24px"
      >
        {t("main:disclaimer.default")}
      </chakra.div>
    </MainFooterItem>
  );
};

export default BlogDisclaimer;

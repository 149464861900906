import { Box } from "@chakra-ui/react";
import Linkify from "linkify-react";
import React from "react";

import ChatCard from "../ChatCard";
import { MessageRenderer } from "../ChatMessage";

const stripATags = (string: string) =>
  string.replace(/<a[^>]*>(.*?)<\/a>/gi, "$1");

const TextMessage = ({ chatMessage, chatConversation }: MessageRenderer) => {
  return (
    <ChatCard
      chatConversation={chatConversation}
      createdAt={chatMessage.attributes.createdAt}
      creator={chatMessage.relationships.user.data}
    >
      <Box
        __css={{ "& a": { color: "primary.darkActive" } }}
        whiteSpace="pre-line"
      >
        <Linkify>{stripATags(chatMessage.attributes.content)}</Linkify>
      </Box>
    </ChatCard>
  );
};

export default TextMessage;

import { GridItem } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

const MainFooter = ({ children }: PropsWithChildren) => (
  <GridItem area="footer" as="footer" marginTop="2em" overflow="auto">
    {children}
  </GridItem>
);

export default MainFooter;

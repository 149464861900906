import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";
import { FiEdit } from "react-icons/fi";

import AsyncResource from "../../../../shared/components/async/AsyncResource";
import Collection from "../../../../shared/components/collection";
import CollectionItems from "../../../../shared/components/collection/CollectionItems";
import { Loader } from "../../../../shared/components/loader";
import useCollection from "../../../../shared/hooks/useCollection";
import useCurrentUser from "../../../../shared/hooks/useCurrentUser";
import {
  ChatConversationResource,
  FeedbackResource,
  ResourceType,
  UserType,
} from "../../../../types/serializers";
import useCurrentFeedback from "../hooks/useCurrentFeedback";
import userName from "../lib/userName";

import FeedbackForm from "./FeedbackForm";

const OnEmpty = () => <>{t("main:chat.feedbackBox.empty")}</>;

export const FeedbackItem = ({
  feedback,
  setUpdate,
  update,
}: {
  feedback: FeedbackResource;
  setUpdate?: (value: boolean) => void;
  update?: boolean;
}) => {
  const [currentUser] = useCurrentUser();
  const edittable =
    setUpdate &&
    currentUser?.attributes.id === feedback.relationships.user.data?.id;

  return (
    <Box>
      <AsyncResource resource={feedback.relationships.user.data}>
        {(user) => (
          <HStack marginBottom="0.5em">
            <Image
              alt={user.attributes.firstname}
              borderRadius="1em"
              height="2em"
              marginRight="0.1em"
              src={user.attributes.avatarUrl}
              verticalAlign="middle"
              width="2em"
            />
            <Text flexGrow={1} fontWeight="bold">
              {userName(user)}
            </Text>
            {edittable && (
              <Button
                leftIcon={<FiEdit />}
                variant="ghost"
                onClick={() => setUpdate(!update)}
              >
                {t("main:chat.feedbackBox.edit")}
              </Button>
            )}
          </HStack>
        )}
      </AsyncResource>
      <Text fontStyle="italic">{`"${feedback.attributes.feedback}"`}</Text>
    </Box>
  );
};

const FeedbackBox = ({
  chatConversation,
}: {
  chatConversation: ChatConversationResource;
}) => {
  const [currentUser] = useCurrentUser();
  const feedbackCollection = useCollection(ResourceType.Feedback, {
    filter: {
      chatConversationId: [chatConversation.id],
      userType: [UserType.Volunteer, UserType.Supervisor],
    },
  });
  const [update, setUpdate] = useState(false);
  const [currentFeedback, refreshCurrentFeedback] =
    useCurrentFeedback(chatConversation);
  const handleSubmit = () => {
    refreshCurrentFeedback();
    setUpdate(false);
  };
  const showInput =
    update ||
    (!currentFeedback &&
      currentUser?.attributes.id !==
        chatConversation.relationships.volunteer.data?.id);

  return (
    <VStack
      border="1px solid"
      borderColor="background.stroke"
      borderRadius="md"
      gap={0}
      overflow="hidden"
    >
      <Box background="primary.dark" padding="1em" width="100%">
        <Heading as="h3" color="white" variant="xs">
          {t("main:chat.feedbackBox.header")}
        </Heading>
      </Box>
      <VStack alignItems="flex-start" padding="1em" width="100%">
        <Collection
          id={feedbackCollection?.id}
          wrapperProps={{ width: "100%" }}
          onLoad={() => <Loader width="100%" />}
        >
          <CollectionItems<ResourceType.Feedback> onEmpty={OnEmpty}>
            {(feedback) => (
              <FeedbackItem
                feedback={feedback}
                setUpdate={setUpdate}
                update={update}
              />
            )}
          </CollectionItems>
        </Collection>
        {showInput && (
          <>
            <Divider borderColor="background.stroke" marginTop="1em" />
            <FeedbackForm
              chatConversation={chatConversation}
              currentFeedback={currentFeedback}
              onSubmit={handleSubmit}
            />
          </>
        )}
      </VStack>
    </VStack>
  );
};

export default FeedbackBox;

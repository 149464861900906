import { useContext, useEffect } from "react";

import { AppContext } from "../../../../shared/components/appContext/AppContextProvider";

const useHideFooter = () => {
  const { setShowFooter } = useContext(AppContext);

  useEffect(() => {
    setShowFooter(false);

    return () => setShowFooter(true);
  }, []);
};

export default useHideFooter;

import { Consumer } from "@rails/actioncable";
import React, { FC, PropsWithChildren, useEffect, useState } from "react";

const createConsumer = async () => {
  return import(
    /* webpackChunkName: "actioncable" */
    "@rails/actioncable"
  ).then((ActionCable) => {
    return ActionCable.createConsumer(GLOBALS.actionCableUrl);
  });
};

export const CableContext = React.createContext<Consumer | null>(null);

const CableProvider: FC<PropsWithChildren> = ({ children }) => {
  const [consumer, setConsumer] = useState<Consumer | null>(null);

  useEffect(() => {
    createConsumer().then(setConsumer);
  }, []);

  return (
    <CableContext.Provider value={consumer}>{children}</CableContext.Provider>
  );
};

export default CableProvider;

import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import { ModalRenderSharedProps } from "../../../../shared/components/modal/ModalContextProvider";
import useActionCallback from "../../../../shared/hooks/useActionCallback";
import { ResourceType } from "../../../../types/serializers";

export interface FeedbackConfirmationModalProps extends ModalRenderSharedProps {
  chatConversationId: string;
}

export const FeedbackConfirmationModal = ({
  handleClose,
  chatConversationId,
}: FeedbackConfirmationModalProps) => {
  const [callback, pending] = useActionCallback(
    { id: chatConversationId, type: ResourceType.ChatConversation },
    "wrap_up"
  );

  const sendFeedbackRequest = () => {
    callback({}, handleClose);
  };

  return (
    <ModalContent>
      <ModalHeader>{t("main:kandoorGPT.chat.feedback_request")}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {t("main:kandoorGPT.chat.confirm_feedback_request")}
      </ModalBody>
      <ModalFooter>
        <Button mr={3} variant="ghost" onClick={handleClose}>
          {t("main:kandoorGPT.chat.cancel")}
        </Button>
        <Button
          isDisabled={pending}
          variant="solid"
          onClick={sendFeedbackRequest}
        >
          {t("main:kandoorGPT.chat.send_feedback")}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import useResource from "../../../../../shared/hooks/useResource";
import { ChatConversationResource } from "../../../../../types/serializers";
import { COMPLIMENT_TRANSLATIONS, ICONS } from "../../modals/ComplimentsModal";
import HelpedQueryChatButton from "../buttons/HelpedQueryChatButton";
import ChatCard from "../ChatCard";
import { MessageRenderer } from "../ChatMessage";

const FeedbackCard = ({
  chatMessage,
  chatConversation,
}: MessageRenderer & {
  chatConversation: ChatConversationResource;
}) => {
  const feedback = useResource(chatMessage.relationships.feedback?.data);
  if (!feedback) {
    return null;
  }
  const helped = feedback.attributes.helpedQuery;
  const compliment = feedback.attributes.compliment;
  const userFeedback = feedback.attributes.feedback;
  const icon = compliment && ICONS[compliment];
  const label = compliment && COMPLIMENT_TRANSLATIONS[compliment];

  return (
    <ChatCard
      fullWidth
      background="none"
      chatConversation={chatConversation}
      createdAt={feedback.attributes.createdAt}
      creator={chatConversation.relationships.user.data}
    >
      <Flex
        border="1px solid"
        borderColor="background.stroke"
        borderRadius="lg"
        direction={{ base: "column", md: "row" }}
        gap={{ base: "1em", md: "2em" }}
        overflow="auto"
        p={4}
      >
        <Box
          flexShrink={0}
          minW="120px"
          textAlign={{ base: "left", md: "center" }}
        >
          <Text fontWeight="bold">{t("main:chat.feedback.helpedQuery")}</Text>
          <HelpedQueryChatButton helped={helped} />
        </Box>

        {helped && (
          <Box textAlign="left">
            <Text fontWeight="bold">{t("main:chat.feedback.compliment")}</Text>
            {compliment ? (
              <>
                <Flex
                  alignItems={{ base: "flex-start", md: "center" }}
                  direction="column"
                >
                  <Image alt={label} mt="16px" src={icon} />
                  <Text mt={2}>{label}</Text>
                </Flex>
              </>
            ) : (
              <Text mt="1em">{t("main:chat.feedback.none")}</Text>
            )}
          </Box>
        )}

        <Box textAlign="left">
          <Text fontWeight="bold">{t("main:chat.feedback.userFeedback")}</Text>
          {userFeedback ? (
            <Text fontStyle="italic" mt="16px">
              {userFeedback}
            </Text>
          ) : (
            <Text mt="1em">{t("main:chat.feedback.none")}</Text>
          )}
        </Box>
      </Flex>
    </ChatCard>
  );
};

export default FeedbackCard;

import { useMemo } from "react";

import useCurrentUser from "../../../../shared/hooks/useCurrentUser";
import useRefreshResource from "../../../../shared/hooks/useRefreshResource";
import useSubscription, {
  ClientActionType,
  OnReceive,
} from "../../../../shared/hooks/useSubscription";
import { ResourceType } from "../../../../types/serializers";
import { ChatViewerTab } from "../ChatViewerTabs";
import { ChatViewerTabKey, getTabCollectionId } from "../lib/tabs";

const useTabRefresh = (tabKey: ChatViewerTabKey, tabs: ChatViewerTab[]) => {
  const [currentUser] = useCurrentUser();
  const userChannel = tabKey === ChatViewerTabKey.User;
  const channel = useMemo(
    () =>
      userChannel
        ? { channel: "UserChatChannel", id: currentUser?.attributes.id }
        : { channel: "DashboardChannel" },
    [userChannel && currentUser?.id]
  );
  const refreshResource = useRefreshResource();

  const onReceive: OnReceive = (body) => {
    switch (body.type) {
      case ClientActionType.UpdateCounts:
        tabs.forEach((tab) => {
          refreshResource({
            id: getTabCollectionId(tab, true),
            type: ResourceType.Collection,
          });
          refreshResource({
            id: getTabCollectionId(tab, false),
            type: ResourceType.Collection,
          });
        });
        break;
    }
  };

  useSubscription(channel, {
    onReceive,
  });
};

export default useTabRefresh;

import { Box, HStack, Icon, chakra } from "@chakra-ui/react";
import React from "react";
import { FiMessageCircle } from "react-icons/fi";

import PartyIcon from "./icons/party.svg";

type IconType = "speech" | "party";

const EmptyIcon = ({ icon }: { icon: IconType }) => {
  switch (icon) {
    case "party":
      return <chakra.img src={PartyIcon} width="2em" />;
    case "speech":
      return <Icon as={FiMessageCircle} />;
  }
};

const EmptyMessage = ({
  icon,
  message,
}: {
  icon: IconType;
  message: string;
}) => (
  <HStack marginTop="1em" width="100%">
    <EmptyIcon icon={icon} />
    <Box>{message}</Box>
  </HStack>
);

export default EmptyMessage;

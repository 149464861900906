import { EmptyResource, ResourceType } from "../../types/serializers";
import useResourceRoute from "../components/store/hooks/useResourceRoute";

import { METHOD } from "./useFetchCallback";
import useResourceCallback from "./useResourceCallback";

const useActionCallback = <T extends ResourceType>(
  resource: EmptyResource<T> | undefined,
  action: string,
  method: METHOD = "PUT",
  root?: string
) => {
  const path = useResourceRoute(resource);

  return useResourceCallback<T>(`${path}/${action}`, method, resource, root);
};

export default useActionCallback;

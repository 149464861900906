import { t } from "i18next";
import React from "react";

import RichText from "../../../../../shared/components/richText";
import QuestionButton from "../../../buttons/QuestionButton";
import ChatCard from "../ChatCard";
import { MessageRenderer } from "../ChatMessage";

const SendToVolunteerMessage = ({
  chatConversation,
  chatMessage,
}: MessageRenderer) => {
  return (
    <ChatCard
      chatConversation={chatConversation}
      createdAt={chatMessage.attributes.createdAt}
      creator={chatMessage.relationships.user.data}
    >
      <RichText>{chatMessage.attributes.content}</RichText>
      <QuestionButton
        showRightIcon
        marginTop="1em"
        referer="gpt"
        showLeftIcon={false}
        size="md"
      >
        {t("main:kandoorGPT.chat.sendToVolunteer.sendButton")}
      </QuestionButton>
    </ChatCard>
  );
};

export default SendToVolunteerMessage;

import { Button } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiTrash2 } from "react-icons/fi";

import useModalDispatch from "../../../../../shared/hooks/useModalDispatch";
import { DeleteAccountConfirmationModal } from "../../modals/DeleteAccountConfirmationModal";

const DeleteAccountButton = () => {
  const dispatchModal = useModalDispatch(DeleteAccountConfirmationModal);

  const handleOpenDeleteModal = () => {
    dispatchModal();
  };

  return (
    <Button
      leftIcon={<FiTrash2 />}
      marginBottom="1em"
      padding="0"
      sx={{
        _hover: {},
      }}
      variant="ghost"
      onClick={handleOpenDeleteModal}
    >
      {t("main:account.delete_account")}
    </Button>
  );
};

export default DeleteAccountButton;

import { Box, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useContext } from "react";

import { CollectionContext } from "../../../shared/components/collection";
import useResource from "../../../shared/hooks/useResource";
import { collectionOptsFromId } from "../../../shared/lib/ids";
import { ResourceType } from "../../../types/serializers";

const ChatArchiveUserFilter = () => {
  const { collectionId, updateCollection } = useContext(
    CollectionContext
  ) as CollectionContext<ResourceType.ChatConversation>;
  const { filter } = collectionOptsFromId(collectionId);
  const [userFilter] = filter?.userId ?? [];
  const filteredUser = useResource({ id: userFilter, type: ResourceType.User });
  const removeFilter = () => {
    const newFilter = { ...filter } as Record<string, string[]>;
    delete newFilter["userId"];

    updateCollection({ filter: newFilter });
  };

  if (!userFilter) {
    return null;
  }

  return (
    <Box>
      {t("main:chatArchive.filters.user_id.label")}
      <Tag borderRadius="full" variant="solid">
        <TagLabel>{filteredUser?.attributes.firstname}</TagLabel>
        <TagCloseButton onClick={removeFilter} />
      </Tag>
    </Box>
  );
};

export default ChatArchiveUserFilter;

import { Box, Button, chakra } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";

import useCreateCallback from "../../../../shared/hooks/useCreateCallback";
import useUpdateCallback from "../../../../shared/hooks/useUpdateCallback";
import {
  ChatConversationResource,
  FeedbackResource,
  ResourceType,
} from "../../../../types/serializers";

interface FeedbackFormProps {
  chatConversation: ChatConversationResource;
  currentFeedback?: FeedbackResource;
  onSubmit?: () => void;
}

const FeedbackForm = ({
  chatConversation,
  currentFeedback,
  onSubmit,
}: FeedbackFormProps) => {
  const [createCallback, createLoading] = useCreateCallback(
    ResourceType.Feedback
  );
  const [updateCallback, updateLoading] = useUpdateCallback(currentFeedback);
  const [feedbackValue, setFeedbackValue] = useState("");
  const callback = currentFeedback ? updateCallback : createCallback;
  const loading = currentFeedback ? updateLoading : createLoading;

  useEffect(() => {
    if (currentFeedback) {
      setFeedbackValue(currentFeedback.attributes.feedback);
    }
  }, [currentFeedback]);

  return (
    <Box>
      <chakra.textarea
        background="background.footer"
        borderRadius="0.5em"
        padding="1em"
        placeholder={t("main:chat.feedbackBox.placeholder")!}
        value={feedbackValue}
        width="100%"
        onChange={(e) => setFeedbackValue(e.target.value)}
      />
      <Button
        alignSelf="end"
        isLoading={loading}
        onClick={() => {
          if (callback) {
            callback(
              {
                chatConversationId: chatConversation.id,
                feedback: feedbackValue,
              },
              onSubmit
            );
          }
        }}
      >
        {t("main:chat.feedbackBox.send")}
      </Button>
    </Box>
  );
};

export default FeedbackForm;

import {
  Box,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SimpleGrid,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FiChevronRight } from "react-icons/fi";

import { ModalRenderSharedProps } from "../../../../shared/components/modal/ModalContextProvider";
import useModalDispatch from "../../../../shared/hooks/useModalDispatch";
import useUpdateCallback from "../../../../shared/hooks/useUpdateCallback";
import {
  ChatConversationResource,
  ChatMessageResource,
  Compliment,
} from "../../../../types/serializers";
import useChatParticipation from "../hooks/useChatParticipation";
import PleasantConversationIcon from "../images/pleasant_conversation.svg";
import QuickResponseIcon from "../images/quick_response.svg";
import Understandingicon from "../images/understanding.svg";
import WellHelpedIcon from "../images/well_helped.svg";
import userName from "../lib/userName";

import FeedbackModal from "./PositiveFeedbackModal";

export interface ComplimentsModalProps extends ModalRenderSharedProps {
  chatConversation: ChatConversationResource;
  chatMessage: ChatMessageResource;
}

export const COMPLIMENT_TRANSLATIONS = {
  [Compliment.QuickResponse]: t(
    "main:kandoorGPT.chat.compliments.quick_response"
  ),
  [Compliment.PleasantConversation]: t(
    "main:kandoorGPT.chat.compliments.pleasant_conversation"
  ),
  [Compliment.WellHelped]: t("main:kandoorGPT.chat.compliments.well_helped"),
  [Compliment.Understanding]: t(
    "main:kandoorGPT.chat.compliments.understanding"
  ),
};

export const ICONS: Record<Compliment, string> = {
  [Compliment.QuickResponse]: QuickResponseIcon,
  [Compliment.PleasantConversation]: PleasantConversationIcon,
  [Compliment.WellHelped]: WellHelpedIcon,
  [Compliment.Understanding]: Understandingicon,
};

const ComplimentButton = ({
  compliment,
  handleCompliment,
  loading,
}: {
  compliment: Compliment;
  handleCompliment: (compliment: Compliment) => void;
  loading: boolean;
}) => {
  const icon = ICONS[compliment];
  const label = COMPLIMENT_TRANSLATIONS[compliment];

  return (
    <Button
      _hover={{ bg: "background.accordion" }}
      alignItems="center"
      bg="white"
      borderRadius="lg"
      boxShadow="rgba(0, 0, 0, 0.15) 0px 6px 6px 0px"
      color="blackish"
      flexDirection="column"
      gap={2}
      isDisabled={loading}
      minH="120px"
      minW="120px"
      onClick={() => handleCompliment(compliment)}
    >
      <img alt={label} src={icon} />
      {label}
    </Button>
  );
};

export const ComplimentsModal = ({
  chatConversation,
  chatMessage,
}: ComplimentsModalProps) => {
  const [updateChatConversation, loading] = useUpdateCallback(
    chatMessage.relationships.feedback!.data!
  );

  const participation = useChatParticipation(
    chatConversation,
    chatConversation.relationships.volunteer.data!.id
  );
  const firstName = userName(participation);

  const dispatchFeedbackModal = useModalDispatch(FeedbackModal, false);

  const handleCompliment = (compliment: Compliment) => {
    const handleSuccess = () =>
      dispatchFeedbackModal({ chatConversation, chatMessage });
    updateChatConversation(
      {
        compliment,
      },
      handleSuccess
    );
  };

  return (
    <ModalContent>
      <ModalHeader>{t("main:kandoorGPT.chat.compliment_header")}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box fontSize="lg" mb="16px">
          {t("main:kandoorGPT.chat.compliment_subheader", {
            volunteer: firstName,
          })}
        </Box>
        <SimpleGrid columns={2} spacing={6}>
          {Object.values(Compliment).map((compliment) => (
            <ComplimentButton
              compliment={compliment}
              handleCompliment={handleCompliment}
              key={compliment}
              loading={loading}
            />
          ))}
        </SimpleGrid>
      </ModalBody>
      <ModalFooter>
        <Button
          fontSize="17px"
          isDisabled={loading}
          rightIcon={<FiChevronRight />}
          variant="ghost"
          onClick={() =>
            dispatchFeedbackModal({ chatConversation, chatMessage })
          }
        >
          {t("main:kandoorGPT.chat.skip")}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

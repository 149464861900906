import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { FiAlertCircle } from "react-icons/fi";

import { MessageRenderer } from "../ChatMessage";

const SystemMessage = ({ chatMessage }: MessageRenderer) => {
  return (
    <Flex alignItems="center" bg="background.system" borderRadius="lg" p={4}>
      <Icon as={FiAlertCircle} boxSize="20px" color="blackish" mr={2} />
      <Text color="blackish" fontSize="lg">
        {chatMessage.attributes.content}
      </Text>
    </Flex>
  );
};

export default SystemMessage;

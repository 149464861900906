import React, { PropsWithChildren } from "react";

import Layout from "../components/layout/Layout";

const desktopTemplate =
  '"breadcrumbs breadcrumbs breadcrumbs" ' +
  '"left header right" ' +
  '"left body right" ' +
  '"left footer right"';

const mobileTemplate =
  '"breadcrumbs" ' +
  '"header" ' +
  '"left" ' +
  '"body" ' +
  '"footer" ' +
  '"right"';

const Layout3_6_3 = ({ children }: PropsWithChildren) => {
  return (
    <Layout
      as="article"
      gridTemplateColumns={{
        base: "1fr",
        lg: "1fr 2fr 1fr",
      }}
      templateAreas={{ base: mobileTemplate, lg: desktopTemplate }}
    >
      {children}
    </Layout>
  );
};

export default Layout3_6_3;
